import { Component, OnInit, ViewChild, ElementRef ,HostListener} from "@angular/core";
import { DropdownData } from "../../../shared-data-entry/dropdown-data";
import { InfoModalServiceService } from "../../../modal/info-modal/info-modal-service.service";
import { MajorAdverseEventService } from "../../../shared-data-entry/major-adverse-event.service";
import { MajorAdverseEventResponse } from "../../../../shared/major-adverse-event-response";
import { MajorAdverseEventModel } from "../../../../shared/major-adverse-event-model";
import { OtherAdverseEventService } from "../../../shared-data-entry/other-adverse-event.service";
import { OtherAdverseEventResponse } from "../../../../shared/other-adverse-event-response";
import { OtherAdverseEventModel } from "../../../../shared/other-adverse-event-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { CaseTypeTreeModel } from "../../../shared-data-entry/case-types-entry/case-type-tree-model";
import { CaseFieldsService } from "../case-fields.service";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";

@Component({
  selector: "app-adverse-events",
  templateUrl: "./adverse-events.component.html",
  styleUrls: ["./adverse-events.component.scss"],
  host: { class: "host" }
})
export class AdverseEventsComponent implements OnInit, ComponentCanDeactivate{
  @ViewChild("seriousness") seriousness: ElementRef;
  @ViewChild('form') form: NgForm;

  events = [];
  name = "Adverse Events";
  selectedMajorAdverseEvent = [];

  toAdd: any = {};
  // recordedEvents = {};
  // recordedArray = [];
  showEvents: boolean = false;

  options = {
    animateExpand: true,
    animateAcceleration: 1.2,
    scrollOnActivate: true,
    animateSpeed: 10
  };
  dropdownData: DropdownData;

  public info: InfoModalServiceService;
  public majorAdverseEventService: MajorAdverseEventService;
  public otherAdverseEventService: OtherAdverseEventService;
  public fields: CaseFieldsService;
  constructor(private router: Router,
    info: InfoModalServiceService,
    _majorAdverseEventService: MajorAdverseEventService,
    _otherAdverseEventService: OtherAdverseEventService,
    fields: CaseFieldsService
  ) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.info = info;
    this.majorAdverseEventService = _majorAdverseEventService;
    this.otherAdverseEventService = _otherAdverseEventService;

    this.fields = fields;

    this.majorAdverseEventService
      .getMajorAdverseEvent()
      .subscribe(data => this.populateMajorEvents(data));

    this.otherAdverseEventService
      .getOtherAdverseEvent()
      .subscribe(data => this.populateOtherEvents(data));
  }

  ngOnInit() {
    
    let hasEvents = this.fields.getField(this.name, "Recorded Events").value != null;
    localStorage.setItem('adverseevents', JSON.stringify(this.fields.getField(this.name, "Recorded Events").value))

    this.fields.getParent(this.name)["hide"] = !hasEvents;
    this.fields.currentSeriousness = null;
    this.fields.currentSeriousnessDisplay = "";
    this.toAdd = {};
    this.showEvents = hasEvents;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');

    if (localStorage.getItem('adverseevents')  != null && localStorage.getItem('adverseevents') != JSON.stringify(this.fields.getField('Adverse Events', 'Recorded Events').value))
      localStorage.setItem('dirty', 'true');


    return !this.form.dirty;
  }

  populateMajorEvents(majorAEResponse: MajorAdverseEventResponse) {
    this.majorAdverseEventService.majorAdverseEventList =
      majorAEResponse.DataObject;
    this.majorAdverseEventService.mapToDropDown();

    this.dropdownData = new DropdownData(
      this.selectedMajorAdverseEvent,
      this.majorAdverseEventService.dropDownMajorAdverseEvent
    );
  }

  populateOtherEvents(otherAEResponse: OtherAdverseEventResponse) {
    this.otherAdverseEventService.otherAdverseEventList =
      otherAEResponse.DataObject;
    this.otherAdverseEventService.mapToDropDown();

    this.events = this.otherAdverseEventService.dropDownOtherAdverseEvent;
  }

  getKeyString(node, tree) {
    return CaseTypeTreeModel.getKeyString(node, tree);
  }

  //places the event in selected adverse events
  onSave($event) {
    if (!this.getEvents()) {
      this.setEvents({});
    }
    if (
      this.toAdd.id !== null &&
      this.toAdd.id > 0 &&
      this.checkExists(this.fields.currentSeriousness)
    ) {
      this.addItem(this.toAdd.name, {
        name: this.toAdd.name,
        id: this.toAdd.id,
        code: this.toAdd.code,
        major: this.toAdd.major,
        seriousness: this.fields.currentSeriousness,
        timeneeded: this.toAdd.timeneeded
      });
    }
    if (this.toAdd !== {}) {
      this.onClear($event);
    }
  }

  //places the selected event in the edit fields
  onSelect(event, tree) {
    if (event.node.isLeaf) {
      this.toAdd = {
        name: CaseTypeTreeModel.getKeyString(event.node, tree),
        id: event.node.data.id,
        code: event.node.data.code,
        major: "0",
        seriousness: ""
      };
      this.fields.currentSeriousness = null;
      this.setCurrentSeriousnessDisplay();
    }

    event.node.toggleExpanded();
  }

  //clears the selection in the major adverse events dropdown
  setMajor(event) {
    this.toAdd = {
      name: event.name,
      id: event.id,
      code: event.code,
      major: "1",
      seriousness: ""
    };
    this.fields.currentSeriousness = null;
    this.setCurrentSeriousnessDisplay();
    this.selectedMajorAdverseEvent = [];
  }

  //clears the editable event fields
  onClear(event) {
    this.toAdd = {};

    this.fields.currentSeriousness = null;
    this.setCurrentSeriousnessDisplay();
  }

  //deletes a selected procedure
  deleteProcedure(event, name) {
    let savedEvents = this.getEvents();
    delete savedEvents[name];
    let arr = Object.getOwnPropertyNames(savedEvents);

    if (arr.length == 0) {
      savedEvents = null;
    }
  }

  toggleShowEvents(val) {
    this.showEvents = val;
    this.fields.getParent(this.name)['hide'] = !val;

    //Adverse Events did not occur, remove selected adverse events and event description
    if (!val) {
      this.fields.getField(this.name, 'Description').value = null;
      this.fields.getField(this.name, 'Recorded Events').value = null;
     // this.fields.getParent(this.name)["hide"] = true;
    }
  }

  toggleCheckbox(select, val) {
    this.fields.getField(this.name, val).value = select;
  }

  getDropdown(val) {
    return this.fields.getField(this.name, val).value;
  }

  //returns the seriousness value of a particular event
  getSeriousness(name) {
    let savedEvents = this.getEvents();
    if (savedEvents) {
      var seriousness = savedEvents[name]["seriousness"].substring(4,5);
      if (seriousness > 0 && seriousness < 6)
        return  +savedEvents[name]["seriousness"].substring(4,5);
    }
  }

  getTimeneeded(name) {
    let savedEvents = this.getEvents();
    if (savedEvents) {
      if (savedEvents[name]["timeneeded"] !== null && savedEvents[name]["timeneeded"] !=="") {
        return "("+savedEvents[name]["timeneeded"]+" min)";
      }
      else {
        return "";
      }

    }
  }

  //places a selected event in the editable fields
  editProcedure($event, name) {
    this.toAdd = this.getEvents()[name];
    this.fields.currentSeriousness = this.getEvents()[name]["seriousness"];
    this.setCurrentSeriousnessDisplay();
  }

  checkAdd() {
    return !(
      this.checkExists(this.toAdd.name) && this.checkExists(this.fields.currentSeriousness)
      && this.checkTimeneededExists(this.toAdd.timeneeded)
    );

  }

  checkExists(string) {
    return string && string !== "";
   }

  checkTimeneededExists(string) {
    if (string === 0) {
      return true;
    }
    return string && string !== "" && string > 0;
  }

  addItem(name, item) {

    this.fields.getField(this.name, "Recorded Events").value[name + ""] = item;
    
    let unordered = this.fields.getField(this.name, "Recorded Events").value;
    let ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    this.fields.getField(this.name, "Recorded Events").value = ordered;

    //Set default value
    this.fields.getField(this.name, "Seriousness").value = "AES01";
    this.fields.getField(this.name, "Time Needed").value = 100;
  }

  getEvents() {
    return this.fields.getField(this.name, "Recorded Events").value;
  }

  setEvents(val) {
    this.fields.getField(this.name, "Recorded Events").value = val;
  }

  //gets the recorded procedures as an array
  getEventsArray() {
    var proc = this.getEvents();
    if (proc) {
      return Object.getOwnPropertyNames(this.getEvents());
    } else {
      return [];
    }
  }

  setCurrentSeriousnessDisplay() {
    this.fields.currentSeriousnessDisplay = "";

    switch (this.fields.currentSeriousness) {
      case "AES01": {
        this.fields.currentSeriousnessDisplay = "1 - None";
        break;
      }
      case "AES02": {
        this.fields.currentSeriousnessDisplay = "2 - Minor";
        break;
      }
      case "AES03": {
        this.fields.currentSeriousnessDisplay = "3 - Moderate";
        break;
      }
      case "AES04": {
        this.fields.currentSeriousnessDisplay = "4 - Major";
        break;
      }
      case "AES05": {
        this.fields.currentSeriousnessDisplay = "5 - Catastrophic";
        break;
      } 
    }
  }
}
