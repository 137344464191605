import { Component, OnInit, OnDestroy, ViewChild, HostListener } from "@angular/core";
import { fadeAnimation } from "../../../animations/fade.animation";
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd,
  NavigationStart,
  RouterEvent
} from "@angular/router";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { RouterHelper } from "../../../router-helper";
import { SurgeryCaseFieldsService } from "./surgery-case-fields.service";
import { SurCaseService } from "./surcase.service";
import { SurCaseResponse } from "../../../shared/iqis/surcase/surcase-response";
import { SurCaseModel } from '../../../shared/iqis/surcase/surcase-model';
import { SurCaseProcEfficacyInfoModel } from '../../../shared/iqis/surcase/surcase-proc-efficacy-info-model';
import { LoginService } from "../login.service";
import { SurProcedurePicklistService } from "../../shared-data-entry/surprocedure-picklist.service";
import { SurProcedurePicklistResponse } from '../../../shared/iqis/surprocedure-picklist-response';
import { ComponentCanDeactivate } from '../../../CanDeactivateGaurd';
import { NgForm } from "@angular/forms";
import { SurDropdownItemModel } from "../../../shared/iqis/surdropdown-item-model";
import { SurCardiacDiagnosisService } from "../../shared-data-entry/surcardiac-diagnosis.service";


@Component({
  selector: "app-edit-case",
  templateUrl: "./edit-surgery-case.component.html",
  styleUrls: ["./edit-surgery-case.component.scss"],
  animations: [fadeAnimation]
})
export class EditSurgeryCaseComponent  implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('form') form: NgForm;
  //form: NgForm;

  caseId: number;
  plData = [];
  caseModel: SurCaseModel;
  loadData: boolean;
  loading: boolean = false;
  nextRoute;
  prevRoute;

  fields: SurgeryCaseFieldsService;
  routerHelper: RouterHelper;
  activatedRoute: ActivatedRoute;
  caseService: SurCaseService;
  user: LoginService;
  procedurePicklistService: SurProcedurePicklistService;
  public surCardiacDiagnosisService: SurCardiacDiagnosisService;
  constructor(private router: Router,
      _activatedRoute: ActivatedRoute,
    _fields: SurgeryCaseFieldsService,
    _caseService: SurCaseService,
      _user: LoginService,
    _procedurePicklistService: SurProcedurePicklistService,
    _surCardiacDiagnosisService: SurCardiacDiagnosisService) {
   
    this.activatedRoute = _activatedRoute;
    this.caseService = _caseService;
    this.fields = _fields;
    this.user = _user;
       this.routerHelper = new RouterHelper(this.router, this.activatedRoute);      
    this.setCaseBase();
    this.procedurePicklistService = _procedurePicklistService;
    this.surCardiacDiagnosisService = _surCardiacDiagnosisService;
    localStorage.setItem('casedatabeforechange', JSON.stringify(this.fields.fields));
    console.log('before');
    console.log(this.fields.fields)
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(): Observable<boolean> | boolean {
    console.log('after');
    console.log(this.fields.fields);
    if (localStorage.getItem('casedatabeforechange') == JSON.stringify(this.fields.fields))
      return true;
    else
      return false;
  }


  validateProcedurePicklist(ppr: SurProcedurePicklistResponse) {
    this.procedurePicklistService.procedureList = ppr.DataObject;
    this.procedurePicklistService.mapToDropDown();

   // this.setCaseData();
  }



  private setCaseBase() {
    console.log("setCaseBase");
    this.loadData = false;
    let url = this.router.url;
    let urlSplit = url.split("/");
    let id = urlSplit[urlSplit.length - 1];
    this.caseId = +id;

   // this.setCaseListData();

    this.getFieldsBase();       
  }

  private getFieldsBase() {
    console.log("getFieldsBase");
    let fieldsCaseId = this.fields.getField("Case Identification", 'Case Number').value;
    if (fieldsCaseId != this.caseId) {
      if (this.caseId) {        
        this.fields.caseLoaded = false;        
        this.fields.type = "edit";
        this.fields.resetFields();

        this.fields.setValue("Case Identification", "Case Number", this.caseId);
        this.caseService.getCaseDetails(this.caseId).subscribe(data => this.loadResponse(data));

        this.fields.getParent("Case Identification")["link"] = this.fields.getParent("Case Identification")["link"] + "/" + this.caseId;
        this.fields.getParent("Clinical Characteristics")["link"] = this.fields.getParent("Clinical Characteristics")["link"] + "/" + this.caseId;
        this.fields.getParent("Medical History")["link"] = this.fields.getParent("Medical History")["link"] + "/" + this.caseId;
        this.fields.getParent("Surgical Procedures")["link"] = this.fields.getParent("Surgical Procedures")["link"] + "/" + this.caseId;
        this.fields.getParent("Surgery Events")["link"] = this.fields.getParent("Surgery Events")["link"] + "/" + this.caseId;
        this.fields.getParent("Clinical Outcomes")["link"] = this.fields.getParent("Clinical Outcomes")["link"] + "/" + this.caseId;
        this.fields.getParent("Day 30 Status")["link"] = this.fields.getParent("Day 30 Status")["link"] + "/" + this.caseId;
        this.fields.getParent("Procedural Efficacy")["link"] = this.fields.getParent("Procedural Efficacy")["link"] + "/" + this.caseId;
                       
      }
    }

  }

  private loadResponse(data: SurCaseResponse) {

    this.caseModel = data.DataObject;
    this.fields.caseLoaded = true;

    try {
      this.fields.setValue("Case Identification", 'Case Number', this.caseModel.SurCaseId);
      this.fields.setValue("Case Identification", "Hospital", this.caseModel.SurLocationId);
      this.fields.setValue("Case Identification", "Surg Date", new Date(this.caseModel.SurgDate));
      this.fields.setValue("Case Identification", "uniquePatientId", this.caseModel.SurCaseUniqueIdentifier);
      this.fields.setValue("Case Identification", "PIPofcardiacSurgeons", this.convertTrueFalse(this.caseModel.SurPerfPVCSGInd));
      if (this.caseModel.PrimarySurgeonId > 0) {
        this.fields.setValue("Case Identification", "Surgeon", this.caseModel.PrimarySurgeonId);
      }


    } catch {
      console.log("Error loading Case Identification");
    }

    try {
      if (this.caseModel.SurCaseClinicalInfo && this.caseModel.SurCaseClinicalInfo.SurCaseClinicalInfoId > 0) {
        this.fields.setValue("Clinical Characteristics", 'Id', this.caseModel.SurCaseClinicalInfo.SurCaseClinicalInfoId);
        this.fields.setValue("Clinical Characteristics", 'Prematurity Unit', this.getprematurevalue(this.caseModel.SurCaseClinicalInfo.PatPreMaturityVal));
        if (this.caseModel.SurCaseClinicalInfo.PatAge > 0) {
          this.fields.setValue("Clinical Characteristics", 'Age', this.caseModel.SurCaseClinicalInfo.PatAge);
          this.fields.setValue("Clinical Characteristics", 'Age Unit', this.convertAgeType(this.caseModel.SurCaseClinicalInfo.PatAgeType));
        }
        if (this.caseModel.SurCaseClinicalInfo.PatSex != '') {
          this.fields.setValue("Clinical Characteristics", 'Sex', this.caseModel.SurCaseClinicalInfo.PatSex);
        }
        if (this.caseModel.SurCaseClinicalInfo.PatWeight > 0) {
          this.fields.setValue("Clinical Characteristics", 'Weight', this.caseModel.SurCaseClinicalInfo.PatWeight);
        }
        if (this.caseModel.SurCaseClinicalInfo.PatHeight > 0) {
          this.fields.setValue("Clinical Characteristics", 'Height', this.caseModel.SurCaseClinicalInfo.PatHeight);
        }
        if (this.caseModel.SurCaseClinicalInfo.PatBSA > 0) {
          this.fields.setValue("Clinical Characteristics", 'BSA', this.caseModel.SurCaseClinicalInfo.PatBSA);
        }
        if (this.caseModel.SurCaseClinicalInfo.SurHemotocritVal != '') {
          this.fields.setValue("Clinical Characteristics", 'Hematocrit', this.caseModel.SurCaseClinicalInfo.SurHemotocritVal);
        }
        if (this.caseModel.SurCaseClinicalInfo.SurOxygenSatVal != '') {
          this.fields.setValue("Clinical Characteristics", 'OxygenSaturation', this.caseModel.SurCaseClinicalInfo.SurOxygenSatVal);
        }
      }
      // else {
      //  this.fields.setValue("Clinical Characteristics", 'Id', 0);
      //}
    } catch {
      console.log("Error loading Clinical Characteristics");
    }

    try {
      if (this.caseModel.SurCaseMedicalHistInfo && this.caseModel.SurCaseMedicalHistInfo.SurCaseMedHistId > 0) {
        this.fields.setValue("Medical History", 'SurCaseMedHistId', this.caseModel.SurCaseMedicalHistInfo.SurCaseMedHistId);
        if(this.caseModel.SurCaseMedicalHistInfo.CardiacDiagCodes.length >0)
        this.fields.setValue("Medical History", 'Recorded Events', this.getMedicalHistoryprocedures(this.caseModel.SurCaseMedicalHistInfo.CardiacDiagCodes));
        this.fields.setValue("Medical History", 'RheumaticHeartDiseaseInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.RheumeticFeverInd));

        this.fields.setValue("Medical History", 'GenericsyndromeInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.GenericSyndInd));
        this.fields.setValue("Medical History", 'GenericsyndromeVal', this.caseModel.SurCaseMedicalHistInfo.GenericSyngVal);

        this.fields.setValue("Medical History", 'StructuralAnomalyInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.MajorNcsAnomalyInd));
        this.fields.setValue("Medical History", 'StructuralAnomalyVal', this.caseModel.SurCaseMedicalHistInfo.MajorNcsAnomalyVal);
        this.fields.setValue("Medical History", 'MedicalIllnessInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.MajorNcMedIllnessInd));
        this.fields.setValue("Medical History", 'MedicalIllnessVal', this.caseModel.SurCaseMedicalHistInfo.MajorNcMedIllnessVal);

        this.fields.setValue("Medical History", 'PreviousCardiacSurgeryInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PrevCardSurgInd));
        this.fields.setValue("Medical History", 'NumberOfUniqueCardiacOperations', this.caseModel.SurCaseMedicalHistInfo.PrevCardSurgeries);

        this.fields.setValue("Medical History", 'PreviousCardiacCatheterizationInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PrevCardCathInd));

        this.fields.setValue("Medical History", 'BalloonAtrioseptostomyBeforeSurgeryInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PreoprBakatriBSInd));
        this.fields.setValue("Medical History", 'ResuscitationBeforeSurgeryInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PreoprResuscitBSInd));
        this.fields.setValue("Medical History", 'InotropeTherapyBeforeSurgeryInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PreoprInotheraBSInd));
        this.fields.setValue("Medical History", 'VentilationPreoperativelyInd', this.getYesNo(this.caseModel.SurCaseMedicalHistInfo.PreoprVentpreopInd));
      }
    } catch {
      console.log("Error loading Medical History");
    }

    try {
      if (this.caseModel.SurCaseProcAsgnmtInfo && this.caseModel.SurCaseProcAsgnmtInfo.SurCaseProcAsgnId > 0) {
        this.fields.setValue("Surgical Procedures", 'SurCaseProcAsgnId', this.caseModel.SurCaseProcAsgnmtInfo.SurCaseProcAsgnId);
        this.fields.setValue("Surgical Procedures", 'Recorded Events', this.getprocedures(this.caseModel.SurCaseProcAsgnmtInfo.ProcedureCode));
        this.fields.setValue("Surgical Procedures", 'Description', this.caseModel.SurCaseProcAsgnmtInfo.ProceduresDescription);
        this.fields.setValue("Surgical Procedures", 'RACHS-1 Category', this.caseModel.SurCaseProcAsgnmtInfo.ProceduresRachsValue);
      }
    } catch {
      console.log("Error loading Surgical Procedures");
    }


    try {
      if (this.caseModel.SurCaseEventsInfo && this.caseModel.SurCaseEventsInfo.SurCaseEventId > 0) {
        this.fields.setValue("Surgery Events", 'SurCaseEventId', this.caseModel.SurCaseEventsInfo.SurCaseEventId);
        this.fields.setValue("Surgery Events", 'PatChestOpenSurgeryInd', this.convertTrueFalse(this.caseModel.SurCaseEventsInfo.PatChestOpenSurgeryInd));
        this.fields.setValue("Surgery Events", 'PatCarioBypassRelEventInd', this.convertTrueFalse(this.caseModel.SurCaseEventsInfo.PatCarioBypassRelEventInd));
        this.fields.setValue("Surgery Events", 'CPBEventsList', this.caseModel.SurCaseEventsInfo.PatCarioBypassRelEventNotes);
      }
    } catch {
      console.log("Error loading Surgery Evenets");
    }

    try {
      if (this.caseModel.SurCase30DayStatusInfo && this.caseModel.SurCase30DayStatusInfo.SurCase30DayStatusId > 0) {
        this.fields.setValue("Day 30 Status", 'SurCase30DayStatusId', this.caseModel.SurCase30DayStatusInfo.SurCase30DayStatusId);
        this.fields.setValue("Day 30 Status", 'IsPatientAlive', this.getYesNoUnknown(this.caseModel.SurCase30DayStatusInfo.IsPatientAlive));
        this.fields.setValue("Day 30 Status", 'IsPatientDischarged', this.getYesNo(this.caseModel.SurCase30DayStatusInfo.IsPatientDischarged));
        this.fields.setValue("Day 30 Status", 'IsPatientDoingWell', this.getYesNo(this.caseModel.SurCase30DayStatusInfo.IsPatientDoingWell));
      }
    } catch {
      console.log("Error loading Day 30 Status");
    }
     try {
      if (this.caseModel.SurCaseProcEfficacy && this.caseModel.SurCaseProcEfficacy.length > 0) {
          let peFields: any[] = this.fields.fields.find(x => x.name == "Procedural Efficacy")["fields"]
          let setValue: any = null;
          for (let fobj of peFields) {
            if (fobj.proccode !== undefined)
              setValue = this.caseModel.SurCaseProcEfficacy.sort((a, b) => (a.EfficacyDataName > b.EfficacyDataName) ? 1 : (b.EfficacyDataName > a.EfficacyDataName) ? -1 : 0).find(x => (x.EfficacyProcedureCode.trim() == fobj.proccode.trim())).EfficacyDataValue;
            else
              setValue = this.caseModel.SurCaseProcEfficacy.find(x => x.EfficacyDataName.trim() == fobj.elementcode.trim()) === undefined ? null : this.caseModel.SurCaseProcEfficacy.find(x => x.EfficacyDataName.trim() == fobj.elementcode.trim()).EfficacyDataValue;
            if(fobj.fieldtype == 'boolean')
              this.fields.setValue("Procedural Efficacy", fobj.name, JSON.parse(setValue));
            else if (fobj.fieldtype == 'radio')
              this.fields.setValue("Procedural Efficacy", fobj.name, setValue);
            else
              this.fields.setValue("Procedural Efficacy", fobj.name, setValue);

            setValue = null;
          }
      }
    }
    catch (ex) {
      console.log(ex)
    //catch {
    //  console.log("Error loading Procedural Efficacy");
    }

    try {
      if (this.caseModel.SurCaseOutcomesInfo && this.caseModel.SurCaseOutcomesInfo.SurCaseClinicalOutComesId > 0) {
        this.fields.setValue("Clinical Outcomes", 'SurCaseClinicalOutComesId', this.caseModel.SurCaseOutcomesInfo.SurCaseClinicalOutComesId);
        this.fields.setValue("Clinical Outcomes", 'InHospDeath', this.getYesNo(this.caseModel.SurCaseOutcomesInfo.HospitalDeathInd));
        this.fields.setValue("Clinical Outcomes", 'dOD', new Date(this.caseModel.SurCaseOutcomesInfo.HospitalDeathDt));
        this.fields.setValue("Clinical Outcomes", 'dODDescription', this.caseModel.SurCaseOutcomesInfo.HospitalDeathReason);

        this.fields.setValue("Clinical Outcomes", 'BactSepsis', this.getYesNo(this.caseModel.SurCaseOutcomesInfo.BactSepsisInfectionInd));
        this.fields.setValue("Clinical Outcomes", 'BactSepsisDescription', this.caseModel.SurCaseOutcomesInfo.BactSepsisInfectionDesc);

        this.fields.setValue("Clinical Outcomes", 'SurgicalSiteInfection', this.getYesNo(this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionInd));
        this.fields.setValue("Clinical Outcomes", 'SurgicalSiteInfectionType', this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionVal);
        this.fields.setValue("Clinical Outcomes", 'SurSiteInfectionDescription', this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionDesc);


        this.fields.setValue("Clinical Outcomes", 'HospitalInfections', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteAddlInfectionInd));

        this.fields.setValue("Clinical Outcomes", 'VentPneumonia', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionVaeInd));
        this.fields.setValue("Clinical Outcomes", 'VentPneumoniaDescription', this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionVaeDesc);

        this.fields.setValue("Clinical Outcomes", 'CathInfection', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionCabiInd));
        this.fields.setValue("Clinical Outcomes", 'CathInfectionDescription', this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionCabiDesc);

        this.fields.setValue("Clinical Outcomes", 'cathUTI', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionCautiInd));
        this.fields.setValue("Clinical Outcomes", 'CathUTIDescription', this.caseModel.SurCaseOutcomesInfo.SurgSiteInfectionCautiDesc);

        this.fields.setValue("Clinical Outcomes", 'AnyComplications', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationInd));
        this.fields.setValue("Clinical Outcomes", 'ReqAddSurgeryForBleeding', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationASEBInd));
        this.fields.setValue("Clinical Outcomes", 'ReIntubationIn24hrs', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationRIWI24Ind));
        this.fields.setValue("Clinical Outcomes", 'ICUReAdmissionAfter24Hrs', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationICR24DInd));
        this.fields.setValue("Clinical Outcomes", 'DiaphragmaticParalysis', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationDIAPInd));
        this.fields.setValue("Clinical Outcomes", 'PostSurgicalHeartBlock', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationHBNPMInd));
        this.fields.setValue("Clinical Outcomes", 'Tracheostomy', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationTDSAAGInd));
        this.fields.setValue("Clinical Outcomes", 'PostOperativeSeizures', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationPOSInd));
        this.fields.setValue("Clinical Outcomes", 'AcuteKidneyInjury', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationAKIRDInd));
        this.fields.setValue("Clinical Outcomes", 'OtherComplications', this.getYesNoNotCollected(this.caseModel.SurCaseOutcomesInfo.SurgSiteComplicationOtherInd));
        //this.fields.setValue("Clinical Outcomes", 'OtherComplicationsDescription', this.caseModel.SurCaseOutcomesInfo.SurCaseEventId);
      }
    } catch {
      console.log("Error loading Clinical Outcomes");
    }

  }

  private getMedicalHistoryprocedures(procList: string): any {
    let recordedEventList: any = {}
    let procs: any[] = null
    this.surCardiacDiagnosisService.getCardiacDiagnosisList().subscribe((data) => {
    //this.procedurePicklistService.getProcedurePicklist().subscribe((data) => {
      this.surCardiacDiagnosisService.cardiadiagList = data.DataObject;
      this.surCardiacDiagnosisService.mapToDropDown();
      let events: SurDropdownItemModel[] = this.surCardiacDiagnosisService.dropDowncardiadiagList;
      if (!procs) {
        this.fields.getField("Medical History", "Recorded Events").value = {};
      }
      procs = this.fields.getField("Medical History", "Recorded Events").value;
      let pList = procList.split('|')
      pList.forEach(proc => {
        events.forEach(obj => {
          obj.children.forEach(child => {
            if (child.children.length > 0) {
              child.children.forEach(subChild => {
                if (subChild.code == proc) {
                  let nodeString: string = obj.name + ' | ' + child.name + ' | ' + subChild.name
                  procs[nodeString] = {
                    id: "procedure-" + subChild.code
                  };
                }
              })
            }
            else {
              if (child.code == proc) {
                let nodeString: string = obj.name + ' | ' + child.name + ' | ' + child.name
                procs[nodeString] = {
                  id: "procedure-" + child.code
                };
              }
            }
          })
        })
      })

    });
    return procs;
  }
  private getprocedures(procList: string): any {
    let recordedEventList: any = {}
    let procs: any[] = null
    this.procedurePicklistService.getProcedurePicklist().subscribe((data)  => {
      this.procedurePicklistService.procedureList = data.DataObject;
      this.procedurePicklistService.mapToDropDown();
      let events: SurDropdownItemModel[] = this.procedurePicklistService.dropDownProcedureList;
      if (!procs) {
        this.fields.getField("Surgical Procedures", "Recorded Events").value = {};
      }
      procs = this.fields.getField("Surgical Procedures", "Recorded Events").value;
      let pList = procList.split('|')
      pList.forEach(proc => {
        events.forEach(obj => {
          obj.children.forEach(child => {
            if (child.children.length > 0) {
              child.children.forEach(subChild => {
                if (subChild.code == proc) {
                  let nodeString: string = obj.name + ' | ' + child.name + ' | ' + subChild.name
                  procs[nodeString] = {
                    id: "procedure-" + subChild.code
                  };
                }
              })
            }
            else {
              if (child.code == proc) {
                let nodeString: string = obj.name + ' | ' + child.name + ' | ' + child.name
                procs[nodeString] = {
                  id: "procedure-" + child.code
                };
              }
            }
          })
        })
      })

    });
    return procs;
  }
  
  private setupProcedureEfficacyData() {
    var isProcEfficacyDataEntered: boolean = false;
    if (this.convertTrueFalse(this.fields.getField("Procedural Efficacy", "ASDRepairStatus").value)) {
     // this.fields.setValue("Procedural Efficacy", 'ASDRepairStatus', this.caseModel.SurCaseProcEfficacy[0].);
    }
  }
  private getYesNoUnknown(type: string): string {
    if (type === '1') {
      return "true";
    }
    if (type === '0') {
      return "false";
    }
    if (type === '-1') {
      return "Unknown";
    }
  }
  private loadTime(time: string): Date {
    if (time.length > 0) {
      let today = new Date();
      let todaySplit = today.toString().split(" ");
      todaySplit[4] = time;
      let d = todaySplit.join(" ");
      return new Date(d);
    }

    return null;
  }

  private loadDuration(time1: string, time2: string): string {
    if (time1.length > 0 && time2.length > 0) {
      try {
        let time1Array = time1.split(":");
        let time1Hours = +time1Array[0];
        let time1Mins = +time1Array[1];
        let time1Full = (time1Hours * 60) + time1Mins;

        let time2Array = time2.split(":");
        let time2Hours = +time2Array[0];
        let time2Mins = +time2Array[1];
        let time2Full = (time2Hours * 60) + time2Mins;

        let timeMinutes = 0;
        //if inTime is larger than outTime, assume outTime is the next day, so add 1440 minutes to outTime
        if (time1Full > time2Full) {
          time2Full = time2Full + 1440;
        }
        timeMinutes = (time2Full - time1Full);

        let hours = Math.floor(timeMinutes / 60);
        let minutes = timeMinutes % 60;

        var format = function (val) {
          return (val < 10) ? "0" + val : val;
        }
        var timeFormat = function (hr, min) {
          return hr + ':' + min;
        }

        let hourStr = format(hours);
        let minuteStr = format(minutes)
        return timeFormat(hourStr, minuteStr);
      } catch {
        return "";
      }
    }
    return "";
  }

  private convertTrueFalse(type: string): boolean {
    if (type == null) {
      return null;
    }

    return (type === "1");
  }

  private getYesNo(type: string): string {
    if (type === null || type === "") {
      return null;
    }

    if (type === '1') {
      return "Yes";
    }
    if (type === '0') {
      return "No";
    }
  }

  private getYesNoNotCollected(type: string): string {
    if (type === null) {
      return null;
    }

    if (type === '1') {
      return "Yes";
    }
    if (type === '0') {
      return "No";
    }
    if (type === '3') {
      return "Not Collected";
    }
  }

  private getprematurevalue(type: number): string {
    if (type === null) {
      return null;
    }
    if (type === 1) {
      return 'Yes';
    }
    if (type === 0) {
      return 'No';
    }
    if (type === -1) {
      return 'Unknown';
    }
  }

  private convertTrueFalseForElective(type: string): boolean {
    if (type == null) {
      return null;
    }

    return (type != "0" );
  }

  private convertAgeType(type: number): string {
    if (type === 0) {
      return 'Days';
    }
    if (type === 1) {
      return 'Months';
    }
    if (type === 2) {
      return 'Years';
    }
  }

  saveCase() {
    this.caseService.route = "editsurgerycase"
     this.caseService.saveCaseId = this.caseModel.SurCaseId;       
  }

  ngOnInit() {
    localStorage.setItem('previousurl', 'edit-surgery-case');
  }

  ngOnDestroy() {
    this.fields.resetFields();
  }
}
