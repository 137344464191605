import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { Subscription, Observable } from "rxjs";
import { SurdataSharedAttributesService } from '../../../shared-data-entry/surdata-sharedattributes.service';
import { ComponentCanDeactivate } from "../../../../CanDeactivateGaurd";
import { NgForm } from "@angular/forms";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";


@Component({
  selector: "app-surgery-clinical-char",
  templateUrl: "./surgery-clinical-char.component.html",
  styleUrls: ["./surgery-clinical-char.component.scss"],
  host: { class: "host" }
})
export class SurgeryClinicalCharComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  name = "Clinical Characteristics";
  min = 1;
  max = 31;
  age;
  ageError = false;
  weight;
  height;
  showErrorMessage: boolean = false;
  premature: boolean = false;
  shareddata: SurdataSharedAttributesService;
  sharedattributes = [];
  oxygenSaturation: number = null;
  public fields: SurgeryCaseFieldsService;
  constructor(private router: Router, fields: SurgeryCaseFieldsService, _shareddata: SurdataSharedAttributesService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.fields = fields;
     // if (this.fields.getField("Case Identification", "Case Number").value == 0) {
      this.calcBSA();
      this.shareddata = _shareddata;
      this.sharedattributes = this.shareddata.getsurattributes();
     // this.setPatienteAge();
   // }
  }

  ngOnInit() {
    localStorage.removeItem('surgeryclinicalchardirty');
    if (this.fields.getField(this.name, "Age Unit").value !== null)
      this.setMinMax(this.fields.getField(this.name, "Age Unit").value);
  }

 
  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('surgeryclinicalchardirty', 'true');

    return !this.form.dirty;
  }
  calcBSA() {
    this.weight = +this.fields.getValue(this.name, "Weight");
    if (this.weight <= 0 || this.weight > 500) {
      this.weight = null;
    }
    this.fields.setValue(this.name, "Weight", this.weight);

    this.height = +this.fields.getValue(this.name, "Height");
    if (this.height <= 0 || this.height > 500) {
      this.height = null;
    }
    this.fields.setValue(this.name, "Height", this.height);

    if (this.weight && this.height) {
      this.fields.getField(
        this.name,
        "BSA"
      ).value = SurgeryClinicalCharComponent.round(
        Math.sqrt((this.height * this.weight) / 3600),
        2
      );
    } else {
      this.fields.setValue(this.name, "BSA", null);
    }
  }

  static round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }

  validateOxSat(val) {
    if (val <= 100 && val.indexOf(".") == -1) {
      this.oxygenSaturation = val;
    } else {
      this.oxygenSaturation = null;
    }
  }
  setMinMax(ageType: any) {
    this.fields.getField(this.name, 'Age Unit').value = ageType;
    if (ageType === "Days") {
      this.max = 31;
      this.premature = true;
      this.fields.getField(this.name, 'Prematurity Unit').hide = false;
    }
    else if (ageType === "Months") {
      this.max = 12;
      this.premature = true;
      this.fields.getField(this.name, 'Prematurity Unit').hide = false;
    }
    else if (ageType === "Years") {
      this.max = 100;
      this.premature = false;
      this.fields.getField(this.name, 'Prematurity Unit').hide = true;
      this.fields.getField(this.name, 'Prematurity Unit').value = null;
    }
    if (this.age) {
      this.checkVal();
    }

  }
  setPatienteAge() {
    if (this.shareddata) {
      let dob = this.shareddata.suratrributes["DateofBirth"];
      let surgdate = this.fields.getField("Case Identification", "Surg Date").value;
      if (new Date(surgdate) < new Date(dob)) {
        console.error("Start date must be before end date");
        return null;
      }
      var endYear  = new Date(surgdate).getFullYear();
      var endMonth = new Date(surgdate).getMonth();
      var endDay  = new Date(surgdate).getDate();

      var startYear = new Date(dob).getFullYear();
      var startMonth = new Date(dob).getMonth();
      var startDay = new Date(dob).getDate();

      // We calculate February based on end year as it might be a leep year which might influence the number of days.
      var february = (endYear % 4 == 0 && endYear % 100 != 0) || endYear % 400 == 0 ? 29 : 28;
      var daysOfMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      var startDateNotPassedInEndYear = (endMonth < startMonth) || endMonth == startMonth && endDay < startDay;
      var years = endYear - startYear - (startDateNotPassedInEndYear ? 1 : 0);

      var months = (12 + endMonth - startMonth - (endDay < startDay ? 1 : 0)) % 12;

      // (12 + …) % 12 makes sure index is always between 0 and 11
      var days = startDay <= endDay ? endDay - startDay : daysOfMonth[(12 + endMonth - 1) % 12] - startDay + endDay;


      if (years >= 1) {
        this.fields.setValue(this.name, "Age", years);
        this.setMinMax("Years");
       this.premature = false;

      }
      else if (months > 1 && years <1 ) {
        this.fields.setValue(this.name, "Age", months);
        this.setMinMax("Months");
        this.premature = true;
      }
      else {
        this.fields.setValue(this.name, "Age", days);
        this.setMinMax("Days");
        this.premature = true;
      }
    }
  }
  checkVal() {
    this.age = this.fields.getField(this.name, 'Age').value;
    // this.age = Math.round(this.age);
    this.age = this.age.toString().split('.')[0];
    this.fields.getField(this.name, 'Age').value = this.age;

    if (this.age > this.max) {
      this.fields.getField(this.name, 'Age').value = this.max;
    } else if (this.age < this.min) {
      this.fields.getField(this.name, 'Age').value = this.min;
    }
    else {
      this.ageError = false;
    }
  }
}
