import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cathchat',
  templateUrl: './cathchat.component.html',
  styleUrls: ['./cathchat.component.scss'],
  host: { 'class': "host" }
})
export class CathChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
