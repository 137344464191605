
import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from './components/user/login.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanSurgeryCaseDeactivateGaurd implements CanDeactivate<ComponentCanDeactivate> {
  
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    
    let componentChange = component.canDeactivate();

    if (localStorage.getItem('editsurgerycase').indexOf('edit-surgery-case') < 0 && localStorage.getItem('editsurgerycase').indexOf('add-surgery-case') < 0) {

        if (componentChange == false || localStorage.getItem('dirty') == 'true') {
          localStorage.removeItem

          localStorage.removeItem('dirty');
          localStorage.removeItem('adverseevents')
          localStorage.removeItem('casetypes')
          localStorage.removeItem('casetypedone')
          localStorage.removeItem('hemo')
          localStorage.removeItem('prehemo')
          localStorage.removeItem('precasetypes')

          if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
            return true;
          } else {
            return false;
          }
        }
        else {

          return true;
        }

      }

      return true;
    }
}
