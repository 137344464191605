import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SurCardiacDiagnosisModelResponse } from '../../shared/iqis/surcardiac-diagnosis-response';
import { SurCardiacDiagnosisModel } from '../../shared/iqis/surcardiac-diagnosis-model';
import { SurDropdownItemModel } from '../../shared/iqis/surdropdown-item-model';
import { SURGERYCONFIG } from '../../shared/surgeryconfig';

let iqisapiUrl = SURGERYCONFIG.baseUrls.iqisapiurl;
@Injectable()
export class SurCardiacDiagnosisService {
  private http: Http = null;

  public cardiadiagList: SurCardiacDiagnosisModel[];
  public dropDowncardiadiagList: SurDropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }
   getCardiacDiagnosisList() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(iqisapiUrl + "picklist/GetCardiacDiagnosisList", options)
      .pipe(map((response: Response) => <SurCardiacDiagnosisModelResponse>response.json()));  
  }

  mapToDropDown() {
    let ids = [];
    this.dropDowncardiadiagList = [];

    for (let entry of this.cardiadiagList) {
      if (ids.indexOf(entry.ParentCDiagnosisId) > -1) {
        ids.push(entry.CDiagnosisId);

        let newChildItem = new SurDropdownItemModel;
        newChildItem.id = entry.CDiagnosisId;
        newChildItem.name = entry.CDiagnosisTextToShow;
        newChildItem.code = entry.CDiagnosisCode;
        newChildItem.children = [];

        let parentItem = this.dropDowncardiadiagList.find(x => x.id === entry.ParentCDiagnosisId);
        if (parentItem != null) {
          if (parentItem.children == null) {
            parentItem.children = [];
          }

          
            parentItem.children.push(newChildItem);
          
        } else {
          let found = false;
          while (found === false) {
            for (let item of this.dropDowncardiadiagList) {
              let childParentItem = item.children.find(x => x.id === entry.ParentCDiagnosisId);
              if (childParentItem != null) {
                if (childParentItem.children == null) {
                  childParentItem.children = [];
                }

                  childParentItem.children.push(newChildItem);
                
                found = true;
                break;
              }
            }
          }          
        }       
      } else {
        ids.push(entry.ParentCDiagnosisId);
        ids.push(entry.CDiagnosisId);

          let newParentItem = new SurDropdownItemModel;
        newParentItem.id = entry.ParentCDiagnosisId;
        newParentItem.name = entry.ParentCDiagnosisTextToShow;
          newParentItem.children = [];

          let newChildItem = new SurDropdownItemModel;
        newChildItem.id = entry.CDiagnosisId;
        newChildItem.name = entry.CDiagnosisTextToShow;
        newChildItem.code = entry.CDiagnosisCode;
          newChildItem.children = [];
          newParentItem.children.push(newChildItem);
        this.dropDowncardiadiagList.push(newParentItem);
            
      }
    }

  }
}
