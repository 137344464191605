import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SurCaseModel } from '../../../shared/iqis/surcase/surcase-model';
import { SurCaseResponse } from '../../../shared/iqis/surcase/surcase-response';
import { SurCaseSaveResponse } from '../../../shared/iqis/surcase-save/surcase-save-response';
import { MarkSurCaseCompleteResponse } from '../../../shared/iqis/surcase-save/mark-surcase-complete-response';
import { MarkSurCaseInactiveResponse } from '../../../shared/iqis/surcase-save/mark-surcase-inactive-response';

import { SURGERYCONFIG } from '../../../shared/surgeryconfig';
import { MarkSurCaseCompleteStatusModel } from "../../../shared/iqis/surcase-save/mark-surcase-complete-status-model";

let apiUrl = SURGERYCONFIG.baseUrls.iqisapiurl;
@Injectable({
  providedIn: "root"
})
export class SurCaseService {
  private http: Http = null;
  public surcase: SurCaseModel;
  public deleteCaseId: number;
  public saveCaseId: number;
  public IsCaseComplete: boolean;
  public MessageData: string;
  public route: string;

  constructor(_http: Http) {
    this.http = _http;
    this.surcase = new SurCaseModel;
    this.deleteCaseId = 0;
    this.saveCaseId = 0;
    this.IsCaseComplete = false;
    this.route = "";
  }  

  save() {
      return this.saveCase(this.surcase);
    
  }

  saveCase(caseItem: SurCaseModel) {
    
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "Surcase/SaveSurCaseDetails", caseItem, options)
      .pipe(map((response: Response) => <SurCaseSaveResponse>response.json()));

    
  }
  clearCase() {
    this.surcase = new SurCaseModel;
  }

  getCaseDetails(caseid: number) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "surcase/GetSurCaseDetails?SurCaseId=" + caseid, options)
      .pipe(map((response: Response) => <SurCaseResponse>response.json()));
  }

 markCaseComplete() {
    let ininfo = {
      SurCaseId: this.surcase.SurCaseId,
      SurCaseCompletedby: this.surcase.SurCaseUpdatedby
    };

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
  return this.http.post(apiUrl + "surcase/MarkCaseComplete", ininfo, options)
    .pipe
    (map(
      (response: Response) => <MarkSurCaseCompleteResponse>response.json()));
  }

  deactivateCase(updatedBy: string) {
    let ininfo = {
      CaseId: this.deleteCaseId,
      CaseInactivatedby: updatedBy
    };

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "surcase/DeactivateCase", ininfo, options)
      .pipe(map((response: Response) => <MarkSurCaseInactiveResponse>response.json()));
  }

}
