import { Component, OnInit, HostListener, ViewChild } from "@angular/core";
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";

import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { formatDate } from "@angular/common";
import { ComponentCanDeactivate } from "../../../../CanDeactivateGaurd";
import { Observable } from "rxjs";
import { NgForm } from "@angular/forms";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-day-30-status",
  templateUrl: "./day-30-status.component.html",
  styleUrls: ["./day-30-status.component.scss"],
  host: { class: "host" },
})
export class Day30StatusComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  public fields: SurgeryCaseFieldsService;
  name = "Day 30 Status";
  isPatientAlive: number;
  isDischarged: boolean = false;
  showTooltipText1: boolean = false;
  thirtyDaysMessage: any = null;
  public is30DayStatusVisible: boolean = false;
  constructor(private router: Router, _fields: SurgeryCaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.fields = _fields;
  }

  ngOnInit() {
    localStorage.removeItem('day30statusdirty');
    this.ThirtyDayStatusValidation()
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('day30statusdirty', 'true');
    if (this.fields.getField(this.name, "IsPatientAlive").value !== null) {
      localStorage.setItem('day30statusdirty', 'true');
    }
    return !this.form.dirty;
  }
  private ThirtyDayStatusValidation() {
    var surgeryDate = this.fields.getValue("Case Identification", "Surg Date");
    if (surgeryDate !== null) {
      var today = new Date();
      var lessthan30daysDate = new Date(new Date().setDate(today.getDate() - 30))
      if (formatDate(lessthan30daysDate, 'yyyy-MM-dd', 'en-US') <= formatDate(surgeryDate, 'yyyy-MM-dd', 'en-US')) {
        this.is30DayStatusVisible = true
        this.fields.fields.find(item => item.name == this.name).hide = true;
        this.thirtyDaysMessage = "30 day status can be entered 30 days after surgery. The date of surgery for this case is:  " + formatDate(surgeryDate, 'yyyy-MM-dd', 'en-US');
        this.fields.setValue(this.name, "IsPatientAlive", null);
        this.fields.setValue(this.name, "IsPatientDischarged", null);
        this.fields.setValue(this.name, "IsPatientDoingWell", null);
      }
      else {
        this.fields.fields.find(item => item.name == this.name).hide = false;
        if (this.fields.getValue("Clinical Outcomes", "InHospDeath") === 'No') {
          this.fields.getField(this.name, "IsPatientDischarged").hide = false;
          localStorage.setItem('day30statusdirty', 'true');
        }
        if (this.fields.getValue("Clinical Outcomes", "InHospDeath") === 'Yes') {
          this.fields.setValue(this.name, "IsPatientAlive", 'false');
          this.fields.getField(this.name, "IsPatientDischarged").hide = true;
          this.fields.getField(this.name, "IsPatientDoingWell").hide = true;
          this.fields.setValue(this.name, "IsPatientDischarged", null);
          this.fields.setValue(this.name, "IsPatientDoingWell", null);
          localStorage.setItem('day30statusdirty', 'true');
        }
      }
    }

  }
  showTooltip1(b: boolean) {

    this.showTooltipText1 = b;
  }
  toggleCheckbox(val) {
    //this.fields.setValue(name, 'IsPatientDischarged' false)
    if (val === 'IsPatientAlive' && this.fields.getValue(this.name, val) !== 'true') {
      this.fields.setValue(this.name, "IsPatientDischarged", null);
      this.fields.setValue(this.name, "IsPatientDoingWell", null);
      this.fields.getField(this.name, "IsPatientDischarged").hide = true;
      this.fields.getField(this.name, "IsPatientDoingWell").hide = true;
    }
    if (val === 'IsPatientDischarged' && this.fields.getValue(this.name, val) === 'No') {
      this.fields.setValue(this.name, "IsPatientDoingWell", null);
      this.fields.getField(this.name, "IsPatientDoingWell").hide = true;
    }
  }
}
