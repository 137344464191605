import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SurProcedurePicklistResponse } from '../../shared/iqis/surprocedure-picklist-response';
import { SurProcedurePicklistModel } from '../../shared/iqis/surprocedure-picklist-model';
import { SurDropdownItemModel } from '../../shared/iqis/surdropdown-item-model';
import { SURGERYCONFIG } from '../../shared/surgeryconfig';

let iqisapiUrl = SURGERYCONFIG.baseUrls.iqisapiurl;
@Injectable()
export class SurProcedurePicklistService {
  private http: Http = null;

  public procedureList: SurProcedurePicklistModel[];
  public dropDownProcedureList: SurDropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getProcedurePicklist() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(iqisapiUrl + "picklist/getprocedureslist", options)
               .pipe(map((response: Response) => <SurProcedurePicklistResponse>response.json()));  
  }

  mapToDropDown() {
    let ids = [];
    this.dropDownProcedureList = [];

    for (let entry of this.procedureList) {
      if (ids.indexOf(entry.ParentProcedureId) > -1) {
        ids.push(entry.ProcedureId);

        let newChildItem = new SurDropdownItemModel;
        newChildItem.id = entry.ProcedureId;
        newChildItem.name = entry.ProcedureTextToShow;
        newChildItem.code = entry.ProcedureCode;
        newChildItem.children = [];

        let parentItem = this.dropDownProcedureList.find(x => x.id === entry.ParentProcedureId);
        if (parentItem != null) {
          if (parentItem.children == null) {
            parentItem.children = [];
          }

          
            parentItem.children.push(newChildItem);
          
        } else {
          let found = false;
          while (found === false) {
            for (let item of this.dropDownProcedureList) {
              let childParentItem = item.children.find(x => x.id === entry.ParentProcedureId);
              if (childParentItem != null) {
                if (childParentItem.children == null) {
                  childParentItem.children = [];
                }

                  childParentItem.children.push(newChildItem);
                
                found = true;
                break;
              }
            }
          }          
        }       
      } else {
        ids.push(entry.ParentProcedureId);
        ids.push(entry.ProcedureId);

          let newParentItem = new SurDropdownItemModel;
          newParentItem.id = entry.ParentProcedureId;
          newParentItem.name = entry.ParentProcedureTextToShow;
          newParentItem.children = [];

          let newChildItem = new SurDropdownItemModel;
          newChildItem.id = entry.ProcedureId;
          newChildItem.name = entry.ProcedureTextToShow;
          newChildItem.code = entry.ProcedureCode;
          newChildItem.children = [];
          newParentItem.children.push(newChildItem);
          this.dropDownProcedureList.push(newParentItem);
            
      }
    }

  }
}
