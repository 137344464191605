export class SearchSurCaseModel {
  SurCaseId: number;
  UniqueIdentifier: string;
  LocationId: number;
  ThirtyDayStatusInd: string;
  ShowInactiveCases: boolean;        
  SurgDateFrom: string;
  SurgDateTo: string;
  AgeRange: string;
  SurgStatusCode: string;
  ProcedureCodes: string;
}
