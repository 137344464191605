import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SurgeryCaseFieldsService } from '../../add-surgery-case/surgery-case-fields.service';
import { SurdataSharedAttributesService } from '../../../shared-data-entry/surdata-sharedattributes.service';
import { LoginService } from "../../../user/login.service";
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-unique-id',
  templateUrl: './unique-id.component.html',
  styleUrls: ['./unique-id.component.scss'],
  host: { 'class': "host" }
})
export class UniqueIdComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  name = "IQIC Identifier";
  firstName: any;
  lastName: any;
  mrndigit: any;
  nameThird: any;
  surnameThird: any;
  DateofBirth: any;
  uniquePatientID: string = "";
  hospitalID: string = "";
  hospitaltext: string = "";
  showErrorMessage: boolean = false;
  AllowtoGenerate: boolean = true;
  showTooltipText: boolean = false;
  currentDate: Date = new Date();
  shareddata: SurdataSharedAttributesService;
  public fields;
  public user: LoginService;

  constructor(private router: Router, _user: LoginService, fields: SurgeryCaseFieldsService, _shareddata: SurdataSharedAttributesService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.fields = fields;
    this.user = _user;
    //this.shareddata = _shareddata;
  }


  ngOnInit() {
    localStorage.removeItem('uniqueiddirty');
  }


  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('uniqueiddirty', 'true');

    return !this.form.dirty;
  }

  inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[a-zA-Z]*$/;
    
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z]*$/g, "");
      // invalid character, prevent input

    }
  }

  generateID() {
    this.DateofBirth = this.fields.getField(this.name, 'DateofBirth').value;
    let nameFirst = this.firstName.slice(0, 1);
    if (this.firstName.length < 3) this.nameThird = '9';
    else
      this.nameThird = this.firstName.slice(2, 3);

    let surnameFirst = this.lastName.slice(0, 1);
    if (this.lastName.length < 3)
       this.surnameThird = '9';
    else
     this.surnameThird = this.lastName.slice(2, 3);
    this.hospitalID = this.user.user.HospitalId.toString();
    this.hospitaltext = this.user.user.Hospital.substring(0, 3);

    let day = ("0" + new Date(this.DateofBirth).getDate()).slice(-2)

    let mrn = this.mrndigit.toString(10).slice(2, 4);
    this.uniquePatientID = this.hospitaltext.concat(nameFirst, this.nameThird, surnameFirst, this.surnameThird, day, mrn).toUpperCase();
    //let temp = { "UniquePatientID": this.uniquePatientID, "DateofBirth": this.DateofBirth };
    this.fields.setValue(this.name, "uniquePatientId", this.uniquePatientID);
   // this.shareddata.savesuratrributes(temp);
  }

  showTooltip(b: boolean) {
    console.log("Tooltip shown " + b)
    this.showTooltipText = b;
  }

  setGhistLabelCss() {
    return this.uniquePatientID.length > 0 ? true : false
  }
  CheckIdentityEligibility() {
    this.DateofBirth = this.fields.getField(this.name, 'DateofBirth').value;
    if ((this.DateofBirth) && (this.firstName) && (this.lastName) && (this.mrndigit)) {
      var d = new Date(this.DateofBirth);
      if(d)
        var n = d.toString();
      else
        var n = "";
   
      if ((this.firstName.length >= 2) && (this.lastName.length >= 2) && n.length >= 3 && (this.mrndigit.toString().length > 3 && this.mrndigit.toString().length < 13))
        return false;
      else
        return true;
    }
    else
      return true;
  }

  errorMessageCheck() {
    //if (!Date.parse(this.DateofBirth)) {
    //  this.DateofBirth = "";
    //}
    let DOB = this.fields.getField(this.name, 'DateofBirth').value;
    let mill = Date.parse(DOB);
    if (!(mill > 0)) {
      this.fields.setValue(this.name, 'DateofBirth', null);
      $("#DateofBirth").val('');
    } else if (this.isfutureDate(DOB)) {
      this.fields.setValue(this.name, 'DateofBirth', new Date);
    }
  }

  private isfutureDate(value: string) {
    let now = new Date;
    let target = new Date(value);

    if (target.getFullYear() > now.getFullYear()) {
      return true;
    } else if (target.getFullYear() == now.getFullYear()) {
      if (target.getMonth() > now.getMonth()) {
        return true;
      } else if (target.getMonth() == now.getMonth()) {
        if (target.getDate() > now.getDate()) {
          return true;
        }
      }
    }

    return false;
  }

}
