import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CalculatorService } from '../calculator.service';
import { CaseFieldsService } from '../../user/add-case/case-fields.service';

@Component({
  selector: 'app-calc-characteristics',
  templateUrl: './calc-characteristics.component.html',
  styleUrls: ['./calc-characteristics.component.scss']
})
export class CalcCharacteristicsComponent implements OnInit {
  public isCaseCalc: boolean;
  public calc: CalculatorService;
  fields:CaseFieldsService;
  constructor(calc: CalculatorService, fields: CaseFieldsService, private router: Router) {
    this.calc = calc;
    this.fields = fields;

    let url = this.router.url;
    this.isCaseCalc = (url.indexOf("user-portal") > 0);
    this.calc.readOnly = this.isCaseCalc;
  
    if (this.isCaseCalc && this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value) {
      this.calc.stsSelection = this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value;
    }
   }

  ngOnInit() {
  }

  calcWeight() {
    let weight = +this.fields.getValue('Clinical Characteristics', "Weight");
    weight = Math.round(weight*10)/10;
    if (weight <= 0 || weight > 500) {
      weight = null;
    }

    this.fields.setValue('Clinical Characteristics', "Weight", weight);
  }
}
