export class SurCaseClinicalOutComesInfoModel {
  SurCaseClinicalOutComesId: number;
  SurCaseId: number;
  HospitalDeathInd: string;
  HospitalDeathDt: string;
  HospitalDeathReason: string;
  BactSepsisInfectionInd: string;
  BactSepsisInfectionDesc: string;
  SurgSiteInfectionInd: string;
  SurgSiteInfectionVal: string;
  SurgSiteInfectionDesc: string;
  SurgSiteAddlInfectionInd: string;
  SurgSiteInfectionVaeInd: string;
  SurgSiteInfectionVaeDesc: string;
  SurgSiteInfectionCabiInd: string;
  SurgSiteInfectionCabiDesc: string;
  SurgSiteInfectionCautiInd: string;
  SurgSiteInfectionCautiDesc: string;
  SurgSiteComplicationInd: string;
  SurgSiteComplicationASEBInd: string;
  SurgSiteComplicationRIWI24Ind: string;
  SurgSiteComplicationICR24DInd: string;
  SurgSiteComplicationDIAPInd: string;
  SurgSiteComplicationHBNPMInd: string;
  SurgSiteComplicationTDSAAGInd: string;
  SurgSiteComplicationPOSInd: string;
  SurgSiteComplicationAKIRDInd: string;
  SurgSiteComplicationOtherInd: string;
  SurgSiteComplicationOtherDesc: string;
  Updatedby: string;
}
