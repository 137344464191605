export class SearchModel {
  CaseId: number;
  CaseNumber: string;
  HospitalId: number;
  CompleteDataOnly: boolean;
  IncompleteDataOnly: boolean;        
  CathDateFrom: string;
  CathDateTo: string;
  AgeRange: string;
  ShowInactiveCases: boolean;
  ShowPedcathCases: boolean;
}
