import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../../shared/picklist-item-response';
import { PicklistItemModel } from '../../../../shared/picklist-item-model';
import { DropdownItemModel } from '../../../../shared/dropdown-item-model';
import { CaseFieldsService } from '../case-fields.service';
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";


declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-case-id',
  templateUrl: './case-id.component.html',
  styleUrls: ['./case-id.component.scss'],
  host: { 'class': "host" }
})
export class CaseIdComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  operators = [];
  hospitalText = "";
  name = "Case Identification";
  showErrorMessage: boolean = false;
  public currentDate: Date = new Date();

  public user: LoginService;
  public picklistItemService: PicklistItemService;
  public fields: CaseFieldsService;
  constructor(private router: Router,_user: LoginService, _picklistItemService: PicklistItemService, fields: CaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.user = _user;
    this.fields = fields;
    if (this.user.user) {
      this.hospitalText = this.user.user.Hospital;
      this.fields.setValue(this.name, "Hospital", this.user.user.HospitalId);
    }
    
    this.picklistItemService = _picklistItemService;
    this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "OPERATORS").subscribe(data => this.populatePicklistItems(data));

    this.errorMessageCheck();
  }

  ngOnInit() {
    localStorage.removeItem('dirty');
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');

   
      return !this.form.dirty;
  }

  populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.operators = this.picklistItemService.dropDownPicklistItem;
  }

  errorMessageCheck() {
    let cathDate = this.fields.getField(this.name, 'Cath Date').value;
    let mill = Date.parse(cathDate);
    if (!(mill > 0)) {
      this.fields.setValue(this.name, 'Cath Date', null);
      $("#cath-date").val('');
    } else if (this.isfutureDate(cathDate)) {
      this.fields.setValue(this.name, 'Cath Date', new Date);
    }

    if (this.user.user) {
      this.hospitalText = this.user.user.Hospital;
      this.fields.setValue(this.name, "Hospital", this.user.user.HospitalId);
    }

    this.showErrorMessage =
      ((this.fields.validateDate)
        && (this.fields.getField(this.name, 'Cath Date').value == null
          || this.fields.getField(this.name, 'Cath Date').value == ''));
  }

  private isfutureDate(value: string) {
    let now = new Date;
    let target = new Date(value);

    if (target.getFullYear() > now.getFullYear()) {
      return true;
    } else if (target.getFullYear() == now.getFullYear()) {
      if (target.getMonth() > now.getMonth()) {
        return true;
      } else if (target.getMonth() == now.getMonth()) {
        if (target.getDate() > now.getDate()) {
          return true;
        }
      }
    }

    return false;
  }
}
