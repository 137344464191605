import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { LoginService } from "../../../user/login.service";
import { SurPicklistItemService } from '../../../shared-data-entry/surpicklist-item.service';
import { SurPicklistItemResponse } from '../../../../shared/iqis/surpicklist-item-response';
import { SurPicklistItemModel } from '../../../../shared/iqis/surpicklist-item-model';
import { SurDropdownItemModel } from '../../../../shared/iqis/surdropdown-item-model';
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { ComponentCanDeactivate } from "../../../../cansuregerycasedeactivategaurd";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-surgery-events",
  templateUrl: "./surgery-events.component.html",
  styleUrls: ["./surgery-events.component.scss"],
  host: { class: "host" }
})
export class SurgeryEventsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
   name = "Surgery Events";
  showBypassList: boolean = false;
  showTooltipText: boolean = false;

  CardioPulmonaryBypassOptions = [];
  CardioPulmonaryBypass = [];
  selectedCardiopulmonary = [];
    public user: LoginService;
  public picklistItemService: SurPicklistItemService;
  public fields: SurgeryCaseFieldsService;

  constructor(private router: Router,_user: LoginService, _picklistItemService: SurPicklistItemService, fields: SurgeryCaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.user = _user;
    this.fields = fields;
    this.picklistItemService = _picklistItemService;
    this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "CPBEvents").subscribe(data => this.populatePicklistItems(data));
   }

  ngOnInit() {
    //localStorage.removeItem('surgeryeventsdirty');
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    //if (this.form.dirty)
    //  localStorage.setItem('surgeryeventsdirty', 'true');
    //if (this.fields.getField(this.name, "PatChestOpenSurgeryInd").value !== null) {
    //  localStorage.setItem('surgeryeventsdirty', 'true');
    //}

    return !this.form.dirty;
  }
  populatePicklistItems(picklistItemResponse: SurPicklistItemResponse) {
   
  this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
  this.picklistItemService.mapToDropDown();
  this.CardioPulmonaryBypassOptions = this.picklistItemService.dropDownPicklistItem;
  this.CardioPulmonaryBypass = this.CardioPulmonaryBypassOptions.map(x => x.name);
  this.loadProblems(this.CardioPulmonaryBypassOptions, this.CardioPulmonaryBypass, this.selectedCardiopulmonary, 'CPBEventsList'); 
}

  toggleCheckbox(select, val) {
    //this.showBypassList = select;
    console.log(val);
    this.fields.getField(this.name, val).hide = select;
    if (select === true) {
      this.fields.setValue(this.name, val, 0);
      this.fields.setValue(this.name, "CPBEventsList", null);
      this.selectedCardiopulmonary = null;
    }
    else {
      this.fields.setValue(this.name, val, 1);
    }
  }
  showTooltip(b: boolean) {
       this.showTooltipText = b;
  }

  private setSelectionFromFields() {
    try {

      for (let t of this.CardioPulmonaryBypassOptions) {
        this.setSelection(t)
      }

    } catch (ex) {
      console.log(ex);
    }
  }

  private setSelection(type) {
    let period: Array<number> = [0, 1];
    for (let p of period) {
      let list = null;
      list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
      let val = "";

      val = this.fields.getValue(this.name, type.name);


      if (type.multiple) {
        let valMap: Array<string> = [];
        let valList: Array<string> = [];
        if (val != null) {
          valList = val.split("|");
        }

        for (let v of valList) {
          for (let dropDownItem of list) {
            if (dropDownItem.code === v) {
              valMap.push(dropDownItem.name);
            }
          }
        }

        if (valMap.length === 0) {
          valMap = ["None"];
        }
        type.selections[p] = valMap;

      } else {
        let valMap = null;
        for (let dropDownItem of list) {
          if (dropDownItem.code === val) {
            valMap = dropDownItem.name;
          }
        }

        if (valMap === null) {
          valMap = "None";
        }

        type.selections[p] = valMap;

      }
    }
  }

  public updateProblems(dropdownOptions, dropdown, selectionOption, type) {
    if (selectionOption.length > 0 && Array.isArray(selectionOption) == true) {
      let valMap: Array<string> = [];

      for (let p of selectionOption) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.name === p) {
            valMap.push(dropDownItem.code)
          }
        }
      }
      this.fields.setValue(this.name, type, valMap.join("|"));
    } else {
      this.fields.setValue(this.name, type, null);
    }

    if (Array.isArray(selectionOption) === false) { this.fields.setValue(this.name, type, dropdownOptions.find(x => x.name == selectionOption)["code"]); }
  }

  private loadProblems(dropdownOptions, dropdown, selectionOption, type) {
    let valMap: Array<string> = [];
    let probs = this.fields.getField(this.name, type).value;
    if (probs != null) {
      let probArray = probs.split('|');

      for (let p of probArray) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.code === p) {
            valMap.push(dropDownItem.name);
          }
        }
      }
    }

    selectionOption = valMap;
    if (type === "CPBEventsList") {
      this.selectedCardiopulmonary = valMap;
    }

  }

}
