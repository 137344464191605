//import 'bootstrap.css';
import 'wijmo/styles/wijmo.css';
import 'wijmo/styles/themes/wijmo.theme.material.css';
//import './styles.css';
import { AppModule } from "../../../../app.module";
import { Component, enableProdMode, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from "../../../user/login.service";
import { ReportDataMM } from "../../../../shared/report-model-mm";
import { ReportService } from '../report-services/report.service';
import { CaseSaveResponse } from '../../../../shared/case-save/case-save-response';
import { DatePipe } from "@angular/common";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { WjGridModule } from 'wijmo/wijmo.angular2.grid';
import { WjInputModule } from 'wijmo/wijmo.angular2.input';
import * as wjcCore from 'wijmo/wijmo';
import * as wjcGrid from 'wijmo/wijmo.grid';
import * as wjcGridDetail from 'wijmo/wijmo.grid.detail';
import * as wjcInput from 'wijmo/wijmo.input';

import * as wjcGridPdf from 'wijmo/wijmo.grid.pdf';
import * as pdf from 'wijmo/wijmo.pdf';
import * as gridXls from 'wijmo/wijmo.grid.xlsx';

type TColumn = {
  header: string;
  binding: string;
  format: string;
}

@Component({

  template: `<div class="container-fluid">
    <input type="checkbox" #checkbox (click)="showGridDetails(checkbox, gridmm, $event)" id="expand-all" name="expand-all" [value]="false" />&nbsp;Expand All
    <!-- the grid -->
    <wj-flex-grid #gridmm
                  [selectionMode]="'None'"
                  [(itemsSource)]="cvPagingmm"
                  [allowSorting]="'MultiColumn'"
                  (initialized)="initializeGrid(gridmm)">

        <wj-flex-grid-column header="Flag" binding="Flag" [width]="47">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <span>{{item.Flag}}</span>
        </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Date Of Cath'" [binding]="'DateOfCath'" [width]="115">
          <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
             {{item.DateOfCath | date: d}}
           </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [header]="'MRN'" [binding]="'MRN'" [width]="88"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Last Name'" [binding]="'LastName'" [width]="100"></wj-flex-grid-column>
        <wj-flex-grid-column *ngIf="showOperatorColumn" [header]="'Operator'" [binding]="'Operator'" [width]="86"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Fellow'" [binding]="'Fellow'" [width]="82"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Adverse Event'" [binding]="'AEName'" [width]="'*'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Severity'" [binding]="'Severity'" [width]="75"></wj-flex-grid-column>
        <wj-flex-grid-column header="Reviewed" binding="Reviewed" [width]="88">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                <input type="checkbox" type="checkbox" [(ngModel)]="item.Reviewed" [ngModelOptions]="{standalone: true}"/>
        </ng-template>
        </wj-flex-grid-column>
        <wj-flex-grid-column [width]="40" [isReadOnly]="true"></wj-flex-grid-column>
        <ng-template wjFlexGridDetail #dp='wjFlexGridDetail' [maxHeight]="600" [detailVisibilityMode]="'ExpandMulti'" ngFor let-item="item">
            <div class="container-fluid">
                <div id="mmdetailslist" name="mmdetailslist">
                    <ul style="list-style: none;">
                        <li>
                            <div class="row">
                                <div class="col-sm-7 " id="mmCaseType" name="CaseType"><b>CaseType:</b>&nbsp;{{item.CaseType | slice:0:65}}</div>
                                <div class="col-sm-5 " id="mmAge" name="Age"><b>Age:</b>&nbsp;{{item.Age}}</div>
                            </div>
                            <div class="row">

                                <div class="col-sm-3 " id="mmTimeNeeded" name="Time Needed"><b>Time Needed</b>(min)<b>:</b>&nbsp;{{item.TimeNeeded}}</div>
                                <div class="col-sm-4 " id="mmPreventability" name="Preventability"><b>Preventability:</b>&nbsp;{{item.Preventability}}</div>
                                <div class="col-sm-5 " id="mmAttributability" name="Attributability"><b>Attributability:</b>&nbsp;{{item.Attributability}}</div>

                            </div>
                            <div class="row">
                                <div class="col-sm wrap-div-text" id="mmPresentation" name="Presentation"><b>Presentation:</b>{{item.Presentation}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12" id="emtyRow" name="emtyRow">&nbsp;</div>
                            </div>
                            <div class="row">
                                <div class="col-sm wrap-div-text" id="mmDescription" name="Description"><b>Description:</b>{{item.Description}}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-template>
    </wj-flex-grid>

    <!-- the popup editor -->
    <wj-popup #popup class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Edit MRN # {{editingItem != null ? editingItem.MRN : ''}}, Last Name: {{editingItem != null ? editingItem.LastName : ''}}, Date Of Cath: {{editingItem != null ? (editingItem.DateOfCath|date) : ''}}</h5>
            <button type="button" tabindex="-1" class="close wj-hide">
                &times;
            </button>
        </div>
        <div class="modal-body">
            <div id="popupContent">
                <div id="left">
                    <div class="form-group">
                        <label for="AEName">Adverse Events  :</label>
                        <a routerLink="/user-portal/edit-case/adverse-events/{{caseId}}">
                            {{editingItem != null ? editingItem.AEName : ''}}
                        </a>
                    </div>
                    <div class="form-group">
                        <label for="severity">Severity  :</label>
                        <wj-combo-box #severity id="severity" [itemsSource]="severities">
                        </wj-combo-box>
                    </div>
                    <div class="form-group">
                        <label for="timeNeeded">Time Needed (min)  :</label>
                        <wj-input-mask #timeNeeded id="timeNeeded" [mask]="'000'" [promptChar]="' '" style="width: 80px;"></wj-input-mask>
                    </div>
                </div>
                <div id="right">
                    <div class="form-group">
                        <label for="dummy"></label>
                    </div>
                    <div class="form-group">
                        <label for="preventability">Preventability  :</label>
                        <wj-combo-box #preventability id="preventability" [itemsSource]="preventabilities">
                        </wj-combo-box>
                    </div>
                    <div class="form-group">
                        <label for="attributability">Attributability  :</label>
                        <wj-combo-box #attributability id="attributability" [itemsSource]="attributabilities">
                        </wj-combo-box>
                    </div>
                </div>
                <div id="bottom">
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary wj-hide-ok">Save</button>
                        <button type="button" class="btn btn-default wj-hide">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </wj-popup>

    <!--Hidden grid used for grid details export to Excel-->
    <wj-flex-grid #hiddenGridMM [itemsSource]="reportDataMM" (initialized)="initHiddenGrid(hiddenGridMM)" [hidden]="true">
        <wj-flex-grid-column [header]="'MRN'" [binding]="'MRN'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'DateOfCath'" [binding]="'DateOfCath'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'LastName'" [binding]="'LastName'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column *ngIf="showOperatorColumn" [header]="'Operator'" [binding]="'Operator'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Fellow'" [binding]="'Fellow'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Age'" [binding]="'Age'"></wj-flex-grid-column>
        <!--<wj-flex-grid-column [header]="'Sex'" [binding]="'Sex'" ></wj-flex-grid-column>-->

        <wj-flex-grid-column [header]="'R3CaseType'" [binding]="'CaseType'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Severity'" [binding]="'Severity'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Preventability'" [binding]="'Preventability'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Attributability'" [binding]="'Attributability'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Time Needed'" [binding]="'TimeNeeded'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Presentation'" [binding]="'Presentation'" [width]="110"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'AEName'" [binding]="'AEName'" [width]="'*'"></wj-flex-grid-column>
        <wj-flex-grid-column [header]="'Description'" [binding]="'Description'" [width]="'*'"></wj-flex-grid-column>

    </wj-flex-grid>

    <div class="row row-margin">
        <div class="btn-group">
            <div>
                <input [(ngModel)]="exportAllmm" type="radio" id="export-this-page" name="export-this-page" [value]="false" (click)="showThisGridDetails(checkbox, gridmm, $event)">
                <label class="btn-sm" for="export-this-page">This Page</label>

                <input [(ngModel)]="exportAllmm" type="radio" id="export-all-data" name="export-all-data" [value]="true" (click)="showAllGridDetails(checkbox, gridmm, $event)">
                <label class="btn-sm" for="export-all-data">Five Pages</label>&nbsp;&nbsp;
            </div>
        </div>
        <div class="btn-group">
            <button #exporttopdfMM class="btn-warning btn-sm" (click)="pdfExport()"><i class="fa fa-spinner"></i>&nbsp;ExportToPDF</button>&nbsp;&nbsp;&nbsp;
            <button #exporttoexcelMM class="btn-warning btn-sm" (click)="exportToExcel(hiddenGridMM)"><i class="fa fa-spinner"></i>&nbsp;ExportAllToExcel</button>&nbsp;&nbsp;&nbsp;
        </div>
        <br>
        <div class="btn-group max-width d-flex align-items-center justify-content-center" style="padding-top:10px">
            <button type="button" class="btn btn-primary"
                    (click)="cvPagingmm.moveToFirstPage();showGridDetails(checkbox, gridmm, $event)"
                    [disabled]="cvPagingmm.pageIndex <= 0">
                First
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="cvPagingmm.moveToPreviousPage();showGridDetails(checkbox, gridmm, $event)"
                    [disabled]="cvPagingmm.pageIndex <= 0">
                Previous
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="cvPagingmm.moveToNextPage();showGridDetails(checkbox, gridmm, $event)"
                    [disabled]="cvPagingmm.pageIndex >= cvPagingmm.pageCount - 1">
                Next
            </button>
            <button type="button" class="btn btn-primary"
                    (click)="cvPagingmm.moveToLastPage();showGridDetails(checkbox, gridmm, $event)"
                    [disabled]="cvPagingmm.pageIndex >= cvPagingmm.pageCount - 1">
                Last
            </button>
        </div>
    </div>
</div>`,
  selector: 'mm-report-edit-template',
  styles: [`body {
  margin-bottom: 24px;
}

#popupContent > div {
    box-sizing:border-box;
    -moz-box-sizing:border-box;
}
#left {
    float:left;
    width: 50%;
}
#right {
    float:right;
    width: 50%;
}
#bottom {
    clear: both;
}

#timeNeeded_input{
    width: 90px;
}
.modal-content {
  width: 1000px;
}`]

})
export class MMReportEditComponent implements OnInit {

  public reportService: ReportService;
  @ViewChild('dp') dp: wjcGridDetail.FlexGridDetailProvider;
  @ViewChild('popup') popup: wjcInput.Popup;
  @ViewChild('preventability') Preventability: wjcInput.ComboBox;
  @ViewChild('attributability') Attributability: wjcInput.ComboBox;
  @ViewChild('severity') Severity: wjcInput.ComboBox;
  @ViewChild('timeNeeded') TimeNeeded: wjcInput.InputMask;


  @Input() cvPagingmm: wjcCore.CollectionView;
  @Input() reportDataMM: ReportDataMM[];
  @Input() exportAllmm: boolean = false;
  @Input() spinner: NgxSpinnerService;
  @Input() showOperatorColumn: boolean = false;

  datePipe: DatePipe;
  public user: LoginService;
  caseId: any;
  severities = ['1', '2', '3', '4', '5'];
  preventabilities = ['','Preventable', 'Possibly Preventable', 'Not Preventable', 'N/A'];
  attributabilities = ['','Sedation or airway related problem', 'Catheterization Related Problem', 'Access Related Problem', 'Dilation Related Problem', 'Stent Related Problem', 'Device Related Problem', 'Coil Related Problem', 'Biopsy Related Problem', 'Ablation Related Problem', 'CRMD Related Problem', 'Valvuloplasty Related Problem'];
  editingItem: any;

  private readonly colWidth = 55;
  private readonly rowHeight = 18;
  reportDataForPDF: ReportDataMM[];
  pdfGrid:wjcGrid.FlexGrid;

  // DataSvc will be passed by derived classes
  constructor(_reportService: ReportService, _user: LoginService, datePipe: DatePipe) {
    this.user = _user;
    this.reportService = _reportService;
    this.datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {

  }

  init(s: wjcGrid.FlexGrid) {
  }

  initializeGrid(gridmm: wjcGrid.FlexGrid) {
    this.pdfGrid = gridmm;

    // add 'edit button' to row header cells
    gridmm.formatItem.addHandler((s: wjcGrid.FlexGrid, e: wjcGrid.FormatItemEventArgs) => {

      if (e.panel == s.cells && e.col == 9) {
        e.cell.innerHTML = '<span class="wj-glyph-pencil" style="color: #cc0000;"></span>';
      }

    });


    // handle button clicks
    gridmm.addEventListener(gridmm.hostElement, 'mousedown', (e: MouseEvent) => {
      let ht = gridmm.hitTest(e);
      if (e.target["innerHTML"] === '⚐' || e.target["innerHTML"] === '⚑') {
        this.editingItem = gridmm.rows[ht.row].dataItem;
        (<wjcCore.CollectionView>gridmm.collectionView).editItem(this.editingItem);
        if (this.editingItem.Flag === '⚐')
          this.editingItem.Flag = '⚑';
        else
          this.editingItem.Flag = '⚐';
        (<wjcCore.CollectionView>gridmm.collectionView).commitEdit();

      }
      
      if (e.target["type"] === 'checkbox') {

        this.editingItem = gridmm.rows[ht.row].dataItem;
        (<wjcCore.CollectionView>gridmm.collectionView).editItem(this.editingItem);
        (<wjcCore.CollectionView>gridmm.collectionView).commitEdit();

        this.reportService.editCaseReportMM = JSON.parse(JSON.stringify(this.editingItem));
        if (this.editingItem.Reviewed === true)
          this.reportService.editCaseReportMM.Reviewed = false;
        else
          this.reportService.editCaseReportMM.Reviewed = true;
        this.reportService.editCaseReportMM.Updatedby = this.user.user.UserId;
        this.reportService.SaveMMReport().subscribe(data => this.saveResponse(data));
      }

      if (e.target["className"] === 'wj-glyph-pencil') {
        // prepare form
        this.editingItem = gridmm.rows[ht.row].dataItem;
        this.caseId = this.editingItem.CaseID;
        this.Preventability.selectedItem = this.editingItem.Preventability;
        this.Attributability.selectedItem = this.editingItem.Attributability;
        this.Severity.selectedItem = this.editingItem.Severity;
        this.TimeNeeded.rawValue = this.editingItem.TimeNeeded;
        // show the form
        this.popup.show(true, (s: wjcInput.Popup) => {
          if (s.dialogResult == 'wj-hide-ok') {
            // commit changes if the user pressed the Save button
            (<wjcCore.CollectionView>gridmm.collectionView).editItem(this.editingItem);
            this.editingItem.Preventability = this.Preventability.selectedItem;
            this.editingItem.Attributability = this.Attributability.selectedItem;
            this.editingItem.Severity = this.Severity.selectedValue;
            this.editingItem.TimeNeeded = this.TimeNeeded.rawValue;
            (<wjcCore.CollectionView>gridmm.collectionView).commitEdit();
            this.reportService.editCaseReportMM = this.editingItem;
            this.reportService.editCaseReportMM.Updatedby = this.user.user.UserId;
            this.reportService.SaveMMReport().subscribe(data => this.saveResponse(data));
          }
          // return focus to the grid
          gridmm.focus();
        });
      }
    });
  }


  private saveResponse(data: CaseSaveResponse) {
    let caseSaveStatusModel = data.DataObject;
    if (caseSaveStatusModel.Casestatus === "Success") {
      let newCaseId = caseSaveStatusModel.CaseId;

    } else {
      alert("Save failed!");
    }
  }

  //Show into Grid

  showGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();
    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    gridmm.endUpdate()
  }

  showThisGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();

    this.cvPagingmm.pageSize = 10;

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }
    gridmm.endUpdate()
  }

  showAllGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();

    this.cvPagingmm.pageSize = 50;

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }
    gridmm.endUpdate()
  }


  //PDF export
  pdfExport() {
    var PDFExportFileName = 'M&MDetails_ ' + new Date().toLocaleString() + '.pdf';
    let doc = new pdf.PdfDocument({
      header: {
        declarative: {
          text: '\tM&M Report\t&[Page]',
          font: new pdf.PdfFont('helvetica', 10, 'normal', 'bold')
        }
      },
      footer: {
        declarative: {
          text: '\t&[Page]\\&[Pages]',
          brush: '#3173c0',
          font: new pdf.PdfFont('helvetica', 10, 'normal', 'bold')
        }
      },
      pageSettings: {
        layout: pdf.PdfPageOrientation.Portrait,
        size: pdf.PdfPageSize.Letter,
        margins: {
          left: 53,
          top: 72,
          right: 72,
          bottom: 10
        }
      },
      ended: (sender: pdf.PdfDocument, args: pdf.PdfDocumentEndedEventArgs) => pdf.saveBlob(args.blob, PDFExportFileName)

    });

    //Take a grid copy of ReportDataMM Array
    this.reportDataForPDF = JSON.parse(JSON.stringify(this.cvPagingmm._view));

    //Group By CaseID
    var groups = this.reportDataForPDF.reduce(function (obj, item) {
      obj[' '+item.CaseID] = obj[' '+item.CaseID] || [];
      obj[' '+item.CaseID].push(item);
      return obj;
    }, {});

    //Orde By Severity DESC
    for (var key in groups)
      groups[key].sort(function (a, b) { var x = a.Severity, y = b.Severity; return x === y ? 0 : x > y ? -1 : 1; });

    //Print in Pdf
    for (var key in groups) {
      var processCase;
      var secondaryAeDetailsList = [];
      groups[key].forEach((aCase, i) => {

        if (i == 0)
          processCase = aCase;

        secondaryAeDetailsList.push(new SecondaryAeDetails(aCase.AEName, aCase.Severity, aCase.Preventability));

        if (groups[key].length == i + 1)
          this.drawPDF(doc, processCase, secondaryAeDetailsList);

      });
    };
  
    doc.end();
  }

  private drawPDF(doc: pdf.PdfDocument, aCase: ReportDataMM, secondaryAeDetailsList: any[]) {
    aCase.DateOfCath = this.datePipe.transform(aCase.DateOfCath, 'MM/dd/yyyy');
    let y = doc.y, columns: TColumn[] = [
      { header: 'Date Of Cath', binding: 'DateOfCath', format: 'd' },
      { header: 'MRN', binding: 'MRN', format: 'c' },
      { header: 'Last Name', binding: 'LastName', format: 'c' },
      { header: 'Operator', binding: 'Operator', format: 'c' },
      { header: 'Fellow', binding: 'Fellow', format: 'c' },
      { header: 'Adverse Event', binding: 'AEName', format: 'c' },
      { header: 'Severity', binding: 'Severity', format: 'c' },
      { header: 'Reviewed', binding: 'Reviewed', format: 'c' },
      ],
      bold = new pdf.PdfFont('times', 10, 'normal', 'bold'),
      c1 = new wjcCore.Color('#ff002b');

    doc.registerFont({
      source: '../../../../assets/fonts/FiraSans-Regular.ttf',
      name: 'fira',
      style: 'normal',
      weight: 'normal',
      sansSerif: true
    });
    doc.registerFont({
      source: '../../../../assets/fonts/FiraSans-Bold.ttf',
      name: 'fira',
      style: 'normal',
      weight: 'bold',
      sansSerif: true
    });

    let firaFontNormal = new pdf.PdfFont("fira", 9, "normal", "normal");
    let firaFontBold = new pdf.PdfFont("fira", 9, "normal", "bold");
    // header
    if (y == 0) {
      this.renderRow(doc, 0, columns, (column: TColumn) => column.header, null, bold, '#fad9cd');
    }

    if (y >= 500) {
      doc.y = 0;
      doc.addPage();
      this.renderRow(doc, 0, columns, (column: TColumn) => column.header, null, bold, '#fad9cd');
    }

    //body
    y = doc.y;
    this.renderRow(doc, y, columns, (column: TColumn) => aCase[column.binding], (column: TColumn) => column.format);

    doc.moveDown(1);
    y = doc.y;
    if (aCase.Flag == '⚑') {
      doc.drawText('(!)', 0, y, { font: firaFontBold, continued: true, brush: new pdf.PdfSolidBrush(c1) });
      doc.drawText(' ');
    }

    doc.drawText('Case Type: ', 20, y, { font: firaFontBold, continued: true});
    if (aCase.CaseType == '') aCase.CaseType = ' ';
    doc.drawText(aCase.CaseType.substring(0,55), null, null, { font: firaFontNormal });

    doc.drawText('Age: ', 310, y, { font: firaFontBold, continued: true });
    doc.drawText(aCase.Age, null, null, { font: firaFontNormal });

    y = doc.y;
    doc.drawText('Time Needed(min): ', 20, y, { font: firaFontBold, continued: true });
    if (aCase.TimeNeeded == '') aCase.TimeNeeded = ' ';
    doc.drawText(aCase.TimeNeeded , null, null, { font: firaFontNormal });

    doc.drawText('Preventability: ', 140, y, { font: firaFontBold, continued: true });
    if (aCase.Preventability == '') aCase.Preventability = ' ';
    doc.drawText(aCase.Preventability, null, null, { font: firaFontNormal });

    doc.drawText('Attributability: ', 310, y, { font: firaFontBold, continued: true });
    if (aCase.Attributability == '') aCase.Attributability = ' ';
    doc.drawText(aCase.Attributability, null, null, { font: firaFontNormal });


    secondaryAeDetailsList.forEach((aSecondaryAeDetails, i) => {
      if (i == 0) doc.moveDown(1);
      y = doc.y;
      var aeNumber = +i + 1;
      doc.drawText('AE ' + aeNumber + ': ', 20, y, { font: firaFontBold, continued: true });
      if (aSecondaryAeDetails.Name == '') aSecondaryAeDetails.Name = ' ';
      doc.drawText(aSecondaryAeDetails.Name.substring(0,42), null, null, { font: firaFontNormal });


      doc.drawText('Severity: ', 240, y, { font: firaFontBold, continued: true });
      if (aSecondaryAeDetails.Severity == '') aSecondaryAeDetails.Severity = ' ';
      doc.drawText(aSecondaryAeDetails.Severity, null, null, { font: firaFontNormal });


      doc.drawText('Preventability: ', 310, y, { font: firaFontBold, continued: true });
      if (aSecondaryAeDetails.Preventability == '') aSecondaryAeDetails.Preventability = ' ';
      doc.drawText(aSecondaryAeDetails.Preventability, null, null, { font: firaFontNormal });

    });

    doc.moveDown(1);
    y = doc.y;
    doc.drawText('Presentation: ', 20, y, { font: firaFontBold, continued: true });
    if (aCase.Presentation == '') aCase.Presentation = ' ';
    doc.drawText(aCase.Presentation, null, null, { font: firaFontNormal });

    doc.moveDown(1);
    y = doc.y;
    doc.drawText('Description: ', 20, y, { font: firaFontBold, continued: true });
    doc.drawText(aCase.Description, null, null, { font: firaFontNormal});

  }

  private renderRow(doc: pdf.PdfDocument, y: number, values: any[], valueGetter: Function, formatGetter: Function, font?: pdf.PdfFont, brush?: pdf.PdfBrush | string) {
      let x = 0,
          columnWidth = 0;
    values.forEach((v: any, idx: number) => {

      let value = valueGetter != null ? valueGetter(v) : v || '';
      let format = formatGetter != null ? formatGetter(v) : '';

      //
      if (v["binding"] === 'Reviewed' && value !== 'Reviewed') {

        if (value == true)
          value = 'Yes';
        else
          value = ' ';

      }

      //
      if (v["binding"] === 'DateOfCath') {
        x = idx * this.colWidth;
        columnWidth = this.colWidth + 20;
      }
      else if (v["binding"] === 'MRN') {
        x = idx * this.colWidth + 20;
        columnWidth = this.colWidth;
      }
      else if (v["binding"] === 'LastName') {
        x = idx * this.colWidth + 20;
        columnWidth = this.colWidth;
      }
      else if (v["binding"] === 'Operator') {
        x = idx * this.colWidth + 20;
        columnWidth = this.colWidth;
      }
      else if (v["binding"] === 'Fellow') {
        x = idx * this.colWidth + 20;
        columnWidth = this.colWidth;
      }
      else if (v["binding"] === 'AEName') {
        x = idx * this.colWidth + 20;
        columnWidth = this.colWidth + 50;
      }
      else if (v["binding"] === 'Severity') {
        x = idx * this.colWidth + 20 + 50;
        columnWidth = this.colWidth;
      }
      else  {
        x = idx * this.colWidth + 20 + 50;
        columnWidth = this.colWidth;
      }

      doc.paths.rect(x, y, columnWidth, this.rowHeight).fill(brush || '#f4b19b');
      doc.drawText(value, x + 3, y + 5, { font: font, height: this.rowHeight, width: columnWidth });

    });
  }

  //Excel Export
  initHiddenGrid(hiddenGrid: wjcGrid.FlexGrid) {
  }
  exportToExcel(hiddenGrid: wjcGrid.FlexGrid) {
    this.spinner.show();
    gridXls.FlexGridXlsxConverter.saveAsync(hiddenGrid, {
      includeColumnHeaders: true, sheetName: 'M&MInfo',
    }, 'M&MSummary_' + new Date().toLocaleString() + '.xlsx');
    this.spinner.hide();
  }
}


export class SecondaryAeDetails {
  Name: string;
  Severity: string;
  Preventability: string
  constructor(name: string, severity: string, preventability: string) {
    this.Name = name;
    this.Severity = severity;
    this.Preventability = preventability;
  }
}


