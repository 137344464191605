/// <reference path="components/user/login.service.ts" />
import {
  Component,
  ViewEncapsulation,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener
} from "@angular/core";
import { fadeAnimation } from "./animations/fade.animation";
import { TitleService } from "./title.service";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { JsonpCallbackContext } from "@angular/common/http/src/jsonp";
import { LoginService } from "./components/user/login.service";

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fadeAnimation],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  public user: LoginService;


  constructor(private titleService: TitleService, private router: Router, _user: LoginService) {
   
    this.user = _user;
    
      if (localStorage.getItem('tabs') == null)
        localStorage.setItem('tabs', "0");
      else
        localStorage.setItem('tabs', JSON.stringify(parseInt(localStorage.getItem('tabs')) + 1));
    

    window.onunload = function (e) {
       
      if (localStorage.getItem('tabs') != null && (parseInt(localStorage.getItem('tabs'))) == 0) {       
        this.localStorage.removeItem('currentUser');       
        localStorage.removeItem('tabs');                   
        }
        else {
          this.localStorage.setItem('tabs', JSON.stringify(parseInt(localStorage.getItem('tabs')) - 1));
        }      
      return "close browser";
    };
   
    router.events.subscribe(val => {     
      if (val instanceof NavigationEnd) {        
        this.appendFooter();
      }
    });
   
  }

  ngOnInit() {
    this.titleService.init();
  }
  

  appendFooter() { 
    
    $(".footer").remove();
    $(".my-contain").append(
      "<footer class='footer my-footer text-muted text-center mt-4'> &copy; 2007-2019 Boston Children's Hospital</footer>"
    );

  }
 
}
