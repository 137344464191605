import { Component, OnInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { SurCardiacDiagnosisService } from "../../../shared-data-entry/surcardiac-diagnosis.service";
import { SurCardiacDiagnosisModelResponse } from '../../../../shared/iqis/surcardiac-diagnosis-response';
import { SurCardiacDiagnosisModel } from '../../../../shared/iqis/surcardiac-diagnosis-model';
import { SurPicklistItemService } from '../../../shared-data-entry/surpicklist-item.service';
import { SurPicklistItemResponse } from '../../../../shared/iqis/surpicklist-item-response';
import { SurPicklistItemModel } from '../../../../shared/iqis/surpicklist-item-model';
import { SurDropdownItemModel } from '../../../../shared/iqis/surdropdown-item-model';
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../cansuregerycasedeactivategaurd';
import { LoginService } from "../../../user/login.service";
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";

declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.scss'],
  host: { 'class': "host" }
})
export class MedicalHistoryComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  name = "Medical History";
  user: LoginService;
  
  genericsyndromes = [];
  genericsyndrome = [];
  selectedgenericsyndrome = [];

  structuralAnomalies = [];
  structuralAnomaly = [];
  selectedstructuralAnomaly = [];

  medicalIllnesses = [];
  medicalIllness = [];
  selectedmedicalIllness = [];

  PreopProcOptions = [];
  preopProc = [];
  text = "Cardiac Diagnosis";
  selectedPreopProc = [];
    
  events = [];
  toAdd: any = {};
  // recordedEvents = {};
  // recordedArray = [];
  showEvents: boolean = false;
  showTooltipText: boolean = false;
  options = {
    animateExpand: true,
    animateAcceleration: 1.2,
    scrollOnActivate: true,
    animateSpeed: 10
  };
  dropdownData: SurDropdownItemModel;
  picklistItemService: SurPicklistItemService;
  public surCardiacDiagnosisService: SurCardiacDiagnosisService;
  public fields: SurgeryCaseFieldsService;
  constructor(private router: Router, _picklistItemService: SurPicklistItemService, user: LoginService, _surcardiacdiagnosisService: SurCardiacDiagnosisService, fields: SurgeryCaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    
    this.surCardiacDiagnosisService = _surcardiacdiagnosisService;
    this.user = user;
    this.fields = fields;
    this.surCardiacDiagnosisService.getCardiacDiagnosisList().subscribe(data => this.populateCardiacDiagnisList(data));
    if (this.user.user) {
      this.picklistItemService = _picklistItemService;
      this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "ALL").subscribe(data => this.populatePicklistItems(data));
    }
  }

  ngOnInit() {
    //localStorage.removeItem('medicalhistorydirty');
    let hasEvents = this.fields.getField(this.name, "Recorded Events") != null;
    localStorage.setItem('cardiacdiagnosis', JSON.stringify(this.fields.getField(this.name, "Recorded Events").value))

    this.fields.getParent(this.name)["hide"] = !hasEvents;
    this.showEvents = hasEvents;
    this.setDefaultValues();
    if (this.fields.getField(this.name, "Recorded Events").value === null)
      this.toggleStatus('RheumaticHeartDiseaseInd');
    
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    //if (this.form.dirty)
    //  localStorage.setItem('medicalhistorydirty', 'true');
    //if (this.fields.getField(this.name, "Recorded Events").value !== null && localStorage.getItem('cardiacdiagnosis') !== JSON.stringify(this.fields.getField(this.name, 'Recorded Events').value))
    //  localStorage.setItem('medicalhistorydirty', 'true');

    return !this.form.dirty;
  }

  private setDefaultValues() {
    if (this.fields.getField(this.name, "GenericsyndromeInd").value === null)
      this.fields.setValue(this.name, "GenericsyndromeInd", 'No');

    if (this.fields.getField(this.name, "BalloonAtrioseptostomyBeforeSurgeryInd").value === null)
      this.fields.setValue(this.name, "BalloonAtrioseptostomyBeforeSurgeryInd", 'No');

    if (this.fields.getField(this.name, "ResuscitationBeforeSurgeryInd").value === null)
      this.fields.setValue(this.name, "ResuscitationBeforeSurgeryInd", 'No');

    if (this.fields.getField(this.name, "InotropeTherapyBeforeSurgeryInd").value === null)
      this.fields.setValue(this.name, "InotropeTherapyBeforeSurgeryInd", 'No');
    if (this.fields.getField(this.name, "VentilationPreoperativelyInd").value === null)
      this.fields.setValue(this.name, "VentilationPreoperativelyInd", 'No');

  }
  private populatePicklistItems(picklistItemResponse: SurPicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.genericsyndromes = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "GENETICSYNDROME");
    this.genericsyndrome = this.genericsyndromes.map(x => x.name);
    this.loadProblems(this.genericsyndromes, this.genericsyndrome, this.selectedgenericsyndrome, 'GenericsyndromeVal');

    this.structuralAnomalies = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "MNCSAnomalies");
    this.structuralAnomaly = this.structuralAnomalies.map(x => x.name);
    this.loadProblems(this.structuralAnomalies, this.structuralAnomaly, this.selectedstructuralAnomaly, 'StructuralAnomalyVal');

    this.medicalIllnesses = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "MNCMIllness");
    this.medicalIllness = this.medicalIllnesses.map(x => x.name);
    this.loadProblems(this.medicalIllnesses, this.medicalIllness, this.selectedmedicalIllness, 'MedicalIllnessVal');

    
  }

     
  populateCardiacDiagnisList(cardiacDiagnosisResponse: SurCardiacDiagnosisModelResponse) {
    this.surCardiacDiagnosisService.cardiadiagList =
      cardiacDiagnosisResponse.DataObject;
    this.surCardiacDiagnosisService.mapToDropDown();

    this.events = this.surCardiacDiagnosisService.dropDowncardiadiagList;
  }


  private loadProblems(dropdownOptions, dropdown, selectionOption, type) {
    let valMap: Array<string> = [];

    let probs = this.fields.getField(this.name, type).value;
    if (probs != null) {
      let probArray = probs.split('|');

      for (let p of probArray) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.code === p) {
            valMap.push(dropDownItem.name);
          }
        }
      }
    }

    selectionOption = valMap;
   
    if (type == "GenericsyndromeVal") {
      this.selectedgenericsyndrome = valMap;
    }
    if (type == "StructuralAnomalyVal") {
      this.selectedstructuralAnomaly = valMap;
    }
    if (type == "MedicalIllnessVal") {
      this.selectedmedicalIllness = valMap;
    }
    
  }
  public updateProblems(dropdownOptions, dropdown, selectionOption, type) {
    if (selectionOption.length > 0 && Array.isArray(selectionOption) == true) {
      let valMap: Array<string> = [];

      for (let p of selectionOption) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.name === p) {
            valMap.push(dropDownItem.code)
          }
        }
      }

      this.fields.setValue(this.name, type, valMap.join("|"));
    } else {
      this.fields.setValue(this.name, type, null);
    }

    if (Array.isArray(selectionOption) == false) { this.fields.setValue(this.name, type, dropdownOptions.find(x => x.name == selectionOption)["code"]); }
  }
    
      
   toggleShowEvents(val) {
    this.showEvents = val;
    this.fields.getParent(this.name)['hide'] = !val;
  }
  showTooltip(b: boolean) {
    this.showTooltipText = b;
  }
  addItem(name, item) {
    this.fields.getField(this.name, "Recorded Events").value[name + ""] = item;

    let unordered = this.fields.getField(this.name, "Recorded Events").value;
    let ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    this.fields.getField(this.name, "Recorded Events").value = ordered;
    this.toggleStatus('RheumaticHeartDiseaseInd');
  }
  //deletes a selected procedure
  deleteProcedure(event, name) {
    let savedEvents = this.getEvents();
    delete savedEvents[name];
    let arr = Object.getOwnPropertyNames(savedEvents);

    if (arr.length == 0) {
      savedEvents = null;
      this.fields.getField(this.name, "Recorded Events").value = null;
    }
    this.toggleStatus('RheumaticHeartDiseaseInd');
  }
  getEvents() {
    return this.fields.getField(this.name, "Recorded Events").value;
  }

  //sets the recorded events
  setEvents(val) {
    this.fields.getField(this.name, "Recorded Events").value = val;
  }
  //places a selected event in the editable fields
  editProcedure($event, name) {
    this.toAdd = this.getEvents()[name];
  }
 //produces the selected key string for a particular procedure
  static getKeyString(node, tree) {
    //all procedure names are parsed as
    let text = "";
    if (node && node.path) {
      let path = node.path;
      for (let id of path) {
        let parent = tree.treeModel.getNodeById(id);
        if (text !== "") {
          text = text + " | " + parent.data.name;
        } else {
          text = text + parent.data.name;
        }
      }
    }
    return text;
  }

  createRecord(node, tree) {
    let procs = this.getEvents();
    let nodeString = MedicalHistoryComponent.getKeyString(node, tree);
    procs[nodeString] = {
     id: "procedure-" + node.data.code
     };     
    this.sortProcedures();
    this.toggleStatus('RheumaticHeartDiseaseInd');
    return true;
  }
  //when selecting a leaf, creates a new procedure entry
  //otherwise, opens parent
  onSelect(event, tree) {
    let procs = this.getEvents();
    if (event.node.isLeaf ) {
     
        //creates normal entry
      if (!procs) {
        this.setEvents({});
        }
        this.createRecord(event.node, tree);
      
    } else {
      //opens parent node
      event.node.toggleExpanded();
    }
  }

  sortProcedures() {
    let unordered = this.fields.getField(this.name, "Recorded Events").value;
    if (unordered != null) {
      let ordered = {};
      Object.keys(unordered).sort().forEach(function (key) {
        ordered[key] = unordered[key];
      });
      this.fields.getField(this.name, "Recorded Events").value = ordered;
    }
  }

  //gets the recorded procedures as an array
  getProceduresArray() {
    let procs =this.getEvents();
    if (procs) {
      return Object.getOwnPropertyNames(this.getEvents());
    } else {
      return [];
    }
  }

  toggleCheckbox(val) {
    //if (val === 'GenericsyndromeInd') {
    //  this.fields.setValue(this.name, "GenericsyndromeVal", null);
      
    //}
    if (val === 'StructuralAnomalyInd' && this.fields.getValue(this.name, val) === 'No') {
      this.fields.setValue(this.name, "StructuralAnomalyVal", null);
      this.fields.getField(this.name, "StructuralAnomalyVal").hide = true;
    }
    if (val === 'StructuralAnomalyInd' && this.fields.getValue(this.name, val) === 'Yes') {
      
      this.loadProblems(this.structuralAnomalies, this.structuralAnomaly, null, 'StructuralAnomalyVal');
      this.setSelectionFromFields();
    }

    if (val === 'MedicalIllnessInd') {
      this.fields.setValue(this.name, "MedicalIllnessVal", null);
      this.loadProblems(this.medicalIllnesses, this.medicalIllness, null, 'MedicalIllnessVal');
      this.setSelectionFromFields();

    }
  }

  getDropdown(val) {
    return this.fields.getField(this.name, val).hide;
  }
  private setSelectionFromFields() {
    try {


      for (let t of this.genericsyndromes) {


        this.setSelection(t)

      }
      

    } catch (ex) {
      console.log(ex);
    }
  }

  private setSelection(type) {
    let period: Array<number> = [0, 1];
    for (let p of period) {
      let list = null;
      list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
      let val = "";

      val = this.fields.getValue(this.name, type.name );


      if (type.multiple) {
        let valMap: Array<string> = [];
        let valList: Array<string> = [];
        if (val != null) {
          valList = val.split("|");
        }

        for (let v of valList) {
          for (let dropDownItem of list) {
            if (dropDownItem.code === v) {
              valMap.push(dropDownItem.name);
            }
          }
        }

        if (valMap.length === 0) {
          valMap = ["None"];
        }
        type.selections[p] = valMap;

      } else {
        let valMap = null;
        for (let dropDownItem of list) {
          if (dropDownItem.code === val) {
            valMap = dropDownItem.name;
          }
        }

        if (valMap == null) {
          valMap = "None";
        }

        type.selections[p] = valMap;

      }
    }
  }

  toggleStatus(val) {
    let isRestricted : Boolean = true
    this.fields.getField(this.name, val).value = false;
    var procCodes = this.getprocedures(this.fields.getField(this.name, "Recorded Events").value).split('|');
    let conditionalProcCodes = this.fields.getField(this.name, val).proccodes;
    for (let code of procCodes) {
      
      if (conditionalProcCodes.indexOf(code) > -1) {
        isRestricted = false;
      }
    }
   
    if (isRestricted && procCodes[0] !== "") {
      this.fields.getField(this.name, val).value = 'No';
    }
    else {
      this.fields.getField(this.name, val).value = null;
    }
  }

  private getprocedures(procList: any): string {
    var proc_ids = ""
    var procsArr = [];
    if (procList) {
      procsArr = Object.getOwnPropertyNames(procList);
    }
    for (let item of procsArr) {
      let proc = procList[item];
      //let proc = item;
      if (proc_ids.length == 0)
        proc_ids = proc['id'].replace("procedure-", "");
      else
        proc_ids = proc_ids + "|" + proc['id'].replace("procedure-", "");

    }
    return proc_ids;
  }
 }
