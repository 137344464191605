import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SearchSurCaseModel } from '../../../shared/iqis/search-surcase-model';
import { SearchSurcaseResponse } from '../../../shared/iqis/search-surcase-response';
import { SearchSurCaseSummaryModel } from '../../../shared/iqis/search-surcase-summary-model';
import { SURGERYCONFIG } from '../../../shared/surgeryconfig';

let iqisapiUrl = SURGERYCONFIG.baseUrls.iqisapiurl;
@Injectable({
  providedIn: "root"
})
export class SurCaseLookupService {

  SurCaseId: number;
  LocationId: number;
  UniqueIdentifier: string ='';
  ICMPLThirtyDayStatusInd: boolean = false;
  CMPLThirtyDayStatusInd: boolean = false;
  CompleteDataOnly: boolean = false;
  IncompleteDataOnly: boolean = false;
  ShowInactiveCases: boolean = false;;
  SurgDateFrom: Date = null;
  SurgDateTo: Date = null;
  AgeRange: string = '';
 
  ProcedureCodes: string = '';

  public searchCaseSummarys: SearchSurCaseSummaryModel[];

  private http: Http = null;
  constructor(_http:Http) {
    this.http = _http;
  }

  searchClear() {
    this.SurCaseId = null;
    this.LocationId = null;
    this.SurgDateFrom = null;
    this.SurgDateTo = null;
    this.AgeRange = '';
    this.UniqueIdentifier = '';
    this.IncompleteDataOnly = false;
    this.CompleteDataOnly = false;
    this.ICMPLThirtyDayStatusInd = false;
    this.CMPLThirtyDayStatusInd = false;
    this.ShowInactiveCases = false;
    this.ProcedureCodes = '';
    this.searchCaseSummarys = [];
   
  }

  searchCases() {
    let searchInfo = new SearchSurCaseModel;

    searchInfo.SurCaseId = this.SurCaseId;    
    searchInfo.LocationId = this.LocationId;
    searchInfo.UniqueIdentifier = this.UniqueIdentifier;
    searchInfo.ShowInactiveCases = this.ShowInactiveCases;
    searchInfo.ThirtyDayStatusInd = null;
      //has To date but no From date
    if (this.SurgDateFrom == null && this.SurgDateTo) {
      let dFrom = new Date(this.SurgDateTo);
        dFrom.setDate(dFrom.getDate() - 30);
      this.SurgDateFrom = dFrom;
      }

      //has From date but no To date
    if (this.SurgDateFrom && this.SurgDateTo == null) {
      this.SurgDateTo = new Date;
      }

      try {
        searchInfo.SurgDateFrom = this.formatDate(new Date(this.SurgDateFrom));
      } catch {
        searchInfo.SurgDateFrom = '';
      }

      try {
        searchInfo.SurgDateTo = this.formatDate(new Date(this.SurgDateTo));
      } catch {
        searchInfo.SurgDateTo = '';
      }
    if (this.CompleteDataOnly)
       searchInfo.SurgStatusCode = 'CMPL';
    if (this.IncompleteDataOnly)
      searchInfo.SurgStatusCode = 'INCMPL'; 
    
    if (this.CompleteDataOnly && this.IncompleteDataOnly) {
      searchInfo.SurgStatusCode = '';
    }
    if (this.CMPLThirtyDayStatusInd)
      searchInfo.ThirtyDayStatusInd ='1';
    if (this.ICMPLThirtyDayStatusInd)
      searchInfo.ThirtyDayStatusInd = '0';

    if (this.CMPLThirtyDayStatusInd && this.ICMPLThirtyDayStatusInd) {
      searchInfo.ThirtyDayStatusInd = null;
    } 
    searchInfo.AgeRange = this.AgeRange;       
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(iqisapiUrl + "surcase/GetSurCaseSearchResults", searchInfo, options)
      .pipe(map((response: Response) => <SearchSurcaseResponse>response.json()));
  }



  private formatDate(d: Date): string {
    if (d) {
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (isNaN(year)) {
        return '';
      }

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let response = [month, day, year].join('-');

      if (year < 2000) {
        response = '';
      }

      return response;
    }

    return '';
  }
}
