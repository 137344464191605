import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { ComponentCanDeactivate } from "../../../../CanDeactivateGaurd";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-procedural-efficacy",
  templateUrl: "./procedural-efficacy.component.html",
  styleUrls: ["./procedural-efficacy.component.scss"],
  host: { class: "host" },
})
export class ProceduralEfficacySurgeryComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  public fields: SurgeryCaseFieldsService;
  name = "Procedural Efficacy";
  public result = [];
  public resultString = ""
  //show sections
  aSDRepair: boolean = false;
  vASSurgery: boolean = false;
  cRLThoracotomy: boolean = false;
  pDALigation: boolean = false;

  //tps scores
  aSDTPSScore: number = null;
  vASRTPSScore: number = null;
  cRLTTPSScore: number = null;
  pDATPSScore: number = null;

  //ASD
  aSDRepairNotAvailable: boolean = false;
  conductionNotAvailable: boolean = false;

  //VASS
  aVSNotAvailable: boolean = false;
  aVRNotAvailable: boolean = false;
  cFNotAvailable: boolean = false;
  sAANotAvailable: boolean = false;
  vASSCondNotAvailable: boolean = false;

  //CRLT
  aArchNotAvailable: boolean = false;
  dAortaNotAvailable: boolean = false;
  bPArteryNotAvailable: boolean = false;

  //PDA
  lDivisionNotAvailable: boolean = false;
  bPArteryPDANotAvailable: boolean = false;
  isthmusNotAvailable: boolean = false;

  //show tables
  showASDTable: boolean = false;
  showVASSTable: boolean = false;
  showCRLTTable: boolean = false;
  showPDATable: boolean = false;

  constructor(private router: Router,_fields: SurgeryCaseFieldsService) {
    this.fields = _fields;
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
  }

  ngOnInit() {
    localStorage.removeItem('proceduralefficacydirty');
    this.result = [];
    this.toggleStatus("ASDRepairStatus");
    this.toggleStatus("VASSurgeryStatus");
    this.toggleStatus("CRLThoracotomyStatus");
    this.toggleStatus("PDALigationStatus");

    this.ProceduralEfficacyCodesMessage("ASDRepairStatus", this.fields.getField(this.name, "ASDRepairStatus").value);
    this.ProceduralEfficacyCodesMessage("VASSurgeryStatus", this.fields.getField(this.name, "VASSurgeryStatus").value);
    this.ProceduralEfficacyCodesMessage("CRLThoracotomyStatus", this.fields.getField(this.name, "CRLThoracotomyStatus").value);
    this.ProceduralEfficacyCodesMessage("PDALigationStatus", this.fields.getField(this.name, "PDALigationStatus").value);

    this.toggleASDNotAvailable(this.fields.getField(this.name, "ASDRepair").value == "0" ? true : false);
    this.toggleConductionNotAvailable(this.fields.getField(this.name, "ASDConduction").value === "0" ? true : false);

   
    this.toggleAVSNotAvailable(this.fields.getField(this.name, "VASAroticValueStenosis").value === "0" ? true : false);
    this.toggleAVRNotAvailable(this.fields.getField(this.name, "VASAroticValueRegurgitation").value === "0" ? true : false);
    this.toggleCFNotAvailable(this.fields.getField(this.name, "VASCoronaryFlow").value == "0" ? true : false);
    this.toggleSAANotAvailable(this.fields.getField(this.name, "VASSupraAorticAnastomosis").value === "0" ? true : false);
    this.toggleVASSCondNotAvailable(this.fields.getField(this.name, "VASCondution").value === "0" ? true : false);

    this.toggleAArchNotAvailable(this.fields.getField(this.name, "CRLAorticArch").value === "0" ? true : false);
    this.toggleDAortaNotAvailable(this.fields.getField(this.name, "CRLDescendingAorta").value === "0" ? true : false);
    this.toggleBPArteryNotAvailable(this.fields.getField(this.name, "CRLBranchPulmonaryArtery").value === "0" ? true : false);

    this.toggleLDivisionNotAvailable(this.fields.getField(this.name, "PDALigationDivision").value === "0" ? true : false);
    this.toggleBPArteryPDANotAvailable(this.fields.getField(this.name, "PDABranchPulmonaryArtery").value === "0" ? true : false);
    this.toggleIsthmusNotAvailable(this.fields.getField(this.name, "PDAIsthmusDescendingAorta").value === "0" ? true : false);

    

  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('proceduralefficacydirty', 'true');
    if (this.fields.getField(this.name, "ASDRepairStatus").value !== null) {
      localStorage.setItem('proceduralefficacydirty', 'true');
    }
      return !this.form.dirty;

  }
  // toggle ASD not available
  toggleASDNotAvailable(b) {
    this.aSDRepairNotAvailable = b;
  }

  toggleConductionNotAvailable(b) {
    this.conductionNotAvailable = b;
  }

  //toggle VASS not available
  toggleAVSNotAvailable(b) {
    this.aVSNotAvailable = b;
  }

  toggleAVRNotAvailable(b) {
    this.aVRNotAvailable = b;
  }

  toggleCFNotAvailable(b) {
    this.cFNotAvailable = b;
  }

  toggleSAANotAvailable(b) {
    this.sAANotAvailable = b;
  }

  toggleVASSCondNotAvailable(b) {
    this.vASSCondNotAvailable = b;
  }

  //toggle CRLT not available
  toggleAArchNotAvailable(b) {
    this.aArchNotAvailable = b;
  }

  toggleDAortaNotAvailable(b) {
    this.dAortaNotAvailable = b;
  }

  toggleBPArteryNotAvailable(b) {
    this.bPArteryNotAvailable = b;
  }

  //toggle PDA not available
  toggleLDivisionNotAvailable(b) {
    this.lDivisionNotAvailable = b;
  }

  toggleBPArteryPDANotAvailable(b) {
    this.bPArteryPDANotAvailable = b;
  }

  toggleIsthmusNotAvailable(b) {
    this.isthmusNotAvailable = b;
  }

  toggleStatus(val) {
    if (this.fields.getField(this.name, val).value !== true ) {
      this.fields.getField(this.name, val).value = false;
      let stsCodes = this.fields.getField(this.name, val).stscodes;
      var procCodes = this.getprocedures(this.fields.getField("Surgical Procedures", "Recorded Events").value).split("|");
      if (procCodes.length > 0) {
        stsCodes.forEach(code => {
          if (procCodes.indexOf(code) >= 0) {
            this.fields.getField(this.name, val).value = true;
            return;
          }
        });
      }
    }
  }

  public ProceduralEfficacyCodesMessage(fieldName, val) {
    this.result = [];
    if (val === true) {
      this.checkSTSCodesForProceduralEfficacy(fieldName);
     
    } else {
      this.fields.getField(this.name, fieldName).visibleSTSCodesMessage = false;
    }
    for (let category of this.fields.fields) {
      if (category["name"] == this.name) {
        for (let field of category.fields) {
          if (field["visibleSTSCodesMessage"] === true) 
            this.result.push(field["STSCodesWarningMessage"]);
          }
        }
    }
    if (this.result.length > 0 && this.result.indexOf("Range Warning:") == -1) {
      this.result.unshift("Range Warning:");
    }
    this.resultString =  this.result.join("<br>");
  }

 private checkSTSCodesForProceduralEfficacy(field) {
   try {  
        let f = this.fields.getField(this.name, field);
        let stscodes = f["stscodes"];
          let procCodes = this.getprocedures(this.fields.getField("Surgical Procedures", "Recorded Events").value);
          let arrayPC = procCodes.split("|");
          if (arrayPC.length > 0) {
           for(let code of stscodes){
              if (procCodes.indexOf(code) == -1) {
                this.fields.getField(this.name, field).visibleSTSCodesMessage = true;
              }
              else {
                this.fields.getField(this.name, field).visibleSTSCodesMessage = false;
                break;
              }
            };
        }
      } catch (ex) {
        console.log(ex)
      }
}
  private getprocedures(procList: any): string {
    var proc_ids = ""
    var procsArr = [];
    if (procList) {
      procsArr = Object.getOwnPropertyNames(procList);
    }
    for (let item of procsArr) {
      let proc = procList[item];
      //let proc = item;
      if (proc_ids.length == 0)
        proc_ids = proc['id'].replace("procedure-", "");
      else
        proc_ids = proc_ids + "|" + proc['id'].replace("procedure-", "");

    }
    return proc_ids;
  }
  
  toggleCRLThoracotomyStatus(b) {
    this.cRLThoracotomy = b;
  }

  togglePDALigationStatus(b) {
    this.pDALigation = b;
  }
}
