import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { LoginService } from "../../../user/login.service";
import { SurPicklistItemService } from '../../../shared-data-entry/surpicklist-item.service';
import { SurPicklistItemResponse } from '../../../../shared/iqis/surpicklist-item-response';
import { SurPicklistItemModel } from '../../../../shared/iqis/surpicklist-item-model';
import { SurDropdownItemModel } from '../../../../shared/iqis/surdropdown-item-model';
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { SurdataSharedAttributesService } from '../../../shared-data-entry/surdata-sharedattributes.service';
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-surgery-case-id',
  templateUrl: './surgery-case-id.component.html',
  styleUrls: ['./surgery-case-id.component.scss'],
  host: { 'class': "host" }
})
export class SurgeryCaseIdComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  surgeons = [];
  hospitalText = "";
  uniquepatientid = "";
  patdateofbirth = "";
  name = "Case Identification";
 showErrorMessage: boolean = false;
  public currentDate: Date = new Date();
  shareddata: SurdataSharedAttributesService;
  sharedattributes = [];
  public validationMessage: string = null;
  public surgdateOnload: number = null;
 public user: LoginService;
  public picklistItemService: SurPicklistItemService;
  public fields: SurgeryCaseFieldsService;
  constructor(private router: Router,_user: LoginService, _picklistItemService: SurPicklistItemService, _fields: SurgeryCaseFieldsService, _shareddata: SurdataSharedAttributesService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.user = _user;
    this.fields = _fields;
    this.shareddata = _shareddata;
     if (this.user.user) {
         this.hospitalText = this.user.user.Hospital;
         this.fields.setValue(this.name, "Hospital", this.user.user.HospitalId);
    }
    if (this.fields.getField(this.name, "Case Number").value === 0) {
      this.uniquepatientid = this.fields.getField("IQIC Identifier", "uniquePatientId").value;
      this.fields.setValue(this.name, "uniquePatientId", this.uniquepatientid);
      this.sharedattributes = this.shareddata.getsurattributes();
    }
    this.picklistItemService = _picklistItemService;
    this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "SURGEONS").subscribe(data => this.populatePicklistItems(data));
    this.surgdateOnload = Date.parse(this.fields.getField(this.name, 'Surg Date').value);
      this.errorMessageCheck();
    }

  ngOnInit() {
    localStorage.removeItem('surgerycaseiddirty');
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('surgerycaseiddirty', 'true');

    return !this.form.dirty;
  }
  getiqicpatientid() {
    var value = "";

    if (this.shareddata) {
      value = this.shareddata.suratrributes["UniquePatientID"];
    }
    
   return value;
  }

  populatePicklistItems(picklistItemResponse: SurPicklistItemResponse) {
   this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
   this.picklistItemService.mapToDropDown();
    this.surgeons = this.picklistItemService.dropDownPicklistItem;
   }

  errorMessageCheck() {
  let surgDate = this.fields.getField(this.name, 'Surg Date').value;
     let mill = Date.parse(surgDate);
     if (!(mill > 0)) {
     this.fields.setValue(this.name, 'Surg Date', null);
       $("#surg-date").val('');
     } else if (this.isfutureDate(surgDate)) {
       this.fields.setValue(this.name, 'Surg Date', new Date);
     }
      if (this.user.user) {
        this.hospitalText = this.user.user.Hospital;
        this.fields.setValue(this.name, "Hospital", this.user.user.HospitalId);
      }

    //if (this.fields.getField('IQIC Identifier', 'DateofBirth').value == ''
    //  || this.fields.getField('IQIC Identifier', 'DateofBirth').value == null
    //  || this.fields.getField('IQIC Identifier', 'DateofBirth').value == undefined) {

    //  if (this.surgdateOnload != Date.parse(this.fields.getField(this.name, 'Surg Date').value)) {
    //    this.showErrorMessage = true;
    //    this.validationMessage = "Surgery Date is updated but Date of Birth is empty in IQIC Identifier tab for Age calculation. Date of Birth field is required to continue."
    //  }
    //}
    if ((this.fields.validateDate) && (this.fields.getField(this.name, "uniquePatientId").value == null || this.fields.getField(this.name, "uniquePatientId").value == undefined || this.fields.getField(this.name, "uniquePatientId").value == '')) {
      this.showErrorMessage = true;
      this.validationMessage = "IQIC identifier is required. Please use the IQIC Identifier tab to generate this value."
    }
   
    if ((this.fields.validateDate)
      && (this.fields.getField(this.name, 'Surg Date').value === null
        || this.fields.getField(this.name, 'Surg Date').value === '')) {
      this.showErrorMessage = true;
      this.validationMessage = "Surgery Date is a required field to save."
    }
  
    }

   private isfutureDate(value: string) {
     let now = new Date;
     let target = new Date(value);

      if (target.getFullYear() > now.getFullYear()) {
       return true;
     } else if (target.getFullYear() == now.getFullYear()) {
       if (target.getMonth() > now.getMonth()) {
         return true;
       } else if (target.getMonth() == now.getMonth()) {
         if (target.getDate() > now.getDate()) {
           return true;
         }
       }
    }

     return false;
   }

}
