import { Component, OnInit ,ViewChild,HostListener} from "@angular/core";
import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { CaseFieldsService } from "../case-fields.service";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";

@Component({
  selector: "app-indicators",
  templateUrl: "./indicators.component.html",
  styleUrls: ["./indicators.component.scss"],
  host: { class: "host" }
})
export class IndicatorsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  private procs: Array<string> = ["Pre-Procedure", "Procedural", "Post-Procedure",'48 Hrs Post-Cath'];
  private procsNA: Array<string> = ["6 Hrs Pre-Procedure","Post-Procedure Before 6hrs", "18 to 24 Hrs Post-Cath","48 Hrs Post-Cath"];

  airwayManagement = [];
  ventilation = [];
  sedation = [];
  lines = [];
  drains = [];
  mechanicalSupport = [];
  IVMedications = [];
  nursingAssignment0 = [];
  nursingAssignment1 = [];
  nursingAssignment2 = [];
  nursingAssignment3 = [];

  disabled = ["Not Applicable"];
  selectedDisabled = ["Not Applicable"];
  name = "Indicators of Required Resources";
  types = [];

  public user: LoginService;
  public picklistItemService: PicklistItemService;
  public fields: CaseFieldsService;
  constructor(private router: Router,_user: LoginService, _picklistItemService: PicklistItemService, fields: CaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.user = _user;
    this.fields = fields;

    if (this.user.user) {
      this.picklistItemService = _picklistItemService;
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "ALL")
        .subscribe(data => this.populatePicklistItems(data));
    }
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');


    return !this.form.dirty;
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.airwayManagement = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Airway Management")
      .map(x => x.name);
    this.ventilation = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Ventilation")
      .map(x => x.name);
    this.sedation = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Sedation")
      .map(x => x.name);
    this.lines = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Lines")
      .map(x => x.name);
    this.drains = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Drains")
      .map(x => x.name);
    this.mechanicalSupport = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Mechanical Support")
      .map(x => x.name);
    this.IVMedications = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "IV Medications")
      .map(x => x.name);
    this.nursingAssignment0 = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Nursing Assignment")
      .map(x => x.name);
    this.nursingAssignment1 = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "<6 hrs post-cath")
      .map(x => x.name);
    this.nursingAssignment2 = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === ">6 hrs post-cath")
      .map(x => x.name);
    this.nursingAssignment3 = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "48 Hrs Post-Cath")
      .map(x => x.name);

    this.sedation.unshift("None");
    this.lines.unshift("None");
    this.drains.unshift("None");
    this.mechanicalSupport.unshift("None");
    this.IVMedications.unshift("None");

    this.types = [
      {
        name: "Airway Management",
        multiple: false,
        id: "airwayManagement",
        items: this.airwayManagement,
        selections: [
          "", "", ""
        ]
      },
      {
        name: "Ventilation",
        multiple: true,
        items: this.ventilation,
        id: "ventilation",
        selections: [
          [""], [""], [""]
        ]
      },
      {
        name: "Sedation",
        multiple: false,
        items: this.sedation,
        id: "sedation",
        selections: [
          "", "", ""
        ]
      },
      {
        name: "Lines",
        multiple: true,
        items: this.lines,
        id: "lines",
        selections: [
          [""], [""], [""]
        ]
      },
      {
        name: "Drains",
        multiple: true,
        items: this.drains,
        id: "drains",
        selections: [
          [""], [""], [""]
        ]
      },
      {
        name: "Mechanical Support",
        multiple: true,
        items: this.mechanicalSupport,
        id: "mechanicalSupport",
        selections: [
          [""], [""], [""]
        ]
      },
      {
        name: "IV Medications",
        multiple: true,
        items: this.IVMedications,
        id: "IVMedications",
        selections: [
          [""], [""], [""]
        ]
      },
      {
        name: "Nursing Assignment",
        multiple: false,
        items: this.nursingAssignment0,
        id: "nursing-assignment",
        selections: [
          "", "", ""
        ]
      }
    ];

    this.setSelectionFromFields(this.types);
  }

  ngOnInit() {
    
  }

  checkNone(event, type, period): boolean {
    var arr = type.selections[period].splice(0);
    const index = arr.indexOf("None");
    let hasNone = type.items.indexOf("None") > -1;
    if (arr.length === 0) {
      if (hasNone) {
        arr = ["None"];
      } else {
        arr = [type.items[0]];
      }
    }
    if (index === 0 && arr.length > 1) {
      arr.shift();
    }
    if (index == arr.length - 1) {
      arr = [arr[arr.length - 1]];
    }
    type.selections[period] = arr;

    return true;
  }

  private setSelectionFromFields(types) {
    try {
      let period: Array<number> = [0, 1, 2, 3];
      for (let t of types) {
        for (let p of period) {
          let list = null;
          if (t.name != "Nursing Assignment") {
            list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === t.name);
          } else {
            if (p == 0) {
              list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === t.name);
            } else if (p == 1) {
              list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === "<6 hrs post-cath");
            } else if (p == 2) {
              list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === ">6 hrs post-cath");
            } else if (p == 3) {
              list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === "48 Hrs Post-Cath");
            }
          }

          let val = "";
          if (t.name != "Nursing Assignment") {
            val = this.fields.getValue(this.name, (t.name + " " + this.procs[p]));
          } else {
            val = this.fields.getValue(this.name, (t.name + " " + this.procsNA[p]));
          }

          if (t.multiple) {
            let valMap: Array<string> = [];
            let valList: Array<string> = [];
            if (val != null) {
              valList = val.split("|");
            }

            for (let v of valList) {
              for (let dropDownItem of list) {
                if (dropDownItem.code === v) {
                  valMap.push(dropDownItem.name);
                }
              }
            }

            if (valMap.length === 0) {
              valMap = ["None"];
            }

            t.selections[p] = valMap;
          } else {
            let valMap = null;
            for (let dropDownItem of list) {
              if (dropDownItem.code === val) {
                valMap = dropDownItem.name;
              }
            }

            if (valMap == null && t.name != "Nursing Assignment") {
              valMap = "None";
            }

            t.selections[p] = valMap;
          }
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public updateFields(type, period): boolean {
    try {
      let val = type.selections[period];

      let list = null;
      if (type.name != "Nursing Assignment") {
        list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
      } else {
        if (period == 0) {
          list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
        } else if (period == 1) {
          list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === "<6 hrs post-cath");
        } else if (period == 2) {
          list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === ">6 hrs post-cath");
        } else if (period == 3) {
          list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === "48 Hrs Post-Cath");
        }

      }

      if (type.multiple) {
        let valMap = [];

        for (let select of val) {
          for (let dropDownItem of list) {
            if (dropDownItem.name === select) {
              valMap.push(dropDownItem.code);
            }
          }
        }
        if (valMap != null && valMap.length > 0) {
          this.fields.setValue(this.name, (type.name + " " + this.procs[period]), valMap.join("|"));
        } else {
          this.fields.setValue(this.name, (type.name + " " + this.procs[period]), "");
        }
      } else {
        let valMap = null;
        for (let dropDownItem of list) {
          if (dropDownItem.name === val) {
            valMap = dropDownItem.code;
          }
        }
        
        if (type.name != "Nursing Assignment") {
          this.fields.setValue(this.name, (type.name + " " + this.procs[period]), valMap);
        } else {
          this.fields.setValue(this.name, (type.name + " " + this.procsNA[period]), valMap);
        }
      }
    } catch (ex) {
      console.log(ex);
    }

    return true;
  }

  on6HoursChange(e) { // here e is a native event
    this.fields.checked6Hours = e.target.checked;
    if (e.target.checked) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 6 Hrs Pre-Procedure").hide = true;
    } else {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 6 Hrs Pre-Procedure").hide = false;
    }
  }

  on24HoursChange(e) { // here e is a native event
    this.fields.checked24Hours = e.target.checked;
    if (e.target.checked) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 18 to 24 Hrs Post-Cath").hide = true;
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
    } else {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 18 to 24 Hrs Post-Cath").hide = false;
     // this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = false;
    }
  }

  on48HoursChange(e) { // here e is a native event
    this.fields.checked48Hours = e.target.checked;
    if (e.target.checked) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
    } else {
     // this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = false;
    }
  }

}
