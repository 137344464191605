
import { Component, ElementRef, OnInit, ViewChild, Injectable } from "@angular/core";
import { CaseLookupService } from "../case-lookup/case-lookup.service";
import { AdverseEventsReportService } from "./report-services/adverse-events-report.service";
import { CaseTypeService } from "./report-services/case-type.service";
import { CharacteristicsService } from "./report-services/characteristics.service";
import { HemoVariablesService } from "./report-services/hemo-variables.service";
import { RadiationService } from "./report-services/radiation.service";
import { RiskAdjustmentService } from "./report-services/risk-adjustment.service";
import { RiskTypeService } from "./report-services/risk-type.service";
import { ColorServiceService } from "./report-services/color-service.service";
import { enterAnimation } from "../../../animations/enter.animation";

import { ReportRequest } from "../../../shared/report-request";
import { ReportResponse } from "../../../shared/report-response";
// import Wijmo components
import { CollectionView } from 'wijmo/wijmo';
import * as wjcCore from 'wijmo/wijmo';
import * as wjcChart from 'wijmo/wijmo.chart';

import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../shared/picklist-item-response';
import { LoginService } from "../login.service";

import { ReportService } from "./report-services/report.service";
import { DatePipe } from "@angular/common";
import { NgxSpinnerService } from 'ngx-spinner';

import { ReportDataAE } from "../../../shared/report-model-ae";
import { SiteExtract } from "../../../shared/report-model-site-extract";
import { forEach } from "@angular/router/src/utils/collection";
import { ReportDataMM } from "../../../shared/report-model-mm";

import { NgForm } from "@angular/forms";

@Injectable({
  providedIn: "root"
})

@Component({
  selector: "app-manage-reports",
  templateUrl: "./manage-reports.component.html",
  styleUrls: ["./manage-reports.component.scss"],
  host: { '[@enterAnimation]': '' },
  animations: [enterAnimation],
  providers: [DatePipe]
})

export class ManageReportsComponent   implements OnInit {
  @ViewChild('reportform')
  form: NgForm;
  itemList = [];
  selectedItems = [];
  settings = {};
 
  @ViewChild('fChart') fChart: any;

  flexChart: wjcChart.FlexChart;
  cvPaging: wjcCore.CollectionView;
  cvPagingmm: wjcCore.CollectionView;
  cvExtractCaseInfo: wjcCore.CollectionView;
  cvExtractClinicalInfo: wjcCore.CollectionView;
  cvExtractProcedureInfo: wjcCore.CollectionView;
  cvExtractHemoInfo: wjcCore.CollectionView;
  cvExtractCaseTypeInfo: wjcCore.CollectionView;
  cvExtractAEEventInfo: wjcCore.CollectionView;
  cvExtractResourceInfo: wjcCore.CollectionView;
  cvExtractEOCAdmitDispoInfo: wjcCore.CollectionView;
  cvExtractCalculatorInfo: wjcCore.CollectionView;
  fromDate: Date;
  toDate: Date;
  fromWeight: number;
  toWeight: number;
  htmlStringInsert: String;
  contain1stClass: String;
  contain2ndClass: String;
  contain3rdClass: String;
  contain4thClass: String;
  errorMessage: String;
  datePipe: DatePipe;
  isValid: Boolean;
  showSideBar: boolean = true;
  filterStatus: any = 'Hide Filter'
  ageOptions = [];
  flexChartPoints: number;
  title: string;
  duration: number;
  easing: string;
  animationMode: string;
  totalCasesLabel: string;
  totalCases: number = 0;
  reportCategorySelected: string = '';
  reportSelected: string = '';
  reportName: string = '';
  XAxisLabel: string = '';
  legendPosition: string = 'Bottom';
  titleX: string = '';
  yAxisFormat: string = '';


  //chart properties
  chartType = 'Column';
  stacking = 'None';
  rotated = false;

  //Reports, Report Categories and Filters are retrieved from the Data Base
  //reportTypes = ["Date Range", "Clinical Characteristics", "Hemodynamic Variables", "Case Type", "Risk Type Category", "Adverse Events", "Risk Adjustment", "Radiation"];
  //reportTypes = ["Case Volume By Center", "Case Volume Over Time", "Case Volume By Center By Age", "Age By Center"];
  //reportCategories = ["Case Volume", "Comorbities", "Hemodynamics", "Case Type", "Risk Type", "Adverse Events", "Risk Adjustment", "Radiation"];
  reportCategories = [];
  reportTypes = [];
  chartTypes = ["Column", "Bar", "Scatter", "Line", "LineSymbols", "Area", "Spline", "SplineSymbols", "SplineArea"];

  //caseTypes = ["Diagnostic", "Intervention", "Biopsy"];
  caseTypes = [];
  timeIntervals = [];
  operators = [];
  operatorsOptions = [];
  adverseEventSeverity = [];
  adverseEventSeverityOptions = [];

  c3poProc = [];
  hospitalProc = [];
  hospitalProcOptions = [];
  public c3poOptions = [];
  iqicProc = [];
  public iqicOptions = [];
  selectedPdaProc = []; 
  currentValue = "Select Report Type";
  riskTypes = ["REC 1", "REC 2", "REC 3", "CHARM 1", "CHARM 2", "CHARM 3", "CHARM 4", "CRISP 1", "CRISP 2", "CRISP 3", "IMPACT 1", "IMPACT 2", "IMPACT 3", "IMPACT 4", "IMPACT 5", "IMPACT 6"];

  events = ["Any", "Major Adverse Event", "Severity 3", "Severity 4", "Severity 5"];

  selectedEvents = [];
  radiationCat = [1, 2, 3, "NC"];
  selectedRadiationCat = [];
  report = false;
  showSpinner = false;
  showDateRangeFilter = false;
  showWeightFilter = false;
  showHeightFilter = false;
  showAgeRangeFilter = false;
  showCaseTypeFilter = false;
  showTimeIntervalFilter = false;
  showOperatorFilter = false;
  showOperatorColumn = false;
  showAdverseEventSeverityFilter = false;
  showReportCategories = false;
  showReports = false;
  showReport = false;
  showStackedItems = false;
  showYAxisData = true;
  showVolumeTimeIntervalFilter = false;
  showVolumeReports = false;
  showAEReports = false;
  showMMReport = false;
  showSaveToPDF = false;
  showExtractReport = false;
  reviewStatus = false;
  viewAllSites: boolean = false;
  exportAll: boolean = false;
  exportAllmm: boolean = false;
  //These services are from previous versions before
  //It was changed to a generic web service 
  adverseEvents: AdverseEventsReportService;
  characteristics: CharacteristicsService;
  hemoVariables: HemoVariablesService;
  radiation: RadiationService;
  riskAdjustment: RiskAdjustmentService;
  riskType: RiskTypeService;
  colorService: ColorServiceService;
  http: any;
  reportService: ReportService;
  data: any;
  siteExtractData: any;
  picklistItemService: PicklistItemService;
  lookup: CaseLookupService;
  user: LoginService;
  elementRef: ElementRef;
  reportDataAE: ReportDataAE[];
  reportDataMM: ReportDataMM[];



  constructor(
    lookup: CaseLookupService,
    adverseEvents: AdverseEventsReportService,
    caseType: CaseTypeService,
    characteristics: CharacteristicsService,
    hemoVariables: HemoVariablesService,
    radiation: RadiationService,
    riskAdjustment: RiskAdjustmentService,
    riskType: RiskTypeService,
    colorService: ColorServiceService,
    reportService: ReportService,
    datePipe: DatePipe,
    picklistItemService: PicklistItemService,
    user: LoginService,
    private spinner: NgxSpinnerService,
    elementRef: ElementRef,



  ) {
   
    this.adverseEvents = adverseEvents;
    this.characteristics = characteristics;
    this.hemoVariables = hemoVariables;
    this.radiation = radiation;
    this.riskAdjustment = riskAdjustment;
    this.riskType = riskType;
    this.colorService = colorService;
    this.reportService = reportService;
    this.datePipe = new DatePipe('en-US');

    this.flexChartPoints = 10;
    this.title = 'FlexChart';
    this.duration = 40;
    this.chartType = 'Column';
    this.easing = 'Swing';
    this.animationMode = 'All';
    this.picklistItemService = picklistItemService;
    this.lookup = lookup;
    this.user = user;
    this.flexChart = this.flexChart;



  }
  ngOnInit() {

    this.changeLegend();

    this.htmlStringInsert = "Waiting....";
    this.contain1stClass = "my-card d-flex flex-row flex-nowrap";
    this.contain2ndClass = "d-none d-md-flex";
    this.contain3rdClass = "fa";
    this.contain4thClass = "card-body d-flex align-items-center justify-content-center";  
    this.lookup.HospitalId = this.user.user.HospitalId;
    this.lookup.searchClear();
    this.hospitalProc.splice(0, 0, "All");
    //load the report categories list
    this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Report Categories").subscribe(data => this.populatePicklistItems(data));

    
   
  }

  toggle() {
    this.showSideBar = !this.showSideBar;   
 

    if (this.showSideBar)
      this.filterStatus = "Hide Filter";
    else
      this.filterStatus = "Show Filter";
  }
 
  populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
   
    if (picklistItemResponse.DataObject.length > 0) {

      this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
      this.picklistItemService.mapToDropDown();
     

      switch (picklistItemResponse.DataObject[0].PicklistName) {
        case 'HospitalsC3POR3':
          this.hospitalProcOptions = this.picklistItemService.dropDownPicklistItem;
          this.hospitalProc = this.hospitalProcOptions.map(x => x.name).sort();
          this.hospitalProc.splice(0, 0, "All");
          break;
        case 'HospitalsIQIC':
          this.hospitalProcOptions = this.picklistItemService.dropDownPicklistItem;
          this.hospitalProc = this.hospitalProcOptions.map(x => x.name).sort();
          this.hospitalProc.splice(0, 0, "All");
          break;
        case 'Age Range':
          this.ageOptions = this.picklistItemService.dropDownPicklistItem;
          if (this.ageOptions.length > 0) {           
            this.showAgeRangeFilter = true;
          }
          break;
        case 'Case Type':
          this.caseTypes = this.picklistItemService.dropDownPicklistItem;
          if (this.caseTypes.length > 0) {            
            this.showCaseTypeFilter = true;
          }
          break;
        case 'Time Interval':
          this.timeIntervals = this.picklistItemService.dropDownPicklistItem;
          if (this.timeIntervals.length > 0) {
            
            this.showVolumeTimeIntervalFilter = true;
          }
          break;
        case 'Reports':
          this.reportTypes = this.picklistItemService.dropDownPicklistItem;
          if (this.reportTypes.length > 0) {
            this.showReports = true;
          }
          break;
        case 'Report Categories':
          this.reportCategories = this.picklistItemService.dropDownPicklistItem;
          if (this.reportCategories.length > 0) {
            this.showReportCategories = true;
          }
         
          break;
        case 'Operators':
          //Hide Operator Filter for IQIC users per Lisa
          //If a user is not an IQIC user or they are both a C3POR3 and IQIC user then show the operator filter and grid column
          if ((this.user.IsIqicUser() == true && this.user.user.UserAppRegistry == 'BOTH') || (this.user.IsIqicUser() == false && this.user.user.UserAppRegistry == 'C3POR3')) {
            this.operatorsOptions = this.picklistItemService.dropDownPicklistItem;
            this.operators = this.operatorsOptions.map(x => x.name).sort();
            this.operators.splice(0, 0, "All");
            this.lookup.Operator = "All";
            if (this.operators.length > 0) {
              this.showOperatorFilter = true;
              this.showOperatorColumn = true;
            }
          }

          break;
        case 'Adverse event severity':
          this.adverseEventSeverityOptions = this.picklistItemService.dropDownPicklistItem;
          this.adverseEventSeverity = this.adverseEventSeverityOptions.map(x => x.name).sort();
          this.adverseEventSeverity.splice(0, 0, "All");
          this.lookup.AdverseEventSeveritys = "All";
          if (this.adverseEventSeverity.length > 0) {
            this.showAdverseEventSeverityFilter = true;
          }
          break;
      }      
    }
  }

   
  selectAll(place, toplace) {
    this[place] = this[toplace];
  }
  unselectAll(place) {
    this[place] = [];
  }

  generateReport() {
    
    //this.report = true;
    this.data = null;
    this.showReport = false;
    this.totalCases = null;
    this.totalCasesLabel = null;
    this.showStackedItems = false;
    this.legendPosition = 'Bottom';
    this.showYAxisData = true;
    this.titleX = null;
    let reportNames = this.reportTypes.find(x => x.code == this.lookup.reportSelected).name;
    let sites = "";
    let selectedOperator = "", selectedSeverity = "";
 


    if (this.lookup.siteSelected != null && this.lookup.siteSelected.indexOf('All') > -1) {
      for (var v in this.hospitalProcOptions) {
        sites = sites + "," + this.hospitalProcOptions[v].code;
      }
    }
    else
    for (var val in this.lookup.siteSelected)
    {
      sites = sites + "," + this.hospitalProcOptions.find(x => x.name == this.lookup.siteSelected[val])["code"];

      }
    if (this.lookup.Operator == null || this.lookup.Operator.indexOf('All') > -1)
        selectedOperator = 'All';
    else
      for (var val in this.lookup.Operator) {
        selectedOperator = selectedOperator + "," + this.operatorsOptions.find(x => x.name == this.lookup.Operator[val])["code"];
      }
    if (this.lookup.AdverseEventSeveritys == null || this.lookup.AdverseEventSeveritys.indexOf('All') > -1)
      selectedSeverity = 'All';
    else
      for (var val in this.lookup.AdverseEventSeveritys) {
        selectedSeverity = selectedSeverity + "," + this.adverseEventSeverityOptions.find(x => x.name == this.lookup.AdverseEventSeveritys[val])["code"];
      }
    
    //Change this to include new reports
    this.validateInput();

    if (this.isValid == true) {

      this.spinner.show();  
      
      let reportParams = new ReportRequest;

      //format the dates
      //Correct format for query
      //reportParams.FromDate = "31-AUG-15";
      //reportParams.ToDate = "31-AUG-18";      
      reportParams.FromDate = this.datePipe.transform(this.fromDate, 'dd-MMM-yy');
      reportParams.ToDate = this.datePipe.transform(this.toDate, 'dd-MMM-yy');
      reportParams.CaseType = this.lookup.CaseType;
      reportParams.AgeRange = this.lookup.AgeRange;
      reportParams.TimeInterval = this.lookup.TimeInterval;
      reportParams.Reviewed = this.lookup.Reviewed;
      reportParams.NotReviewed = this.lookup.NotReviewed;
      reportParams.FromWeight = this.fromWeight;
      reportParams.ToWeight = this.toWeight;
      //Pass the report that was selected
      reportParams.ReportID = this.lookup.reportSelected;
      reportParams.ReportName = reportNames;
      reportParams.OperatorID = selectedOperator;
      reportParams.AdverseEventSeverity = selectedSeverity;
      reportParams.Email = this.user.user.Email;
      reportParams.UserName = this.user.user.Hospital;
      reportParams.AppRegistry = this.user.user.UserAppRegistry;
      reportParams.sitesSelected = sites;
      if (this.viewAllSites == false)
        reportParams.CathLocationID = this.user.user.HospitalId;

      // if (this.viewAllSites == false || this.lookup.reportSelected == '21' || this.lookup.reportSelected == '41') {
      //if (this.viewAllSites == false || reportNames == 'Generate Case Summary' || reportNames == 'Extract Data' || reportNames == '') {
       
      //  let user = JSON.parse(sessionStorage.getItem('currentUser'));        
      //  reportParams.CathLocationID = user.HospitalId;
      //}
    
     
      this.reportService.getReportData(reportParams).subscribe(responsedata => this.getReportDataResponse(responsedata));

      }
  }

  selectHospitals() {
    if (this.lookup.RegistrySelected == 'C3POR3') {
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "HOSPITALSC3POR3")
        .subscribe(data => this.populatePicklistItems(data));
    }
    else {
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "HOSPITALSIQIC")
        .subscribe(data => this.populatePicklistItems(data));
    }
  }

  //This is the web API response data
  getReportDataResponse(reportData: ReportResponse) {
    //In process, web service has been changed to return the error.
    //Logging was also added using NLogLogger with a reference to BCH.HC.Core.Fwrk but cow is throwing a constraint error
    //and is commented out for now
    //Need to change to API logging per Prasad
    if (reportData.DataObject != null ) {    
      if (reportData.DataObject[0] != null) {
        if (reportData.DataObject[0].ReportError != null) {
          this.showErrorMessage(reportData.DataObject[0].ReportError);
          this.spinner.hide();
          return false;
        }
      }     
    }
    if (reportData.DataObject[0].ReportName == "Extract Data") {
      this.showSuccessMessage("Report request received. An email will be sent with extract data within an hour. If you do not receive an email, please check your Junk Mail folder.If the extract is not in your Junk Mail folder, please contact us at cardiooutcomes@childrens.harvard.edu. ");
      this.spinner.hide();
      return false;
    }
   if (reportData.DataObject == null  ){
      this.showErrorMessage("No data found!");
      this.spinner.hide();
      return false;
    }
 
    else {

      this.displayReport(reportData);  

    }
  }
  //Determines the report to display
  displayReport(reportData: ReportResponse) {

    //Displays the Report header and name
   
      this.showReport = true;
    this.clearErrorMessage();
    this.reportName = reportData.DataObject[0].ReportName;

    //using report ID for now but may need to use name
    //if the ID'S change?
    switch (this.lookup.reportSelected) {
      case '1': //CaseVolumeByCenter
      case '2': //SiteCaseVolumeOverTime
      case '3': //CaseVolumeByCenterBy Age
      case '4': //AgeByCenter
        //Volume reports specific logic
        this.showVolumeReports = true;
        this.showExtractReport = false;
        this.showAEReports = false;
        this.showMMReport = false;
        if (reportData.DataObject[0].ChartType == 'Stacked') {
          //changing the stacked item select list to hide so that report 3 will be stacked and report 4 will be stacked 100% with no option to change
          //so that the Y Axis formating will display correctly
          //also removed the percentage calculation from the AgeByCenter query since Wijmo is calculating the percentage from the data using the "p0" format
          //option.
          this.showStackedItems = true;
          //Hiding the XAXIS because this is a stacked report totaling to 100
          //Don't want to include it in the total
          this.showYAxisData = false;
          //this.titleX = reportData.DataObject[0].XAxisLabel;
          //Shifting the legends less than 30 <30  greater than >30 etc. position to the right
          this.legendPosition = 'Right';
        }

        //Set XAxis Label for all Volume reports to get rid of the default
        //blue bar that is added when prperty [name]="XAxisLabel" is used in <wj-flex-chart-series> tag
        this.titleX = reportData.DataObject[0].XAxisLabel;

        this.data = reportData.DataObject[0].ReportDetails;
        let user = JSON.parse(localStorage.getItem('currentUser'));

        //look up the site legend that corresponds to the SiteID
        let usersitelegendID = reportData.DataObject[0].ReportDetails.find(f => f.XAxis == user.HospitalId);
        if (reportData.DataObject[0].ReportName == "Case Volume By Center") {
          if (usersitelegendID != null) {
            this.htmlStringInsert = "You are center number: " + usersitelegendID.SiteLegend;
          }

          else {
            this.htmlStringInsert = "Your site has no data for this query.";
          }
        }
        this.contain1stClass = "my-card d-flex flex-row flex-nowrap";
        this.contain2ndClass = "d-none d-md-flex";
        this.contain3rdClass = "fa";
        this.contain4thClass = "card-body d-flex align-items-center justify-content-center";

        //Total the site cases
        reportData.DataObject[0].ReportDetails.forEach(reportModel => {
          //YAxis represents Volume;
          this.totalCases = this.totalCases + reportModel.YAxis;
        })
        //Set total cases label remove the case total for Age By Center
        //this.totalCasesLabel = (this.lookup.reportSelected == '4') ? reportData.DataObject[0].YAxisLabel : this.totalCases.toString() + " " + reportData.DataObject[0].YAxisLabel;
        //Removing the total cases number from the label per Lisa's group Laurens email 12/11/2018
        this.totalCasesLabel = reportData.DataObject[0].YAxisLabel;
        
        this.XAxisLabel = reportData.DataObject[0].XAxisLabel;
        //set stacking value from Database 
        this.stacking = reportData.DataObject[0].Stacking;
        this.showSaveToPDF = true;

        //Apply perecentage formating to the YAXIS
        //this.yAxisFormat = ((this.lookup.reportSelected == '4') || (this.lookup.reportSelected == '3')) ? "p0" : '';
        this.yAxisFormat = (this.lookup.reportSelected == '4') ? "p0" : '';
         

        return new CollectionView(this.data);
        break;
      case '21': //AE Summary
        this.showVolumeReports = false;
        this.showExtractReport = false;
        this.showAEReports = true;
        this.showMMReport = false;
        // this.data = reportData.DataObject[0].ReportDataAEHighestSeverity;
        this.data = reportData.DataObject[0].ReportDataAE;
        for (var i = 0; i < this.data.length; i++) {
          this.data[i]["DateOfCath"] = new Date(this.data[i]["DateOfCath"]);
        }
        this.cvPaging = new wjcCore.CollectionView(this.data);
        this.cvPaging.pageSize = 10;

        //Used for the hidden grid Export to Excel
        this.reportDataAE = reportData.DataObject[0].ReportDataAE;

        this.showSaveToPDF = false; 
        this.spinner.hide();       
        
        return new wjcCore.CollectionView(this.data);
        break;
      case '11': //MM Summary
        this.showVolumeReports = false;
        this.showExtractReport = false;
        this.showAEReports = false;
        this.showMMReport = true;
        // this.data = reportData.DataObject[0].ReportDataAEHighestSeverity;
        this.data = reportData.DataObject[0].ReportDataMM;
        for (var i = 0; i < this.data.length; i++) {
            this.data[i]["DateOfCath"] = new Date(this.data[i]["DateOfCath"]);
        }
        this.cvPagingmm = new wjcCore.CollectionView(this.data);
        this.cvPagingmm.pageSize = 10;

        //Used for the hidden grid Export to Excel
        this.reportDataMM = reportData.DataObject[0].ReportDataMM;


        this.showSaveToPDF = false;
        this.spinner.hide();

        return new wjcCore.CollectionView(this.data);
        break;

      case '41': //Site Extract
        this.showVolumeReports = false;
        this.showAEReports = false;
        this.showMMReport = false;
        this.showSaveToPDF = false;
        this.showExtractReport = true;
        this.cvExtractCaseInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.CaseInfo);
        this.cvExtractClinicalInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.ClinicalInfo);


        this.cvExtractProcedureInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.ProcedureInfo);
        this.cvExtractHemoInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.HemoInfo);
        this.cvExtractCaseTypeInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.CaseType);
        this.cvExtractAEEventInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.AEEventInfo);
        this.cvExtractResourceInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.ResourceInfo);
        this.cvExtractEOCAdmitDispoInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.EOCAdmiDispoInfo);
        this.cvExtractCalculatorInfo = new wjcCore.CollectionView(reportData.DataObject[0].SiteExtract.CalculaterRiskValues);


        //this.data = reportData.DataObject[0].SiteExtract;
        //this.cvPaging = new wjcCore.CollectionView(this.data);  
       
        //this.spinner.hide();
        //return new wjcCore.CollectionView(this.data);
      
        break;


    } 
    
  }

  clearFilterSelections() {    
    this.fromDate = null;
    this.toDate = null;
    this.data = null;
    this.lookup.CaseType = '';
    this.lookup.AgeRange = '';
    this.lookup.TimeInterval = '';
    this.lookup.Operator = "All";
    this.lookup.AdverseEventSeveritys = "All";
    this.fromWeight = null;
    this.toWeight = null;
    this.totalCases = null;
    this.totalCasesLabel = null;
  //  this.lookup.reportCategorySelected = '';
   // this.lookup.reportSelected = '';
    this.showReport = false;
    this.clearErrorMessage();
    this.lookup.siteSelected = '';
    this.lookup.RegistrySelected = '';
    this.lookup.Reviewed = false;
    this.lookup.NotReviewed = false;
   
    return false;
  }

  private showErrorMessage(errorMessage: string) {
    
    this.htmlStringInsert = errorMessage;
    this.contain1stClass = "card error-card my-card d-flex flex-row flex-nowrap";
    this.contain2ndClass = "error-icon d-none d-md-flex";
    this.contain3rdClass = "fa fa-exclamation-circle";
    this.contain4thClass = "card-body d-flex align-items-center";
    //reset the grid data
    this.data = null; 
    this.isValid = false;
    this.spinner.hide();
   
    return false;

  }

  private showSuccessMessage(successMessage: string) {

    this.htmlStringInsert = successMessage;
    this.contain1stClass = "card success-card my-card d-flex flex-row flex-nowrap";
    this.contain2ndClass = "success-icon d-none d-md-flex";
    this.contain3rdClass = "fa fa-check-circle";
    this.contain4thClass = "card-body d-flex align-items-center";
    //reset the grid data
    this.data = null;
    this.isValid = false;
    this.spinner.hide();

    return false;

  }

  private clearErrorMessage() {

    this.htmlStringInsert = "";
    this.contain1stClass = "";
    this.contain2ndClass = "";
    this.contain3rdClass = "";
    this.contain4thClass = "";   
    return true;

  }


  private validateInput() {

    let reportName = this.reportTypes.find(x => x.code == this.lookup.reportSelected).name

    //for testing
    if (reportName == 'Hemodynamics' || reportName == 'Case Type' || reportName == 'Generate Case Summary') {
      this.isValid = true;
      return true;
    }

    if (reportName == 'Extract Data') {
      if (this.fromDate != null && this.toDate != null) {
        if ((this.toDate.getFullYear() - this.fromDate.getFullYear()) > 2) {
          this.showErrorMessage("Can export only two years data. please select data under two years.");
          return false;
        }
        if (this.lookup.RegistrySelected != "" && this.lookup.siteSelected == "") {
          this.showErrorMessage("Please select Site(S)");
          return false;
        }
      }
    }
    if (reportName == 'Comorbities' && this.lookup.TimeInterval == '') {

      this.showErrorMessage("Time Interval required for this report! Please select a time interval.");
      return false;
    }    

    if (reportName == '' || reportName == null || this.lookup.reportCategorySelected == null || this.lookup.reportCategorySelected == '')
    {
      this.showErrorMessage("Category/Report selection required! Please select a report.");
      return false;
    }

    if (this.fromDate == null && this.toDate == null && this.lookup.CaseType == '' && this.lookup.AgeRange == '' && this.lookup.TimeInterval == '' && this.lookup.Reviewed == false && this.lookup.NotReviewed == false) {
      
      this.showErrorMessage("Input Error! Search criteria is required!");
      return false;
    }

    else if ((this.fromDate != null && this.toDate == null) || (this.fromDate == null && this.toDate != null) ) {
      
      this.showErrorMessage("Input Error! Both a From and To Date are required!");
      return false;
    }

    else if (this.toDate < this.fromDate) {

      this.showErrorMessage("Date Input Error! To Date cannot be less than From Date !"); 
    } 
    else {
      this.htmlStringInsert = "You are site number: 1";
      this.contain1stClass = "my-card d-flex flex-row flex-nowrap";
      this.contain2ndClass = "d-none d-md-flex";
      this.contain3rdClass = "fa";
      this.contain4thClass = "card-body d-flex align-items-center justify-content-center";
      this.isValid = true;
    }  

  }

  onResize(event) {
    this.changeLegend();
  }


  onReportCategorySelected() {

    //when a new report is selected clear out the old report
    this.clearFilters();

    this.clearReports();

    //GET THE REPORTS FOR THE SELECTED CATEGORY
    this.picklistItemService.getPicklistItem(this.lookup.reportCategorySelected, "Reports").subscribe(data => this.populatePicklistItems(data));

  } 
  

  onReportSelected() {
    var reportname = this.reportTypes.find(x => x.code == this.lookup.reportSelected)["name"];
    if (reportname == "M&M Report" || reportname == "AE Summary") {
         this.reviewStatus = true;
       }
  //  this.clearFilters();
    
    //Get the filters associated with the Report
    this.picklistItemService.getPicklistItem(this.lookup.reportSelected, "ReportFilters").subscribe(data => this.getReportFilters(data));   
    
  }

  //Get the filters associated with a report
  getReportFilters(picklistItemResponse: PicklistItemResponse) {
   

    if (picklistItemResponse.DataObject.length > 0) {

      picklistItemResponse.DataObject.forEach(pickList => {

        switch (pickList.ChoiceText) {
          case 'Age Range':           
            this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Age Range").subscribe(data => this.populatePicklistItems(data));
            break;
          case 'Case Type':            
            this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Case Type").subscribe(data => this.populatePicklistItems(data));
            break;
          case 'Date Range':
            this.showDateRangeFilter = true;
            break;
          case 'Time Interval':                 
            this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Time Interval").subscribe(data => this.populatePicklistItems(data));           
            break;           
          case 'Weight Range':
            this.showWeightFilter = true;
            break;
          case 'Operator':
            this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "OPERATORS").subscribe(data => this.populatePicklistItems(data));
            if (this.operators.length > 0) {
              this.showOperatorFilter = true;
              this.showOperatorColumn = true;
            }
            break;
          case 'Adverse Event Severity':
            this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Adverse event severity").subscribe(data => this.populatePicklistItems(data));
            break;
          case 'Regitry':
            this.showExtractReport = true;
            break;
          case 'Hospital':
            this.showExtractReport = true;
            break;

            
        }

      })

    }

  }

  clearReports() {
    this.showReport = false;
    this.lookup.reportSelected = '';


  }

  clearFilters() {
    this.showDateRangeFilter = false;
    this.showAgeRangeFilter = false;
    this.showCaseTypeFilter = false;
    this.showVolumeTimeIntervalFilter = false;
    this.showWeightFilter = false;
    this.showOperatorFilter = false;
    this.showAdverseEventSeverityFilter = false;
    this.showExtractReport = false;
    this.reviewStatus = false;
    
    this.lookup.CaseType = '';
    this.lookup.AgeRange = '';
    this.lookup.TimeInterval = '';
    this.lookup.Operator = 'All';
    this.lookup.AdverseEventSeveritys = 'All';
    this.lookup.siteSelected = '';
    this.lookup.RegistrySelected = '';
    this.lookup.Reviewed = false;
   
    this.fromWeight = null;
    this.toWeight = null;
    this.fromDate = null;
    this.toDate = null;
    this.data == null;
    

  }
  
  onChartRendered() {

    this.spinner.hide();  

  }

//need to pass both a reference to the menu
//and event args from the HTML
//use #menu in the wj-menu tag and $event in the function parmeter
//(itemClicked)="menuItemClicked(menu, $event)"
  //menuItemClicked(sender: any, e: EventArgs ) {
  //  let menuItem = sender.selectedValue;
  //  if (menuItem == 'Stacked100pc' || menuItem == 'Stacked') {
  //    this.spinner.show();
  //  }   
  //}

    changeLegend() {
  
    //if (window.innerWidth < 768) {
    //  this.dateRange.changeLegend("bottom");
    //  this.characteristics.changeLegend("bottom");
    //} else {
    //  this.dateRange.changeLegend("right");
    //  this.characteristics.changeLegend("bottom");
    //}
    //if (this.charts) {
    //  for (let chart of this.charts._results) {
    //    chart.ngOnChanges({});
    //  }
    //}
    }

  


 

 
  
  
}
