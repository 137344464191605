import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { HomeComponentC3POR3 } from "./components/home/home.componentC3POR3";
import { HomeComponentIQIC } from "./components/home/home.componentIQIC";
import { BackgroundComponent } from "./components/background/background.component";
import { C3poIQICComponent } from "./components/background/iqic/c3po-iqic.component";
import { C3poCharmComponent } from "./components/background/c3po-charm/c3po-charm.component";
import { C3poQiComponent } from "./components/background/c3po-qi/c3po-qi.component";
import { C3poR3Component } from "./components/background/c3po-r3/c3po-r3.component";
import { SitesComponent } from "./components/sites/sites.component";
import { IqicBackgroundComponent } from "./components/iqic-background/iqic-background.component";
import { MissionVisionComponent } from "./components/iqic-background/mission-vision/mission-vision.component";
import { BackgroundHistoryComponent } from "./components/iqic-background/background-history/background-history.component";
import { EnrolledPartnersComponent } from "./components/iqic-background/enrolled-partners/enrolled-partners.component";
import { KeyDriverComponent } from "./components/iqic-background/key-driver/key-driver.component";
import { WebinarsComponent } from "./components/iqic-background/webinars/webinars.component";
import { CathChatComponent } from "./components/iqic-background/cathchat/cathchat.component";
import { DatabaseManualComponent } from "./components/iqic-background/database-manual/database-manual.component";
import { CalculatorComponent } from "./components/calculator/calculator.component";
import { ContactComponent } from "./components/contact/contact.component";
import { LoginComponent } from "./components/login/login.component";
import { UserMenuComponent } from "./components/user/user-menu/user-menu.component";
import { AddCaseComponent } from "./components/user/add-case/add-case.component";
import { EditCaseComponent } from "./components/user/add-case/edit-case.component";
import { PreCalcComponent } from "./components/user/add-case/pre-calc/pre-calc.component";
import { CaseIdComponent } from "./components/user/add-case/case-id/case-id.component";
import { ClinicalCharacteristicsComponent } from "./components/user/add-case/clinical-characteristics/clinical-characteristics.component";
import { ProcedureCharacteristicsComponent } from "./components/user/add-case/procedure-characteristics/procedure-characteristics.component";
import { ProceduralEfficacyComponent } from "./components/user/add-case/procedural-efficacy/procedural-efficacy.component";
import { HemodynamicsComponent } from "./components/user/add-case/hemodynamics/hemodynamics.component";
import { CaseTypesComponent } from "./components/user/add-case/case-types/case-types.component";
import { AdverseEventsComponent } from "./components/user/add-case/adverse-events/adverse-events.component";
import { IndicatorsComponent } from "./components/user/add-case/indicators/indicators.component";
import { EocComponent } from "./components/user/add-case/eoc/eoc.component";
import { CaseLookupComponent } from "./components/user/case-lookup/case-lookup.component";
import { SurCaseLookupComponent } from "./components/user/surgerycase-lookup/surcase-lookup.component";
import { ManageReportsComponent } from "./components/user/manage-reports/manage-reports.component";
import { ForgotPasswordComponent } from "./components/login/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./components/login/change-password/change-password.component";
import { ManageUsersComponent } from "./components/user/manage-users/manage-users.component";
import { AddSurgeryCaseComponent } from "./components/user/add-surgery-case/add-surgery-case.component";
//import { EditSurgeryCaseComponent } from "./components/user/add-surgery-case/edit-surgery-case.component";
import { SurgeryCaseIdComponent } from "./components/user/add-surgery-case/surgery-case-id/surgery-case-id.component";
import { UniqueIdComponent } from "./components/user/add-surgery-case/unique-id/unique-id.component";
import { SurgeryClinicalCharComponent } from "./components/user/add-surgery-case/surgery-clinical-char/surgery-clinical-char.component";
import { MedicalHistoryComponent } from "./components/user/add-surgery-case/medical-history/medical-history.component";
import { SurgeryCaseTypeComponent } from "./components/user/add-surgery-case/surgery-case-type/surgery-case-type.component";
import { SurgeryEventsComponent } from "./components/user/add-surgery-case/surgery-events/surgery-events.component";
import { Day30StatusComponent } from "./components/user/add-surgery-case/day-30-status/day-30-status.component";
import { ClinicalOutcomesComponent } from './components/user/add-surgery-case/clinical-outcomes/clinical-outcomes.component';
import { C3poComponent } from "./components/background/c3po/c3po.component";
import { MissingFieldsComponent } from "./components/user/add-case/missing-fields/missing-fields.component";
import { PublicationsComponent } from "./components/publications/publications.component";
import { RouterService } from "./components/navigation/router.service";
import { ProceduralEfficacySurgeryComponent } from "./components/user/add-surgery-case/procedural-efficacy/procedural-efficacy.component";
import { CanDeactivateGaurd } from "./CanDeactivateGaurd";
import { SurgeryMissingFieldsComponent } from "./components/user/add-surgery-case/surgery-missing-fields/surgery-missing-fields.component";
import { EditSurgeryCaseComponent } from "./components/user/add-surgery-case/edit-surgery-case.component";
import { CanSurgeryCaseDeactivateGaurd } from "./CanSuregeryCaseDeactivateGaurd";
import { SurgeryContactComponent } from "./components/surgerycontact/surgerycontact.component";
import { AuthenticationGaurd } from "./AuthenticationGaurd";

export const routes: Routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },

    {
      path: "home",
      pathMatch: "prefix",
      component: HomeComponentC3POR3,
      data: { title: "Home" }
    },

    {
        path: "about",
        children: [
            { path: "", redirectTo: "background", pathMatch: "full" },
            { path: "", redirectTo: "backgroundIQIC", pathMatch: "full" },
            {
                path: "backgroundIQIC",
                component: C3poIQICComponent,

                data: { title: "Background" },
            },
            {
                path: "background",
                component: BackgroundComponent,

                data: { title: "Background" },
                children: [
                    {
                        path: "",
                        redirectTo: "c3po",
                        pathMatch: "full",
                        data: { title: "Background" }
                    },
                    {
                        path: "c3po",
                        component: C3poComponent,
                        pathMatch: "full",
                        data: {
                            title: "Background",
                            next: "/about/background/c3po-charm",
                            prev: "/about/background/c3po-r3"
                        }
                    },
                    {
                        path: "c3po-charm",
                        component: C3poCharmComponent,
                        pathMatch: "full",
                        data: {
                            title: "Background",
                            next: "/about/background/c3po-qi",
                            prev: "/about/background/c3po"
                        }
                    },
                    {
                        path: "c3po-qi",
                        component: C3poQiComponent,
                        pathMatch: "full",
                        data: {
                            title: "Background",
                            next: "/about/background/c3po-r3",
                            prev: "/about/background/c3po-charm"
                        }
                    },
                    {
                        path: "c3po-r3",
                        component: C3poR3Component,
                        pathMatch: "full",
                        data: {
                            title: "Background",
                            next: "/about/background/c3po",
                            prev: "/about/background/c3po-qi"
                        }
                    }
                ]
            },
            {
                path: "sites",
                component: SitesComponent,
                data: { title: "Participating Sites" }
            }
        ]
    },

    {
        path: "aboutIQIC",
        children: [
            { path: "", redirectTo: "Iqic-background", pathMatch: "full" },
            {
                path: "Iqic-background",
                component: IqicBackgroundComponent,
                data: { title: "IQIC Background" },
                children: [
                    {
                        path: "",
                        redirectTo: "mission-vision",
                        pathMatch: "full",
                        data: { title: "Background" }
                    },
                    {
                        path: "mission-vision",
                        component: MissionVisionComponent,
                        pathMatch: "full",
                        data: {
                            title: "Mission and Vision",
                            next: "/aboutIQIC/Iqic-background/background-history",
                            prev: "/aboutIQIC/Iqic-background/database-manual"
                        }
                    },
                    {
                        path: "background-history",
                        component: BackgroundHistoryComponent,
                        pathMatch: "full",
                        data: {
                            title: "Background",
                            next: "/aboutIQIC/Iqic-background/enrolled-partners",
                            prev: "/aboutIQIC/Iqic-background/mission-vision"
                        }
                    },
                    {
                        path: "enrolled-partners",
                        component: EnrolledPartnersComponent,
                        pathMatch: "full",
                        data: {
                            title: "Enrolled Partners",
                            next: "/aboutIQIC/Iqic-background/key-driver",
                            prev: "/aboutIQIC/Iqic-background/background-history"
                        }
                    },
                    {
                        path: "key-driver",
                        component: KeyDriverComponent,
                        pathMatch: "full",
                        data: {
                            title: "Key Driver",
                            next: "/aboutIQIC/Iqic-background/webinars",
                            prev: "/aboutIQIC/Iqic-background/enrolled-partners"
                        }
                    },
                    {
                        path: "webinars",
                        component: WebinarsComponent,
                        pathMatch: "full",
                        data: {
                            title: "Webinars",
                            next: "/aboutIQIC/Iqic-background/cathchat",
                            prev: "/aboutIQIC/Iqic-background/key-driver"
                        }
                    },
                    {
                        path: "cathchat",
                        component: CathChatComponent,
                        pathMatch: "full",
                        data: {
                            title: "cathchat",
                            next: "/aboutIQIC/Iqic-background/database-manual",
                            prev: "/aboutIQIC/Iqic-background/webinars"
                        }
                    },
                    {
                        path: "database-manual",
                        component: DatabaseManualComponent,
                        pathMatch: "full",
                        data: {
                            title: "databse-manual",
                            next: "/aboutIQIC/Iqic-background/mission-vision",
                            prev: "/aboutIQIC/Iqic-background/cathchat"
                        }
                    }
                ]
            }
        ]
    },

    {
        path: "calculator",
        component: CalculatorComponent,

        data: { title: "Calculator" }
    },
    {
        path: "contact",
        component: ContactComponent,

        data: { title: "Contact" }
  },
  {
    path: "surgerycontact",
    component: SurgeryContactComponent,

    data: { title: "Contact" }
  },
    {
        path: "publications",
        component: PublicationsComponent,

        data: { title: "Publications" }
    },
    {
        path: "login",
        children: [
            {
                path: "",
                pathMatch: "full",
                data: { title: "Login" },
                component: LoginComponent
            },
            {
                path: "forgot-password",
                pathMatch: "full",
                component: ForgotPasswordComponent,
                data: { title: "Forgot Password" }
            },
            {
                path: "change-password",
                pathMatch: "full",
                component: ChangePasswordComponent,
                data: { title: "Change Password" }
            }
        ]
    },
    {
        path: "change-password",
        children: [
            {
                path: "",
                pathMatch: "full",
                component: ChangePasswordComponent,
                data: { title: "Change Password" }
            }
        ]
    },

    {
        path: "user-portal",

        children: [
            { path: "", redirectTo: "user-menu", pathMatch: "full" },
            { path: "add-case", redirectTo: "add-case/pre-calc", pathMatch: "full" },
            { path: "add-case/iqic", redirectTo: "add-case/pre-calc", pathMatch: "full" },
            {
                path: "user-menu",
                pathMatch: "full",

                component: UserMenuComponent,
                data: { title: "User Home" }
            },
            {
                path: "add-case",
                component: AddCaseComponent,

                data: { title: "Add Case" },
                children: [
                    {
                        path: "pre-calc",
                        component: PreCalcComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/case-id",
                            prev: "./"
                        }
                    },
                    {
                        path: "case-id",
                        component: CaseIdComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/clinical-characteristics",
                            prev: "/user-portal/add-case/pre-calc"
                        }
                    },
                    {
                        path: "clinical-characteristics",
                        component: ClinicalCharacteristicsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/procedure-characteristics",
                            prev: "/user-portal/add-case/case-id"
                        }
                    },
                    {
                        path: "procedure-characteristics",
                        component: ProcedureCharacteristicsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/hemodynamics",
                            prev: "/user-portal/add-case/clinical-characteristics"
                        }
                    },
                    {
                        path: "hemodynamics",
                        component: HemodynamicsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/case-types",
                            prev: "/user-portal/add-case/procedure-characteristics"
                        }
                    },
                    {
                        path: "case-types",
                        component: CaseTypesComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/procedural-efficacy",
                            prev: "/user-portal/add-case/hemodynamics"
                        }
                    },
                    {
                        path: "procedural-efficacy",
                        component: ProceduralEfficacyComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/adverse-events",
                            prev: "/user-portal/add-case/case-types"
                        }
                    },


                    {
                        path: "adverse-events",
                        component: AdverseEventsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/indicators",
                            prev: "/user-portal/add-case/procedural-efficacy"
                        }
                    },
                    {
                        path: "indicators",
                        component: IndicatorsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/eoc",
                            prev: "/user-portal/add-case/adverse-events"
                        }
                    },
                    {
                        path: "eoc",
                        component: EocComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Add Case",
                            next: "/user-portal/add-case/pre-calc",
                            prev: "/user-portal/add-case/indicators"
                        }
                    },
                    {
                        path: "save",
                        component: MissingFieldsComponent,
                        data: {
                            title: "Add Case",
                            next: "./",
                            prev: "/user-portal/add-case/eoc"
                        }
                    }

                ]
            },
            {
                path: "edit-case",
                component: EditCaseComponent,

                data: { title: "Edit Case" },
                children: [
                    { path: "edit-case", redirectTo: "edit-case/pre-calc", pathMatch: "full" },
                    {
                        path: "pre-calc/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: PreCalcComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/case-id",
                            prev: "/user-portal/edit-case/save"
                        }
                    },
                    {
                        path: "case-id/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: CaseIdComponent,
                        data: {
                            title: "Edit Case",

                            next: "/user-portal/edit-case/clinical-characteristics",
                            prev: "/user-portal/edit-case/pre-calc"
                        }
                    },
                    {
                        path: "clinical-characteristics/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: ClinicalCharacteristicsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/procedure-characteristics",
                            prev: "/user-portal/edit-case/case-id"
                        }
                    },
                    {
                        path: "procedure-characteristics/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: ProcedureCharacteristicsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/hemodynamics",
                            prev: "/user-portal/edit-case/clinical-characteristics"
                        }
                    },
                    {
                        path: "hemodynamics/:id",
                        component: HemodynamicsComponent,
                        canDeactivate: [CanDeactivateGaurd],
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/case-types",
                            prev: "/user-portal/edit-case/procedure-characteristics"
                        }
                    },
                    {
                        path: "case-types/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: CaseTypesComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/procedural-efficacy",
                            prev: "/user-portal/edit-case/hemodynamics"
                        }
                    },
                    {
                        path: "procedural-efficacy/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: ProceduralEfficacyComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/adverse-events",
                            prev: "/user-portal/edit-case/case-types"
                        }
                    },

                    {
                        path: "adverse-events/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: AdverseEventsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/indicators",
                            prev: "/user-portal/edit-case/procedural-efficacy"
                        }
                    },
                    {
                        path: "indicators/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: IndicatorsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/eoc",
                            prev: "/user-portal/edit-case/adverse-events"
                        }
                    },
                    {
                        path: "eoc/:id",
                        canDeactivate: [CanDeactivateGaurd],
                        component: EocComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/pre-calc",
                            prev: "/user-portal/edit-case/indicators"
                        }
                    },
                    {
                        path: "save/:id",
                        component: MissingFieldsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/case-id",
                            prev: "/user-portal/edit-case/eoc"
                        }
                    },
                    {
                        path: "post-save/:id",
                        component: MissingFieldsComponent,
                        data: {
                            title: "Edit Case",
                            next: "/user-portal/edit-case/case-id",
                            prev: "/user-portal/edit-case/eoc"
                        }
                    }
                ]
            },
            {
                path: "case-lookup",
                component: CaseLookupComponent,
                data: { title: "Case Lookup" },
                pathMatch: "full"
          },
          {
            path: "surcase-lookup",
            component: SurCaseLookupComponent,
            data: { title: "Surgery Case Lookup" },
            pathMatch: "full"
          },
            {
                path: "manage-reports",
                component: ManageReportsComponent,
                data: { title: "Manage Reports" },
                pathMatch: "full"
            },
            {
                path: "manage-users",
                component: ManageUsersComponent,
                data: { title: "Manage Users" },
                pathMatch: "full"
            },
            {
                path: "add-surgery-case",
                component: AddSurgeryCaseComponent,
                data: { title: "Add Surgery Case" },
                children: [
                    {
                        path: "unique-id",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: UniqueIdComponent,
                        data: {
                            title: "Surgery Unique Id",
                            next: "/user-portal/add-surgery-case/surgery-case-id",
                            prev: "./"
                        }
                    },
                    {
                        path: "surgery-case-id",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: SurgeryCaseIdComponent,
                        data: {
                            title: "Surgery Case Id",
                            next: "/user-portal/add-surgery-case/surgery-clinical-char",
                            prev: "/user-portal/add-surgery-case/unique-id"
                        }
                    },
                    {
                        path: "surgery-clinical-char",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: SurgeryClinicalCharComponent,
                        data: {
                            title: "Surgery Clinical Characteristics",
                            next: "/user-portal/add-surgery-case/medical-history",
                            prev: "/user-portal/add-surgery-case/surgery-case-id"
                        }
                    },
                    {
                        path: "medical-history",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: MedicalHistoryComponent,
                        data: {
                            title: "Medical History",
                            next: "/user-portal/add-surgery-case/surgery-case-type",
                            prev: "/user-portal/add-surgery-case/surgery-clinical-char"
                        }
                    },
                    {
                        path: "surgery-case-type",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: SurgeryCaseTypeComponent,
                        data: {
                            title: "surgery case type",
                            next: "/user-portal/add-surgery-case/surgery-events",
                            prev: "/user-portal/add-surgery-case/medical-history"
                        }
                    },
                    {
                        path: "surgery-events",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: SurgeryEventsComponent,
                        data: {
                            title: "surgery events",
                            next: "/user-portal/add-surgery-case/clinical-outcomes",
                            prev: "/user-portal/add-surgery-case/surgery-case-type"
                        }
                    },
                    {
                        path: "day-30-status",
                        canActivate: [AuthenticationGaurd],
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: Day30StatusComponent,
                        data: {
                            title: "30 Day Status",
                            next: "/user-portal/add-surgery-case/procedural-efficacy",
                            prev: "/user-portal/add-surgery-case/clinical-outcomes"
                        }
                    },
                    {
                        path: "clinical-outcomes",
                        canActivate: [AuthenticationGaurd], 
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: ClinicalOutcomesComponent,
                        data: {
                            title: "Clinical Outcomes",
                            next: "/user-portal/add-surgery-case/day-30-status",
                            prev: "/user-portal/add-surgery-case/surgery-events"
                        }
                    },
                    {
                        path: "procedural-efficacy",
                        canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                        component: ProceduralEfficacySurgeryComponent,
                        data: {
                            title: "Procedural Efficacy",
                            next: "/user-portal/add-surgery-case/save",
                            prev: "/user-portal/add-surgery-case/day-30-status"
                        }
                  },
                  {
                      path: "save",
                      component: SurgeryMissingFieldsComponent,
                      data: {
                        title: "Add Surgery Case",
                        next: "/user-portal/add-surgery-case/case-id",
                        prev: "/user-portal/add-surgery-case/procedural-efficacy"
                      }
                  }
                ]
          },
          {
            path: "edit-surgery-case",
            component: EditSurgeryCaseComponent,

            data: { title: "Edit Surgery Case" },
            children: [
              { path: "edit-surgery-case", redirectTo: "edit-surgery-case/unique-id", pathMatch: "full" },
              {
                path: "unique-id/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: UniqueIdComponent,
                data: {
                  title: "Edit Surgery Case",
                  next: "/user-portal/edit-surgery-case/surgery-case-id",
                  prev: "/user-portal/edit-surgery-case/save"
                }
              },
              {
                path: "surgery-case-id/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: SurgeryCaseIdComponent,
                data: {
                  title: "Edit Surgery Case",
                  next: "/user-portal/edit-surgery-case/surgery-clinical-char",
                  prev: "/user-portal/edit-surgery-case/unique-id"
                }
              },
              {
                path: "surgery-clinical-char/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: SurgeryClinicalCharComponent,
                data: {
                  title: "Edit Surgery Case",
                  next: "/user-portal/edit-surgery-case/medical-history",
                  prev: "/user-portal/edit-surgery-case/surgery-case-id"
                }
              },
              {
                path: "medical-history/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: MedicalHistoryComponent,
                data: {
                  title: "Medical History",
                  next: "/user-portal/edit-surgery-case/surgery-case-type",
                  prev: "/user-portal/edit-surgery-case/surgery-clinical-char"
                }
              },
              {
                path: "surgery-case-type/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: SurgeryCaseTypeComponent,
                data: {
                  title: "surgery case type",
                  next: "/user-portal/edit-surgery-case/surgery-events",
                  prev: "/user-portal/edit-surgery-case/medical-history"
                }
              },
              {
                path: "surgery-events/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: SurgeryEventsComponent,
                data: {
                  title: "surgery events",
                  next: "/user-portal/edit-surgery-case/clinical-outcomes",
                  prev: "/user-portal/edit-surgery-case/surgery-case-type"
                }
              },
              {
                path: "clinical-outcomes/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: ClinicalOutcomesComponent,
                data: {
                  title: "Clinical Outcomes",
                  next: "/user-portal/edit-surgery-case/day-30-status",
                  prev: "/user-portal/edit-surgery-case/surgery-events"
                }
              },

              {
                path: "day-30-status/:id",
               // canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: Day30StatusComponent,
                data: {
                  title: "30 Day Status",
                  next: "/user-portal/edit-surgery-case/procedural-efficacy",
                  prev: "/user-portal/edit-surgery-case/clinical-outcomes"
                }
              },
              {
                path: "procedural-efficacy/:id",
                canDeactivate: [CanSurgeryCaseDeactivateGaurd],
                component: ProceduralEfficacySurgeryComponent,
                data: {
                  title: "Procedural Efficacy",
                  next: "/user-portal/edit-surgery-case/save",
                  prev: "/user-portal/edit-surgery-case/day-30-status"
                }
              },
              {
                path: "save/:id",
                component: SurgeryMissingFieldsComponent,
                data: {
                  title: "Edit Surgery Case",
                  next: "/user-portal/edit-surgery-case/case-id",
                  prev: "/user-portal/edit-surgery-case/procedural-efficacy"
                }
              },
              {
                path: "post-save/:id",
                component: SurgeryMissingFieldsComponent,
                data: {
                  title: "Edit Surgery Case",
                  next: "/user-portal/edit-surgery-case/case-id",
                  prev: "/user-portal/edit-surgery-case/procedural-efficacy"
                }
              }
            ]
          }
        ]
    },

    { path: "**", redirectTo: "homeC3PO" }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    useHash: true
});
