export class SurCaseMedicalHistInfoModel {
  SurCaseMedHistId: number;
  SurCaseId: number;
  CardiacDiagCodes: string;
  RheumeticFeverInd: string;
  GenericSyndInd: string;
  GenericSyngVal: string;
  MajorNcsAnomalyInd: string;
  MajorNcsAnomalyVal: string;
  MajorNcMedIllnessInd: string;
  MajorNcMedIllnessVal: string;
  PrevCardSurgInd: string;
  PrevCardSurgeries: string;
  PrevCardCathInd: string;
  PreoprBakatriBSInd: string;
  PreoprResuscitBSInd: string;
  PreoprInotheraBSInd: string;
  PreoprVentpreopInd: string;
  Updatedby: string;
}
