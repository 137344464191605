import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { fadeAnimation } from "../../../animations/fade.animation";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent
} from "@angular/router";

import { filter, map, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { RouterHelper } from "../../../router-helper";
import { CaseFieldsService } from "./case-fields.service";
import { LoginService } from "../login.service";
import { CaseService } from "./case.service";

import { NgForm } from "@angular/forms";

@Component({
  selector: "app-add-case", 
  templateUrl: "./add-case.component.html",
  styleUrls: ["./add-case.component.scss"],
  animations: [fadeAnimation]
})
export class AddCaseComponent   implements OnInit, OnDestroy {
  @ViewChild('addform')
  form: NgForm;
  nextRoute;
  prevRoute;
  fields;
  routerHelper: RouterHelper;
  user: LoginService;
  caseService: CaseService;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, fields: CaseFieldsService, _user: LoginService, _caseService: CaseService,) {
   
    this.user = _user;
    this.caseService = _caseService;
    this.routerHelper = new RouterHelper(router, activatedRoute);
    this.routerHelper
      .getRouteData(this.router.events)
      .pipe(
        map(data => {
        if (data["next"]) {
          //if (data["next"].indexOf("efficacy") >= 0)
          //{
          //  if (this.user.IsIqicUser()) { return data["next"]; }
          //  else
          //  {
          //    return "//user-portal//add-case//adverse-events";
          //  }
          //}
          //else
          //{
            return data["next"];
          //}
            
          }
        })
      )
      .subscribe(pathString => {
        this.nextRoute = pathString;
      });
      this.routerHelper
      .getRouteData(this.router.events)
      .pipe(
        map(data => {
        //if (data["prev"].indexOf("efficacy") >= 0) {
        //  if (this.user.IsIqicUser()) { return data["prev"]; }
        //  else {
        //    return "//user-portal//add-case//case-types";
        //  }
        //}
        //else {
          return data["prev"];
        //}
        })
      )
      .subscribe(pathString => {
        this.prevRoute = pathString;
      });
    this.fields = fields;
    this.fields.anticipatedCase = false;
  }

  ngOnInit() {
    let caseId = this.fields.getField("Case Identification", 'Case Number').value;
    if (caseId != null) {
      this.fields.type = "add";
      this.fields.resetFields();      
    }
    this.fields.setValue("Case Identification", "Case Number", 0);
    this.fields.caseLoaded = true;
    this.fields.checked6Hours = false;
    this.fields.checked24Hours = false;
    this.fields.checked48Hours = false;
  }

  ngOnDestroy() {
    this.fields.resetFields();
  }

  saveCase() {
    this.caseService.route = "addcase"
    
  }
}
