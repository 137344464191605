import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { fadeAnimation } from "../../../animations/fade.animation";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterEvent
} from "@angular/router";

import { filter, map, mergeMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { RouterHelper } from "../../../router-helper";
import { LoginService } from "../../user/login.service";
import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../shared/picklist-item-response';
import { PicklistItemModel } from '../../../shared/picklist-item-model';
import { DropdownItemModel } from '../../../shared/dropdown-item-model';
import { SurgeryCaseFieldsService } from './surgery-case-fields.service';
import { SurCaseService } from "./surcase.service";

import { NgForm } from "@angular/forms";

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-add-surgery-case',
  templateUrl: './add-surgery-case.component.html',
  styleUrls: ['./add-surgery-case.component.scss'],
  host: { 'class': "host" }
})
export class AddSurgeryCaseComponent implements OnInit {
  @ViewChild('addform')
  form: NgForm;
  nextRoute;
  prevRoute;
  fields: SurgeryCaseFieldsService;
  routerHelper: RouterHelper;
  user: LoginService;
  surcaseService: SurCaseService;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, _fields: SurgeryCaseFieldsService, _user: LoginService, _surcaseService: SurCaseService) {
    this.user = _user;
    this.surcaseService = _surcaseService;
    this.routerHelper = new RouterHelper(router, activatedRoute);
    this.fields = _fields;
    this.routerHelper
      .getRouteData(this.router.events)
      .pipe(

        map(data => {
          if (data["next"]) {
            return data["next"];
          }
        })
      )
      .subscribe(pathString => {
        this.nextRoute = pathString;
      });


    this.routerHelper
      .getRouteData(this.router.events)
      .pipe(
        map(data => {
          return data["prev"];
        })
      )
      .subscribe(pathString => {
        this.prevRoute = pathString;
      });
  }

  ngOnInit() {
    let surcaseId = this.fields.getField("Case Identification", "Case Number").value;
    //if (surcaseId != null) {
      this.fields.type = "add";
      this.fields.resetFields();
   // }
    this.fields.setValue("Case Identification", "Case Number", 0);
    this.fields.caseLoaded = true;
  }
  ngOnDestroy() {
    //this.fields.resetFields();
  }
  saveCase() {
   // this.caseService.route = ""
    this.surcaseService.route = "addsurgerycase"


  }
}
