
import { Component, OnInit, ViewChild, ElementRef, HostListener } from "@angular/core";
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { SurProcedurePicklistService } from "../../../shared-data-entry/surprocedure-picklist.service";
import { SurProcedurePicklistResponse } from '../../../../shared/iqis/surprocedure-picklist-response';
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { LoginService } from "../../../user/login.service";
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
declare var jquery: any;
declare var $: any;
@Component({
  selector: "app-surgery-case-type",
  templateUrl: "./surgery-case-type.component.html",
  styleUrls: ["./surgery-case-type.component.scss"],
  host: { class: "host" }
})
export class SurgeryCaseTypeComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  fields: SurgeryCaseFieldsService;
  name = "Surgical Procedures";
  text = "Performed Surgical Procedures";
  public surProcedurePicklistService: SurProcedurePicklistService;
  events = [];
  toAdd: any = {};
  // recordedEvents = {};
  // recordedArray = [];
  showEvents: boolean = false;
  options = {
    animateExpand: true,
    animateAcceleration: 1.2,
    scrollOnActivate: true,
    animateSpeed: 10
  };
  constructor(private router: Router,_surProcedurePicklistService: SurProcedurePicklistService, fields: SurgeryCaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.fields = fields;
    this.surProcedurePicklistService = _surProcedurePicklistService;
    this.surProcedurePicklistService.getProcedurePicklist().subscribe(data => this.populateProcedurePicklist(data));
  }

  populateProcedurePicklist(otherAEResponse: SurProcedurePicklistResponse) {
    this.surProcedurePicklistService.procedureList =
      otherAEResponse.DataObject;
    this.surProcedurePicklistService.mapToDropDown();
    this.events = this.surProcedurePicklistService.dropDownProcedureList;
  }


  ngOnInit() {
    localStorage.removeItem('surgerycasetypedirty');
    let hasEvents = this.fields.getField(this.name, "Surgical Procedures Performed") != null;
    localStorage.setItem('surgicalprocedures', JSON.stringify(this.fields.getField(this.name, "Recorded Events").value))

    //this.fields.getParent(this.name)["hide"] = !hasEvents;
    this.showEvents = hasEvents;
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('surgerycasetypedirty', 'true');

    if (this.fields.getField(this.name, "Recorded Events").value !== null && localStorage.getItem('surgicalprocedures') !== JSON.stringify(this.fields.getField(this.name, 'Recorded Events').value))
      localStorage.setItem('surgerycasetypedirty', 'true');


    return !this.form.dirty;
  }
  addItem(name, item) {
    this.fields.getField(this.name, "Recorded Events").value[name + ""] = item;

    let unordered = this.fields.getField(this.name, "Recorded Events").value;
    let ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    this.fields.getField(this.name, "Recorded Events").value = ordered;
  }
  //deletes a selected procedure
  deleteProcedure(event, name) {
    let savedEvents = this.getEvents();
    delete savedEvents[name];
    let arr = Object.getOwnPropertyNames(savedEvents);

    if (arr.length == 0) {
      savedEvents = null;
      this.fields.getField(this.name, "Recorded Events").value = null;
    }
  }
  getEvents() {
    return this.fields.getField(this.name, "Recorded Events").value;
  }
  //sets the recorded events
  setEvents(val) {
    this.fields.getField(this.name, "Recorded Events").value = val;
  }
  //places a selected event in the editable fields
  editProcedure($event, name) {
    this.toAdd = this.getEvents()[name];
  }  
  //produces the selected key string for a particular procedure
  static getKeyString(node, tree) {
    //all procedure names are parsed as
    let text = "";
    if (node && node.path) {
      let path = node.path;
      for (let id of path) {
        let parent = tree.treeModel.getNodeById(id);
        if (text !== "") {
          text = text + " | " + parent.data.name;
        } else {
          text = text + parent.data.name;
        }
      }
    }
    return text;
  }

  createRecord(node, tree) {
    let procs = this.getEvents();
    let nodeString = SurgeryCaseTypeComponent.getKeyString(node, tree);
    procs[nodeString] = {
      id: "procedure-" + node.data.code
    };
    this.sortProcedures();
    return true;
  }
  //when selecting a leaf, creates a new procedure entry
  //otherwise, opens parent
  onSelect(event, tree) {
    let procs = this.getEvents();
    if (event.node.isLeaf) {

      //creates normal entry
      if (!procs) {
        this.setEvents({});
      }
      this.createRecord(event.node, tree);

    } else {
      //opens parent node
      event.node.toggleExpanded();
    }
  }

  sortProcedures() {
    let unordered = this.fields.getField(this.name, "Recorded Events").value;
    if (unordered != null) {
      let ordered = {};
      Object.keys(unordered).sort().forEach(function (key) {
        ordered[key] = unordered[key];
      });
      this.fields.getField(this.name, "Recorded Events").value = ordered;
    }
  }

  //gets the recorded procedures as an array
  getProceduresArray() {
    let procs = this.getEvents();
    if (procs) {
      return Object.getOwnPropertyNames(this.getEvents());
    } else {
      return [];
    }
  }

}
