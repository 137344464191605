export class CaseProcedureInfoModel {
  CaseProccharInfoId: number;
  CaseId: number;
  SheathCathInDateTime: string;
  SheathCathOutDateTime: string;
  FluroTime: number;
  TotalDap: string;
  BloodTransfusion: string;
  Updatedby: string;
}
