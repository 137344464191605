import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SurPicklistItemResponse } from '../../shared/iqis/surpicklist-item-response';
import { SurPicklistItemModel } from '../../shared/iqis/surpicklist-item-model';
import { SurPicklistLookupModel } from '../../shared/iqis/surpicklist-lookup-model';
import { SurDropdownItemModel } from '../../shared/iqis/surdropdown-item-model';
import {SURGERYCONFIG } from '../../shared/surgeryconfig';

let iqisapiUrl = SURGERYCONFIG.baseUrls.iqisapiurl;
@Injectable()
export class SurPicklistItemService {
  private http: Http = null;

  public picklistItemModel: SurPicklistItemModel[];
  public dropDownPicklistItem: SurDropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getPicklistItem(hospitalId: string, picklistName: string) {
    let picklookup = new SurPicklistLookupModel;
    picklookup.HospitalId = hospitalId;
    picklookup.PicklistName = picklistName;

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(iqisapiUrl + "picklist/GetPickListInfo", picklookup, options)
      .pipe(map((response: Response) => <SurPicklistItemResponse>response.json()));
  }

  

  mapToDropDown() {
    this.dropDownPicklistItem = [];

    this.picklistItemModel.sort(function (a, b) { return (a.DisplayOrder - b.DisplayOrder) });

    for (let entry of this.picklistItemModel) {
      let newItem = new SurDropdownItemModel;
      newItem.name = entry.ChoiceText;
      newItem.description = entry.PicklistName;
      newItem.code = entry.ChoiceCode;     
      this.dropDownPicklistItem.push(newItem);
    }
  }
}
