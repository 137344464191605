import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from "@angular/core";
import { SurgeryCaseFieldsService } from "../surgery-case-fields.service";
import { ComponentCanDeactivate } from "../../../../CanDeactivateGaurd";
import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-clinical-outcomes",
  templateUrl: "./clinical-outcomes.component.html",
  styleUrls: ["./clinical-outcomes.component.scss"],
  host: { 'class': "host" }
})
export class ClinicalOutcomesComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  public fields: SurgeryCaseFieldsService;
  public name = "Clinical Outcomes";
  deathInHospital: boolean = false;
  bacterialSepsis: boolean = false;
  surgicalSiteInfection: boolean = false;
  otherComplications: boolean = false;
  anyComplications: number = null;
  hospitalInfections: number = 0;
  ventilatorPneumonia: number = null;
  cathInfection: number = null;
  cathUTI: number = null;
  currentDate: Date = new Date();
  dODDescription: string = "";
  dOD: string = "";
  showTooltipText: boolean = false;
  showTooltipText2: boolean = false;
  showTooltipText3: boolean = false;
  showTooltipText4: boolean = false;
  showTooltipText5: boolean = false;
  allowAccessToRadio: boolean = false;
  allowAccessToComplicationsRadio: boolean = true;
  constructor(private router: Router, _fields: SurgeryCaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editsurgerycase', e.url);
      });
    this.fields = _fields;
    
  }

  ngAfterViewInit() {
    if (this.fields.getField(this.name, "HospitalInfections").value == 'No' || this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
      this.allowAccessToRadio = true
    }
    else {
      this.allowAccessToRadio = false
    }
  }

  ngOnInit() {
    localStorage.removeItem('clinicaloutcomedirty');
    if (this.fields.getField(this.name, "InHospDeath").value === 'No') {
      this.fields.getField(this.name, "dOD").hide = true;
      this.fields.getField(this.name, "dODDescription").hide = true;
    }
    if (this.fields.getField(this.name, "InHospDeath").value === 'Yes') {
      this.fields.getField(this.name, "dOD").hide = false;
      this.fields.getField(this.name, "dODDescription").hide = false;
    }

    if (this.fields.getField(this.name, "VentPneumonia").value === null) {
      if (this.fields.getField(this.name, "HospitalInfections").value === 'No') {
        this.fields.setValue(this.name, "VentPneumonia",'No')
      }
      if (this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
        this.fields.setValue(this.name, "VentPneumonia",'Not Collected')
      }
    }
    if (this.fields.getField(this.name, "CathInfection").value === null) {
      if (this.fields.getField(this.name, "HospitalInfections").value === 'No') {
        this.fields.setValue(this.name, "CathInfection", 'No')
      }
      if (this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
        this.fields.setValue(this.name, "CathInfection", 'Not Collected')
      }
    }
    if (this.fields.getField(this.name, "cathUTI").value === null) {
      if (this.fields.getField(this.name, "HospitalInfections").value === 'No') {
        this.fields.setValue(this.name, "cathUTI", 'No')
      }
      if (this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
        this.fields.setValue(this.name, "cathUTI", 'Not Collected')
      }
    }
   // this.allowAccessToRadio = true
    if (this.fields.getField(this.name, "HospitalInfections").value === 'No') {
      this.allowAccessToRadio = true
    }
    //if (this.fields.getField(this.name, "AnyComplications").value === 'No' || this.fields.getField(this.name, "AnyComplications").value === 'Not Collected') {
    //  this.allowAccessToComplicationsRadio = true
    //}

   // this.isAllowed();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.form.dirty)
      localStorage.setItem('clinicaloutcomedirty', 'true');
    return !this.form.dirty;
  }

  showTooltip(b: boolean) {
   
    this.showTooltipText = b;
  }
  showTooltip2(b: boolean) {

    this.showTooltipText2 = b;
  }
  showTooltip3(b: boolean) {

    this.showTooltipText3 = b;
  }
  showTooltip4(b: boolean) {

    this.showTooltipText4 = b;
  }
  showTooltip5(b: boolean) {

    this.showTooltipText5 = b;
  }


  toggleHospitalInfections(n: number) {
    this.hospitalInfections = n;
    if (n == 0 || n == 3) {
      this.cathInfection = this.ventilatorPneumonia = this.cathUTI = n;
    } else {
      this.cathInfection = this.ventilatorPneumonia = this.cathUTI = null;
    }
  }

  isAllowed() {
    if (this.fields.getField(this.name, "HospitalInfections").value === 'No' || this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
     return true
    }
    else
      return false
  }

  isCompAllowed() {
    if (this.fields.getField(this.name, "AnyComplications").value === 'No' || this.fields.getField(this.name, "AnyComplications").value === 'Not Collected') {
      return true
    }
    else
      return false
  }

  toggleTextbox(val) {
    if (val === 'VentPneumonia' && this.fields.getValue(this.name, val) !== 'Yes') {
      this.fields.setValue(this.name, "VentPneumoniaDescription", null);
    }
    if (val === 'CathInfection' && this.fields.getValue(this.name, val) !== 'Yes') {
      this.fields.setValue(this.name, "CathInfectionDescription", null);
    }
    if (val === 'cathUTI' && this.fields.getValue(this.name, val) !== 'Yes') {
      this.fields.setValue(this.name, "CathUTIDescription", null);
    }
  }
  toggleCheckbox(val) {
    if (val === 'InHospDeath' && this.fields.getValue(this.name, val) === 'No') {
      this.fields.setValue(this.name, "dOD", null);
      this.fields.setValue(this.name, "dODDescription", null);
    }

    if (val === 'InHospDeath' && this.fields.getValue(this.name, val) === 'Yes') {
      if (this.fields.getValue("Day 30 Status", 'IsPatientAlive') == 'true') {
        this.fields.setValue("Day 30 Status", 'IsPatientAlive', 'false');
        this.fields.setValue("Day 30 Status", "IsPatientDischarged", null);
        this.fields.setValue("Day 30 Status", "IsPatientDoingWell", null);
      }
    }

    if (val === 'BactSepsis' && this.fields.getValue(this.name, val) === 'No') {
      this.fields.setValue(this.name, "BactSepsisDescription", null);
    }
   
    if (val === 'SurgicalSiteInfection' && this.fields.getValue(this.name, val) === 'No') {
      this.fields.setValue(this.name, "SurgicalSiteInfectionType", null);
      this.fields.getField(this.name, "SurgicalSiteInfectionType").hide = true;
      this.fields.setValue(this.name, "SurSiteInfectionDescription", null);
    }
    if (this.fields.getField(this.name, "HospitalInfections").value === 'No') {
      this.fields.setValue(this.name, "VentPneumonia","No")
      this.fields.setValue(this.name, "CathInfection", "No")
      this.fields.setValue(this.name, "cathUTI", "No")
      this.fields.setValue(this.name, "VentPneumoniaDescription", null);
      this.fields.setValue(this.name, "CathInfectionDescription", null);
      this.fields.setValue(this.name, "CathUTIDescription", null);
      this.allowAccessToRadio = true
    }
    if (this.fields.getField(this.name, "HospitalInfections").value === 'Not Collected') {
      this.fields.setValue(this.name, "VentPneumonia", "Not Collected")
      this.fields.setValue(this.name, "CathInfection", "Not Collected")
      this.fields.setValue(this.name, "cathUTI", "Not Collected")
      this.fields.setValue(this.name, "VentPneumoniaDescription", null);
      this.fields.setValue(this.name, "CathInfectionDescription", null);
      this.fields.setValue(this.name, "CathUTIDescription", null);
      this.allowAccessToRadio = true
    }
    if (val === 'HospitalInfections' && this.fields.getField(this.name, "HospitalInfections").value === 'Yes') {
      this.fields.setValue(this.name, "VentPneumonia", null)
      this.fields.setValue(this.name, "CathInfection", null)
      this.fields.setValue(this.name, "cathUTI", null)
      this.allowAccessToRadio = false
    }

    

    //Complications
    if (this.fields.getField(this.name, "AnyComplications").value === 'No') {
      this.fields.setValue(this.name, "ReqAddSurgeryForBleeding", "No")
      this.fields.setValue(this.name, "ReIntubationIn24hrs", "No")
      this.fields.setValue(this.name, "ICUReAdmissionAfter24Hrs", "No")
      this.fields.setValue(this.name, "DiaphragmaticParalysis", "No")
      this.fields.setValue(this.name, "PostSurgicalHeartBlock", "No")
      this.fields.setValue(this.name, "Tracheostomy", "No")
      this.fields.setValue(this.name, "PostOperativeSeizures", "No")
      this.fields.setValue(this.name, "AcuteKidneyInjury", "No")
      this.fields.setValue(this.name, "OtherComplications", "No")
      this.allowAccessToComplicationsRadio = true
    }
    if (this.fields.getField(this.name, "AnyComplications").value === 'Not Collected') {
      this.fields.setValue(this.name, "ReqAddSurgeryForBleeding", "Not Collected")
      this.fields.setValue(this.name, "ReIntubationIn24hrs", "Not Collected")
      this.fields.setValue(this.name, "ICUReAdmissionAfter24Hrs", "Not Collected")
      this.fields.setValue(this.name, "DiaphragmaticParalysis", "Not Collected")
      this.fields.setValue(this.name, "PostSurgicalHeartBlock", "Not Collected")
      this.fields.setValue(this.name, "Tracheostomy", "Not Collected")
      this.fields.setValue(this.name, "PostOperativeSeizures", "Not Collected")
      this.fields.setValue(this.name, "AcuteKidneyInjury", "Not Collected")
      this.fields.setValue(this.name, "OtherComplications", "Not Collected")
      this.allowAccessToComplicationsRadio = true
    }
    if (this.fields.getField(this.name, "AnyComplications").value === 'Yes') {
      this.fields.setValue(this.name, "ReqAddSurgeryForBleeding", null)
      this.fields.setValue(this.name, "ReIntubationIn24hrs", null)
      this.fields.setValue(this.name, "ICUReAdmissionAfter24Hrs", null)
      this.fields.setValue(this.name, "DiaphragmaticParalysis", null)
      this.fields.setValue(this.name, "PostSurgicalHeartBlock", null)
      this.fields.setValue(this.name, "Tracheostomy", null)
      this.fields.setValue(this.name, "PostOperativeSeizures", null)
      this.fields.setValue(this.name, "AcuteKidneyInjury", null)
      this.fields.setValue(this.name, "OtherComplications", null)
      this.allowAccessToComplicationsRadio = false
    }
  }
}
