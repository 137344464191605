import { Component, OnInit, HostListener } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { LoginService } from "../login.service";

import { SurCaseLookupService } from "./surcase-lookup.service";
import { SearchSurcaseResponse } from '../../../shared/iqis/search-surcase-response';
import { SearchSurCaseModel } from '../../../shared/iqis/search-surcase-model';
import { SearchSurCaseSummaryModel } from '../../../shared/iqis/search-surcase-summary-model';

import { SurPicklistItemService } from '../../shared-data-entry/surpicklist-item.service';
import { SurPicklistItemResponse } from '../../../shared/iqis/surpicklist-item-response';
import { SurPicklistItemModel } from '../../../shared/iqis/surpicklist-item-model';

import { SurCaseService } from '../add-surgery-case/surcase.service';
import { SurgeryCaseFieldsService } from '../add-surgery-case/surgery-case-fields.service';

declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-case-lookup",
  templateUrl: "./surcase-lookup.component.html",
  styleUrls: ["./surcase-lookup.component.scss"]
})
export class SurCaseLookupComponent implements OnInit {
  public errorMessage = "";
  public ageOptions = [];

  public currentDate: Date = new Date();
  
  filter: string = "";
  key: string = "CaseId";
  reverse: boolean = false;
  p: number = 1;

  public lookup: SurCaseLookupService;
  public user: LoginService;
  public picklistItemService: SurPicklistItemService;
  public caseService: SurCaseService;
  public fields: SurgeryCaseFieldsService;
  constructor(_lookup: SurCaseLookupService, _user: LoginService, _picklistItemService:SurPicklistItemService, _caseService: SurCaseService, _fields: SurgeryCaseFieldsService) {
    this.user = _user;    
    this.caseService = _caseService;
    this.fields = _fields;
    this.fields.resetFields();
    this.lookup = _lookup;
    this.lookup.searchClear();
    this.lookup.LocationId = this.user.user.HospitalId;
   
    this.errorMessage = "";

    this.picklistItemService = _picklistItemService;
    this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "Age Range").subscribe(data => this.populatePicklistItems(data));
  }

  populatePicklistItems(picklistItemResponse: SurPicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.ageOptions = this.picklistItemService.dropDownPicklistItem;
  }

  sort(key) {
    if (this.key === key) {
      //key is the same, change reverse
      this.reverse = !this.reverse;
    } else {
      //key changed, default reverse
      this.key = key;
      this.reverse = false;
    }       
  }

  ngOnInit() {
    this.SearchCase();
  }

  SearchCase() {
    if ((this.lookup.SurCaseId == null || this.lookup.SurCaseId <= 0)
      && (this.lookup.UniqueIdentifier == null || this.lookup.UniqueIdentifier == '')
      && this.lookup.SurgDateFrom == null
      && this.lookup.SurgDateTo == null
      && this.lookup.AgeRange == ''
      && this.lookup.IncompleteDataOnly == false
      && this.lookup.CompleteDataOnly == false
      && this.lookup.CMPLThirtyDayStatusInd == false
      && this.lookup.ICMPLThirtyDayStatusInd == false) {
      this.lookup.SurCaseId = null;
      this.errorMessage = "Please enter at least one search criteria";
      this.lookup.searchCaseSummarys = [];
    } else {
      this.errorMessage = "";
      this.lookup.LocationId = this.user.user.HospitalId;
      this.lookup.searchCases().subscribe(data => this.validateSearch(data));
    }    
  }

  SearchClear() {
    this.lookup.searchClear();

    $("#case-number").val('');
    $("#cath-date-from").val('');
    $("#cath-date-to").val('');
  }

  caseIdCheck() {
    if (!this.lookup.SurCaseId) {
      $("#case-number").val('');
    }
  }
    UniqueIdentifierCheck() {
      if (!this.lookup.UniqueIdentifier) {
        $("#case-identifier").val('');
      }
  }

  dateCheck() {
    if (this.lookup.SurgDateFrom) {
      let millFrom = Date.parse(this.lookup.SurgDateFrom.toString());
      if (!(millFrom > 0)) {
        this.lookup.SurgDateFrom = null;
        $("#cath-date-from").val('');
      }
    } else {
      $("#cath-date-from").val('');
    }

    if (this.lookup.SurgDateTo) {
      let millTo = Date.parse(this.lookup.SurgDateTo.toString());
      if (!(millTo > 0)) {
        this.lookup.SurgDateTo = null;
        $("#cath-date-to").val('');
      } else if (this.isfutureDate(this.lookup.SurgDateTo)) {
        this.lookup.SurgDateTo = new Date();
      }
    } else {
      $("#cath-date-to").val('');
    }

    if ((this.lookup.SurgDateFrom && this.lookup.SurgDateTo) && (this.lookup.SurgDateFrom > this.lookup.SurgDateTo)) {
      let newFrom = this.lookup.SurgDateTo;
      let newTo = this.lookup.SurgDateFrom;

      this.lookup.SurgDateTo = newTo;
      this.lookup.SurgDateFrom = newFrom;
    }
  }


  inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[a-zA-Z0-9]*$/;

    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9]*$/g, "");
      // invalid character, prevent input

    }
  }

  validateSearch(sr: SearchSurcaseResponse) {
    if (sr.MessageType === "success") {
      this.p = 1;
      this.lookup.searchCaseSummarys = sr.DataObject;
    }
  }

  deleteCaseSet(caseId: number) {
    this.caseService.deleteCaseId = caseId;
  }

  private isfutureDate(target: Date) {
    let now = new Date;

    if (target.getFullYear() > now.getFullYear()) {
      return true;
    } else if (target.getFullYear() == now.getFullYear()) {
      if (target.getMonth() > now.getMonth()) {
        return true;
      } else if (target.getMonth() == now.getMonth()) {
        if (target.getDate() > now.getDate()) {
          return true;
        }
      }
    }

    return false;
  }
}
