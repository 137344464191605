import { Component, OnInit, ViewChild } from "@angular/core";
import { TreeComponent, TreeModel, TreeNode } from 'angular-tree-component';
import { CaseTypeTreeModel } from "../../shared-data-entry/case-types-entry/case-type-tree-model";
import { StsDiagnosisService } from "../../shared-data-entry/sts-diagnosis.service";
import { StsDiagnosisResponse } from '../../../shared/sts-diagnosis-response';
import { StsDiagnosisModel } from '../../../shared/sts-diagnosis-model';
import { CaseTypesEntryService } from "../../shared-data-entry/case-types-entry/case-types-entry.service";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";

@Component({
  selector: "app-sts-tree",
  templateUrl: "./sts-tree.component.html",
  styleUrls: ["./sts-tree.component.scss"]
})
export class StsTreeComponent implements OnInit {

  @ViewChild('tree2') treeComponent: TreeComponent;
  // selected = "";
  showClear: boolean = false;

  fields: CaseFieldsService;
  public stsDiagnosisService: StsDiagnosisService;
  constructor(fields: CaseFieldsService, _stsDiagnosisService: StsDiagnosisService, service: CaseTypesEntryService) {
    this.fields = fields;
    this.stsDiagnosisService = _stsDiagnosisService;
    if (this.stsDiagnosisService.stsDiagnosisList == null) {
      this.stsDiagnosisService.getStsDiagnosis().subscribe(data => this.populateStsDiagnosis(data));
    }

    let sel = this.fields.getField('Clinical Characteristics', 'STS').value;
    if (sel) {
      //this.selected = sel;
      this.showClear = true;
    }
  }
  populateStsDiagnosis(stsDiagResponse: StsDiagnosisResponse) {
    this.stsDiagnosisService.stsDiagnosisList = stsDiagResponse.DataObject;
    this.stsDiagnosisService.mapToDropDown();

    this.stsDiagnosisService.stsTreedata = this.stsDiagnosisService.dropDownStsDiagnosis;
  }
  onSelect(event, tree) {
    if (event.node.isLeaf) {
      // this.selected = event.node.data;
      this.fields.getField('Clinical Characteristics', 'STS').text = CaseTypeTreeModel.getKeyString(event.node, tree);
      this.fields.getField('Clinical Characteristics', 'STS').value = event.node;
      this.showClear = true;
    }
    else {
      event.node.toggleExpanded();
      event.node.setIsActive(false);

    }

  }

  clear() {
    this.showClear = false;
    //this.selected = "";
    this.fields.getField('Clinical Characteristics', 'STS').text = null;
    this.fields.getField('Clinical Characteristics', 'STS').value = null;
  }
  ngOnInit() {
    this.stsDiagnosisService.treeModel = this.treeComponent.treeModel;
  }
}
