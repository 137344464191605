import { Component, Injectable, Input, Output, EventEmitter } from '@angular/core';

@Injectable() export class SurdataSharedAttributesService  {

  suratrributes: any[] = [];
  constructor() {

  }
  savesuratrributes(attribs: any) {
    this.suratrributes = attribs;
  }
  getsurattributes() {
    return this.suratrributes;
  }
}
