import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class SurgeryCaseFieldsService {
  public type = "add";
  validate = false;
  validateDate = false;
  goodToSubmit = false;
  currentSeriousness = null;
  currentSeriousnessDisplay = null;
  caseLoaded: boolean = false;
  completedCase: boolean = false;
  fields = [];  

  //gets a particular field object based on its parent and name
  public getField(parent, name) {
    let parentFields: any = this.fields.find(x => x.name == parent)["fields"];
    let field = parentFields.find(x => x["name"] == name);

    return field;
  }

  //retrieves the value of a field
  getValue(parent, name) {
    return this.getField(parent, name)["value"];
  }
  //gets a parent object
  getParent(parent) {
    return this.fields.find(x => x.name == parent);
  }

  //checks if a field has a value
  hasValue(parent, name) {
    if (this.validate) {
    return (this.getValue(parent, name) === null);
    }
    else if (this.validateDate && parent === "Case Identification" && name === "Surg Date") {
      return this.getValue(parent, name) === null;
    } else {
      return false;
    }
  }

  setValue(parent, name, val) {
    this.getField(parent, name).value = val;
   }

  //returns the ngStyle object required to indicate form errors
  classObject(parent, name) {
    if (this.hasValue(parent, name)) {
      return "error";
    }

    if (this.hasOutRangeValue(parent, name)) {
      this.categoryOutRangeTotalMessage(parent);
      return "warning";
    }

    return "";
  }

 
  constructor() {
    this.resetFields();
  }

  public resetFields() {    
    this.validate = false;
    this.validateDate = false;
    this.caseLoaded = false;
    this.completedCase = false;
    let defaultFields = [
      {
        name: "IQIC Identifier",
        link: "/user-portal/" + this.type + "-surgery-case/unique-id",
        fields: [
          {
            name: "FirstName",
            value: null,
            hide: true,
            errormessage: "First Name"
          },
          {
            name: "LastName",
            value: null,
            hide: true,
            errormessage: "Last Name"
          },
          {
            name: "DateofBirth",
            value: null,
            hide: true,
            errormessage: "Date of Birth"
          },
          {
            name: "mrn",
            value: null,
            hide: true,
            errormessage: "MRN"
          }, 
          {
            name: "uniquePatientId",
            value: null,
            hide: true,
            errormessage: "IQIC Identifier"
          }
        ]
      },
      {
        name: "Case Identification",
        link: "/user-portal/" + this.type + "-surgery-case/surgery-case-id",
        fields: [
          {
            name: "uniquePatientId",
            value: null,
            errormessage: "IQIC Identifier"
          },
          {
            name: "Case Number",
            value: null,
            hide: true,
            errormessage: "Case Number"
          },
          {
            name: "Hospital",
            value: null,
            errormessage: "Hospital"
          },
          {
            name: "Surg Date",
            value: null,
            errormessage: "Surgery Date"
          },
          {
            name: "Surgeon",
            value: null,
            errormessage: "Surgeon"
          },
          {
            name: "PIPofcardiacSurgeons",
            value: null,
            hide: true,
            errormessage: "Cardiac Surgical Groups"
          }
        ]
      },
     
      {
        name: "Clinical Characteristics",
        link: "/user-portal/" + this.type + "-surgery-case/surgery-clinical-char",
        fields: [
          {
            name: "Id",
            value: 0,
            hide: true,
            errormessage: "Id"
          },
          {
            name: "Age",
            value: null,
            errormessage: "Age"
          },
          {
            name: "Age Unit",
            value: null,
            errormessage: "Age Unit"
          },
          {
            name: "Prematurity Unit",
            value: null,
            hide: true,
            errormessage: "Prematurity"
          },
          {
            name: "Sex",
            value: null,
            errormessage: "Sex"
          },
          {
            name: "Weight",
            value: null,
            min: 1,
            max: 99,
            errormessage: "Weight"
          },
          {
            name: "Height",
            value: null,
            min: 30,
            max: 180,
            errormessage: "Height"
          },
          {
            name: "BSA",
            value: null,
            errormessage: "Body Surface Area"
          },
          {
            name: "Hematocrit",
            value: null,
            hide: false,
            min: 15,
            max: 70,
            errormessage: "Hematocrit"
          },
          {
            name: "OxygenSaturation",
            value: null,
            hide: false,
            min: 10,
            max: 100,
            errormessage: "Oxygen Saturation"
          }
        ]
      },
      {
        name: "Medical History",
        link: "/user-portal/" + this.type + "-surgery-case/medical-history",
        fields: [
         
          {
            name: "SurCaseMedHistId",
            value: null,
            hide: true
          },
          {
            name: "Recorded Events",
            value: null,
            hide: false,
            errormessage: "Cardiac Diagnosis"
          },

          {
            name: "RheumaticHeartDiseaseInd",
            value: null,
            hide: true,
            errormessage: "Rheumatic Heart Disease",
            proccodes: ['990101','990102','990103','990104','990105','990107','990106','990401','990402','990403','990404','990405','990406','990407','990408']
          },

          {
            name: "GenericsyndromeInd",
            value: 'No',
            hide: true,
            errormessage: "Genetic Syndrome"
          },
          {
            name: "GenericsyndromeVal",
            value: null,
            hide: true,
            errormessage: "Genetic Syndrome"
          },     
          {
            name: "StructuralAnomalyInd",
            value: null,
            hide: false,
            errormessage: "Major Non-Cardiac Structural Anomaly"
          },
          {
            name: "StructuralAnomalyVal",
            value: null,
            hide: true,
            errormessage: "Major Non-Cardiac Structural Anomaly"
          },
          {
            name: "MedicalIllnessInd",
            value: null,
            hide: true,
            errormessage: "Major Non-Cardiac Medical Illness"
          },
          {
            name: "MedicalIllnessVal",
            value: null,
            hide: true,
            errormessage: "Major Non-Cardiac Medical Illness"
          },

          {
            name: "PreviousCardiacSurgeryInd",
            value: null,
            hide: true,
            errormessage: "Previous Cardiac Surgery"
          },
               
          {
            name: "NumberOfUniqueCardiacOperations",
            value: null,
            hide: true,
            errormessage: "Number Of Unique Cardiac Operations"
          },
          {
            name: "PreviousCardiacCatheterizationInd",
            value: null,
            hide: true,
            errormessage: "Previous Cardiac Catheterization"
          },

          {
            name: "BalloonAtrioseptostomyBeforeSurgeryInd",
            value: null,
            hide: true,
            errormessage: "Balloon Atrioseptostomy Before Surgery"
          },

          {
            name: "ResuscitationBeforeSurgeryInd",
            value: null,
            hide: true,
            errormessage: "Resuscitation Before Surgery"
          },

          {
            name: "InotropeTherapyBeforeSurgeryInd",
            value: null,
            hide: true,
            errormessage: "Inotrope Therapy Before Surgery"
          },
          {
            name: "VentilationPreoperativelyInd",
            value: null,
            hide: true,
            errormessage: "Ventilation Preoperatively"
          }
        ]
      },
      {
        name: "Surgical Procedures",
        link: "/user-portal/" + this.type + "-surgery-case/surgery-case-type",
        hide: false,
        fields: [
          {
            name: "SurCaseProcAsgnId",
            value: null,
            hide: true
          },
          {
            name: "Recorded Events",
            value: null,
            hide: false,
            errormessage: "Performed Surgical Procedures"
          },
          {
            name: "Description",
            value: null,
            hide: true,
            errormessage: "Describe Surgical Procedure"
          },
          {
            name: "RACHS-1 Category",
            value: null,
            hide: true,
            errormessage: "RACHS-1 Category"
          }
        ]
      },
      {
        name: "Surgery Events",
        link: "/user-portal/" + this.type + "-surgery-case/surgery-events",
        hide: true,
        fields: [
          {
            name: "SurCaseEventId",
            value: null,
            hide: true
          },
          {
          name: "PatChestOpenSurgeryInd",
          value: false,
            hide: false,
            errormessage: "Open Chest After Surgery"
          },
          {
            name: "PatCarioBypassRelEventInd",
            value: false,
            hide: false,
            errormessage: "Any Significant Cardiopulmonary Bypass Related Event"
          },
          {
            name: "CPBEventsList",
            value: null,
            hide: false,
            errormessage: "Cardiopulmonary Bypass Event Type"
          }
        ]
      },



      {
        name: "Clinical Outcomes",
        link: "/user-portal/" + this.type + "-surgery-case/clinical-outcomes",
        fields: [
          {
            name: "SurCaseClinicalOutComesId",
            value: null,
            hide: true
          },
          {
            name: "InHospDeath",
            value: null,
            errormessage: "In Hospital Death"
          },
          {
            name: "dOD",
            value: null,
            hide: true,
            errormessage: "Death Date"
          },
          {
            name: "dODDescription",
            value: null,
            hide: true,
            errormessage: "Death Description"
          },
          {
            name: "BactSepsis",
            value: null,
            hide: false,
            errormessage: "Bacterial Sepsis"
          },
          {
            name: "BactSepsisDescription",
            value: null,
            hide: true,
            errormessage: "Bacterial Sepsis Description"
          },
          {
            name: "SurgicalSiteInfection",
            value: null,
            hide: false,
            errormessage: "Surgical Site Infection"
          },
          {
            name: "SurgicalSiteInfectionType",
            value: null,
            hide: true,
            errormessage: "Surgical Site Infection Type"
          },
          {
            name: "SurSiteInfectionDescription",
            value: null,
            hide: true,
            errormessage: "Surgical Site Infection Description"
          },
          {
            name: "HospitalInfections",
            value: null,
            hide: true,
            errormessage: "Any Additional Hospital Aquired Infections"
          },
          {
            name: "VentPneumonia",
            value: null,
            hide: true,
            errormessage: "Ventilator Associated Events"
          },
          {
            name: "VentPneumoniaDescription",
            value: null,
            hide: true,
            errormessage: "Ventilator Associated Events Description"
          },
          {
            name: "CathInfection",
            value: null,
            hide: true,
            errormessage: "Catheter Associated Bloodstream Infection"
          },
          {
            name: "CathInfectionDescription",
            value: null,
            hide: true,
            errormessage: "Catheter Associated Bloodstream Infection Description"
          },
          {
            name: "cathUTI",
            value: null,
            hide: true,
            errormessage: "Catheter Associated Urinary Tract Infection"
          },
          {
            name: "CathUTIDescription",
            value: null,
            hide: true,
            errormessage: "Catheter Associated Urinary Tract Infection Description"
          },
          {
            name: "AnyComplications",
            value: null,
            hide: true,
            errormessage: "Complications"
          },
          {
            name: "ReqAddSurgeryForBleeding",
            value: null,
            hide: true,
            errormessage: "Required Additional Surgery For Bleeding"
          },
          {
            name: "ReIntubationIn24hrs",
            value: null,
            hide: true,
            errormessage: "Re-intubations With In 24 Hours Of Extubation"
          },
          {
            name: "ICUReAdmissionAfter24Hrs",
            value: null,
            hide: true,
            errormessage: "ICU Readmission After 24 Hours Of Discharge From ICU"
          },
          {
            name: "DiaphragmaticParalysis",
            value: null,
            hide: true,
            errormessage: "Diaphragmatic Paralysis"
          },
          {
            name: "PostSurgicalHeartBlock",
            value: null,
            hide: true,
            errormessage: "Post Surgical Heart Block Requiring Pacemaker"
          },
          {
            name: "Tracheostomy",
            value: null,
            hide: true,
            errormessage: "Tracheostomy During The Same Admission As Surgery"
          },
          {
            name: "PostOperativeSeizures",
            value: null,
            hide: true,
            errormessage: "Post-operative Seizures"
          },
          {
            name: "AcuteKidneyInjury",
            value: null,
            hide: true,
            errormessage: "Acute Kidney Injury Requiring Dialysis"
          },
          {
            name: "OtherComplications",
            value: null,
            hide: true,
            errormessage: "Other Complications"
          },
          {
            name: "OtherComplicationsDescription",
            value: null,
            hide: true
          }
        ]
      },
      {
        name: "Day 30 Status",
        link: "/user-portal/" + this.type + "-surgery-case/day-30-status",
        hide: true,
        fields: [
          {
            name: "SurCase30DayStatusId",
            value: null,
            hide: true
          },
          {
            name: "IsPatientAlive",
            value: null,
            hide: false,
            errormessage: "Is Patient Alive"
          },
          {
            name: "IsPatientDischarged",
            value: null,
            hide: false,
            errormessage: "Is Patient Discharged"
          },
          {
            name: "IsPatientDoingWell",
            value: null,
            hide: false,
            errormessage: "Is The Patient Doing Well"
          }
        ]
      },
      {
        name: "Procedural Efficacy",
        link: "/user-portal/" + this.type + "-surgery-case/procedural-efficacy",
        hide: false,
        fields: [
          {
            name: "ASDRepairStatus",
            value: false,
            hide: true,
            proccode: "IASDSR",
            fieldtype: "boolean",
            errormessage: "Isolated ASD Secundum Repair",
            stscodes: ['110102'],
            visibleSTSCodesMessage: false,
            STSCodesWarningMessage: "ASD Repair Status efficacy data is entered, with out selecting ASD related Procedures in Surgical Procedures."
          },
          {
            name: "ASDEchoPerformed",
            value: null,
            hide: true,
            parentcode: "IASDSR",
            elementcode: "IASDSR01",
            fieldtype: "boolean",
            errormessage: "An Echo Performed After The First Intervention"
          },
          {
            name: "ASDPostOperativeReIntervention",
            value: null,
            hide: true,
            parentcode: "IASDSR",
            elementcode: "IASDSR02",
            fieldtype: "boolean",
            errormessage: "Post-operative Re-intervention (Catheter Or Surgical) Performed"
          },
          {
            name: "ASDRepair",
            value: null,
            hide: true,
            parentcode: "IASDSR",
            elementcode: "IASDSR03",
            fieldtype: "radio",
            errormessage: "ASD Repair"
          },
          {
            name: "ASDConduction",
            value: null,
            hide: true,
            parentcode: "IASDSR",
            elementcode: "IASDSR04",
            fieldtype: "radio",
            errormessage: "ASD Conduction"
          },
          {
            name: "ASDTPS",
            value: null,
            hide: true,
            parentcode: "IASDSR",
            elementcode: "IASDSR05",
            errormessage: "ASD TPS"
          },
          {
            name: "VASSurgeryStatus",
            value: false,
            hide: true,
            proccode: "IVASR",
            fieldtype: "boolean",
            errormessage: "Isolated Valvar Aortic Stenosis Repair",
            stscodes: ['880117'],
            visibleSTSCodesMessage: false,
            STSCodesWarningMessage: "Isolated Valvar Aortic Stenosis Repair Efficacy data is entered with out selecting Aortic Valve Disease related procedures in Surgical Procedures."
          },
          {
            name: "VASEchoPerformed",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR01",
            fieldtype: "boolean",
            errormessage: "An Echo Performed After The First Intervention"
          },
          {
            name: "VASPostOperativeReIntervention",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR02",
            fieldtype: "boolean",
            errormessage: "A Post-operative Re-intervention (Catheter Or Surgical) Performed"
          },
          {
            name: "VASAroticValueStenosis",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR03",
            fieldtype: "radio",
            errormessage: "Aortic Valve, Stenosis"
          },
          {
            name: "VASAroticValueRegurgitation",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR04",
            fieldtype: "radio",
            errormessage: "Aortic Valve, Regurgitation"
          },
          {
            name: "VASCoronaryFlow",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR05",
            fieldtype: "radio",
            errormessage: "Coronary Flow"
          },
          {
            name: "VASSupraAorticAnastomosis",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR06",
            fieldtype: "radio",
            errormessage: "Supra Aortic Anastomosis"
          },
          {
            name: "VASCondution",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR07",
            fieldtype: "radio",
            errormessage: "Aortic Valve, Conduction"
          },
          {
            name: "VASTPS",
            value: null,
            hide: true,
            parentcode: "IVASR",
            elementcode: "IVASR08",
            errormessage: "TPS"
          },
          {
            name: "CRLThoracotomyStatus",
            value: false,
            hide: true,
            proccode: "CRVLT",
            fieldtype: "boolean",
            errormessage: "Coarctation Repair Via left Thoracotomy",
            stscodes: ['160101', '160102', '160103', '160104', '160105', '160106'],
            visibleSTSCodesMessage: false,
            STSCodesWarningMessage: "Coarctation Repair Via left Thoracotomy Efficacy Data is entered with out selecting Coarctation repair in Surgical Procedures."
          },
          {
            name: "CRLEchoPerformed",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT01",
            fieldtype: "boolean",
            errormessage: "An Echo Performed After The First Intervention"
          },
          {
            name: "CRLPostOperativeReIntervention",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT02",
            fieldtype: "boolean",
            errormessage: "A Post-operative Re-intervention (Catheter Or Surgical) Performed"
          },
          {
            name: "CRLAorticArch",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT03",
            fieldtype: "radio",
            errormessage: "Aortic Arch"
          },
          {
            name: "CRLDescendingAorta",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT04",
            fieldtype: "radio",
            errormessage: "Descending Aorta"
          },
          {
            name: "CRLBranchPulmonaryArtery",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT05",
            fieldtype: "radio",
            errormessage: "Branch Pulmonary Artery"
          },
          {
            name: "CRLTPS",
            value: null,
            hide: true,
            parentcode: "CRVLT",
            elementcode: "CRVLT06",
            errormessage: "TPS"
          },
          {
            name: "PDALigationStatus",
            value: false,
            hide: true,
            proccode: "IPDAL",
            fieldtype: "boolean",
            errormessage: "Isolated PDA Ligation",
            stscodes: ['160401'],
            visibleSTSCodesMessage: false,
            STSCodesWarningMessage: "Isolated PDA Ligation Efficacy Data is entered with out selecting PDA Closure Procedure in Surgical Procedures."
          },
          {
            name: "PDAEchoPerformed",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL01",
            fieldtype: "boolean",
            errormessage: "An Echo Performed After The First Intervention"
          },
          {
            name: "PDAPostOperativeReIntervention",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL02",
            fieldtype: "boolean",
            errormessage: "A Post-operative Re-intervention (Catheter Or Surgical) Performed"
          },
          {
            name: "PDALigationDivision",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL03",
            fieldtype: "radio",
            errormessage: "PDA Ligation/Division"
          },
          {
            name: "PDABranchPulmonaryArtery",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL04",
            fieldtype: "radio",
            errormessage: "Branch Pulmonary Artery"
          },
          {
            name: "PDAIsthmusDescendingAorta",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL05",
            fieldtype: "radio",
            errormessage: "Isthmus/Descending Aorta"
          },
          {
            name: "PDATPS",
            value: null,
            hide: true,
            parentcode: "IPDAL",
            elementcode: "IPDAL06",
            errormessage: "TPS"
          }
        ]
      }
    ];
    this.fields = defaultFields;
  }
  //gets a particular field object based on its parent and name

  public isComplete(): boolean {
    if (this.fields.length > 0) {
      for (let category of this.fields) {
        if (!category["hide"]) {
          for (let field of category.fields) {
            //value cannot be null and field should be 
            if (!field.value && !field["hide"]) {
              return false;
            }
          }
        }        
      }
      return true;
    }

    return false;
  }

  hasOutRangeValue(parent, name): boolean {
    let result = false;

    try {
      let f = this.getField(parent, name);
      if (f["value"]) {
        let val = f["value"];

        if (f["min"]) {
          let min = +f["min"];
          if (min > +val) {
            result = true;
          }
        }

        if (f["max"]) {
          let max = +f["max"];

          

          if (max < +val) {
            result = true;
          }
        }
      }
    } catch (ex) {
      console.log(ex)
    }

    return result;
  }

  
  private checkCardiacDiagnosisLength(parent, name): string {
    let result = "";
    var procsArr = [];
      try {
        let f = this.getField(parent, name);
        let val = f["value"];
        if (val !== null && name === 'Recorded Events') {
          procsArr = Object.getOwnPropertyNames(val);
          if (procsArr.length > 4) {
            result = "Selected " + f["errormessage"] + " exceeded 4 values";
          }
        }
      } catch (ex) {
        console.log(ex)
      }

    return result;
  }


  private outRangeMessage(parent, name): string {
    let result = "";

    if (this.hasOutRangeValue(parent, name)) {
      try {
        let f = this.getField(parent, name);
        let val = f["value"];

        if (f["min"]) {
          let min = +f["min"];
          result = "" + name + " value should be greater than or equal to " + min;
        }

        if (f["max"]) {
          let max = +f["max"];
          result = result + " and less than or equal to " + max;
        }
      } catch (ex) {
        console.log(ex)
      }
    }    

    return result;
  }

  public categoryOutRangeTotalMessage(parent): string {
    let result = [];

    if (this.fields.length > 0) {
      for (let category of this.fields) {
        if (category["name"] == parent) {
          for (let field of category.fields) {
            let m = this.outRangeMessage(parent, field["name"]);
            if (m.length > 0) {
              result.push(m);
            }
          }
        }
        if (category["name"] == parent) {
          for (let field of category.fields) {
            let m = this.checkCardiacDiagnosisLength(parent, field["name"]);
            if (m.length > 0) {
              result.push(m);
            }
          }
        }
      }
    }

    if (result.length > 0) {
      result.unshift("Range Warning:");
    }

    return result.join("<br/>");
    
  }
}
