import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../user/login.service";
import { TitleService } from "../../title.service";
import { RouterService } from '../navigation/router.service';
import { RouterLink } from "@angular/router/src/directives/router_link";
import { Location } from '@angular/common';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  imageSourceC3PO: string;
  imageSourceIQIC: string;
  homeLink: string;
  addCaseLink: string;
  isVisible: boolean = true;
  showC3PO: boolean = true;
  showIQIC: boolean = true;
  aboutOpened: boolean = false;
  inPortal:boolean = false;
  portalOnly:boolean = false;
  user:LoginService;
  title:TitleService;

  constructor(private router: Router, user: LoginService, title: TitleService, private routerService: RouterService, private loc: Location,) {
   
    this.user = user;
    this.title = title;
    
    this.router.events.subscribe(() => {
      const url = this.router.url;


      //Hide the NAV if invalid URL
      if (url == "/") {
        this.isVisible = false;
        return;
      }
      else {
        this.isVisible = true;

      }



      if (url.indexOf("about") > -1) {
        this.aboutOpened = true;
      } else {
        this.aboutOpened = false;
      }
      if (url.indexOf("user-portal") > -1) {
        this.inPortal = true;
      } else {
        this.inPortal = false;
      }
      if (url.indexOf("user-menu") > - 1 && !(url.indexOf("add-case") > -1)) {
        this.portalOnly = true;
      }
      else {
        this.portalOnly = false;
      }

      ////IQIC
      //if (isIQIC) {                                             
       
      //  //this.imageSourceC3PO = "assets/images/c3po 1 8 19.png";
      //  //this.imageSourceIQIC = "assets/images/iqic 1 8 19.png";
      //  this.imageSourceC3PO = "assets/images/C3PO 1 11 19.png";
      //  this.imageSourceIQIC = "assets/images/IQIC 1 11 19 ver 2.png";

      //  this.homeLink = "/home/IQIC";
      //  this.showC3PO = false;
      //  this.showIQIC = true;
      //}
      ////C3POR3
      //else {        
        this.imageSourceC3PO = "assets/images/c3poLogo18.jpg";
        this.imageSourceIQIC = "assets/images/IQIC 12 27 18 308x70.jpg";
      //if (router.url.indexOf("login") > 0) {
      //  window.open("http://iqic", "Home Page IQIC", "");
      //}
        this.homeLink = "/login";
        this.showC3PO = true;
        this.showIQIC = false;
        //console.log("I am here")
       // if (router.url.indexOf("login") > 0) {
          //window.open("http://iqic", "_blank");
         // window.open("http://iqic", "Home Page IQIC", ""); 
       // }
        //console.log("I am out")
      //}


      this.addCaseLink = "/user-portal/add-case";
      //IQIC users see pre-case
      if (this.user.IsIqicUser()) {
        this.addCaseLink = "/user-portal/add-case/iqic";
      }

     
    });
  }
  redirectToSite() {
    const angularRoute = this.loc.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');

    if (domainAndApp.indexOf('c3po')>-1) {
      this.router.navigate(['/home']);
    }
    else {
      window.open("https://iqic.chboston.org/", "Home Page IQIC", "");
    }
  }
  logOut() {
    this.user.logOut();
  }
  ngOnInit() {}
}
