import { Component, OnInit, DoCheck } from '@angular/core';
import { Location } from '@angular/common';
import { SurgeryCaseFieldsService } from '../surgery-case-fields.service';
import { SurCaseService } from '../surcase.service';
import { LoginService } from "../../../user/login.service";
import { Router } from '@angular/router';
import { SurCaseSaveResponse } from '../../../../shared/iqis/surcase-save/surcase-save-response';
import { SurCaseResponse } from '../../../../shared/iqis/surcase/surcase-response';
import { SurCaseProcEfficacyInfoModel } from '../../../../shared/iqis/surcase/surcase-proc-efficacy-info-model';
import { MarkSurCaseCompleteResponse } from '../../../../shared/iqis/surcase-save/mark-surcase-complete-response';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-surgery-missing-fields',
  templateUrl: './surgery-missing-fields.component.html',
  styleUrls: ['./surgery-missing-fields.component.scss'],
  host: { class: "host" }
})
export class SurgeryMissingFieldsComponent implements OnInit, DoCheck {

  public loadCaseRun: boolean = false;
  public isNewCase: boolean = false;
  public isComplete: boolean = false;
  public completedCaseText: string = '';
  public saveCaseText: string = '';
  public caseLoadingText: string = '';
  public fields: SurgeryCaseFieldsService;
  public surCaseService: SurCaseService;
  public user: LoginService;
  constructor(fields: SurgeryCaseFieldsService, _surCaseService: SurCaseService, _user: LoginService, private router: Router, private location: Location) {
    localStorage.setItem('savecase', 'savesurgerycase');
    this.user = _user;
    if (this.user.IsReadOnlyUser()) {
      this.location.back();
    }

    this.fields = fields;
    this.surCaseService = _surCaseService;    
  }

  ngOnInit() {
    this.completedCaseText = '';
    this.saveCaseText = '';
    this.caseLoadingText = '';
    this.fields.validate = true;

    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (!isNotPostSave) {
      let urlSplit = url.split("/");
      let caseId = urlSplit[urlSplit.length - 1];
      //if(this.fields.type === "add")
        this.saveCaseText = 'Surgery Case Information successfully  saved (Case ID: ' + caseId + ')';
      //else
       // this.saveCaseText = 'Case has been created and saved (Case ID: ' + caseId + ')';

    }
    this.checkCaseLoaded();
  }

  ngDoCheck() {
    if (this.loadCaseRun === false) {
      this.checkCaseLoaded();
    }
  }

  private checkCaseLoaded() {
    if (this.fields.caseLoaded == false) {
      $('body').addClass('wait-overlay');
      this.caseLoadingText = 'Case is still loading...';
    } else {
      $('body').removeClass('wait-overlay');
      this.caseLoadingText = '';
      this.loadCase();
    }
  }

  private loadCase() {
    this.loadCaseRun = true;
    this.isComplete = this.fields.isComplete();

    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (isNotPostSave) {
      console.log(this.fields.fields);
      this.surCaseService.clearCase();

      // Case Identification
      this.surCaseService.surcase.SurLocationId = this.fields.getField("Case Identification", 'Hospital').value;
      this.surCaseService.surcase.SurCaseUpdatedby = this.user.user.UserId.toString();
      this.surCaseService.surcase.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
      this.surCaseService.surcase.SurCaseUniqueIdentifier = this.fields.getField("Case Identification", 'uniquePatientId').value;
      this.surCaseService.surcase.SurgDate = this.formatDate(this.fields.getField("Case Identification", 'Surg Date').value);
      this.surCaseService.surcase.PrimarySurgeonId = this.fields.getField("Case Identification", 'Surgeon').value;
      this.surCaseService.surcase.SurPerfPVCSGInd = this.getTrueFalse(this.fields.getField("Case Identification", "PIPofcardiacSurgeons").value)


      //Clinical Characteristics
      //if (localStorage.getItem('surgeryclinicalchardirty') === 'true') {
        this.surCaseService.surcase.SurCaseClinicalInfo.SurCaseClinicalInfoId = this.fields.getField("Clinical Characteristics", "Id").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseClinicalInfo.PatAge = this.fields.getField("Clinical Characteristics", "Age").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.PatAgeType = this.getAgeType(this.fields.getField("Clinical Characteristics", 'Age Unit').value);
        if (this.fields.getField("Clinical Characteristics", "Prematurity Unit").value)
          this.surCaseService.surcase.SurCaseClinicalInfo.PatPreMaturityVal = this.getprematurevalue(this.fields.getField("Clinical Characteristics", "Prematurity Unit").value);
        this.surCaseService.surcase.SurCaseClinicalInfo.PatSex = this.fields.getField("Clinical Characteristics", "Sex").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.PatWeight = this.fields.getField("Clinical Characteristics", "Weight").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.PatHeight = this.fields.getField("Clinical Characteristics", "Height").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.PatBSA = this.fields.getField("Clinical Characteristics", "BSA").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.SurHemotocritVal = this.fields.getField("Clinical Characteristics", "Hematocrit").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.SurOxygenSatVal = this.fields.getField("Clinical Characteristics", "OxygenSaturation").value;
        this.surCaseService.surcase.SurCaseClinicalInfo.Updatedby = this.user.user.UserId.toString();
      //}
      //else {
      //  this.surCaseService.surcase.SurCaseClinicalInfo = null;
      //}


      //medical History
      
        this.surCaseService.surcase.SurCaseMedicalHistInfo.SurCaseMedHistId = this.fields.getField("Medical History", "SurCaseMedHistId").value;
        this.surCaseService.surcase.SurCaseMedicalHistInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseMedicalHistInfo.CardiacDiagCodes = this.getprocedures(this.fields.getField("Medical History", "Recorded Events").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.RheumeticFeverInd = this.getYesNo(this.fields.getField("Medical History", "RheumaticHeartDiseaseInd").value);

        this.surCaseService.surcase.SurCaseMedicalHistInfo.GenericSyndInd = this.getYesNo(this.fields.getField("Medical History", "GenericsyndromeInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.GenericSyngVal = this.fields.getField("Medical History", 'GenericsyndromeVal').value;

        this.surCaseService.surcase.SurCaseMedicalHistInfo.MajorNcsAnomalyInd = this.getYesNo(this.fields.getField("Medical History", "StructuralAnomalyInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.MajorNcsAnomalyVal = this.fields.getField("Medical History", 'StructuralAnomalyVal').value;

        this.surCaseService.surcase.SurCaseMedicalHistInfo.MajorNcMedIllnessInd = this.getYesNo(this.fields.getField("Medical History", "MedicalIllnessInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.MajorNcMedIllnessVal = this.fields.getField("Medical History", 'MedicalIllnessVal').value;

        this.surCaseService.surcase.SurCaseMedicalHistInfo.PrevCardSurgInd = this.getYesNo(this.fields.getField("Medical History", "PreviousCardiacSurgeryInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.PrevCardSurgeries = this.fields.getField("Medical History", 'NumberOfUniqueCardiacOperations').value;

        this.surCaseService.surcase.SurCaseMedicalHistInfo.PrevCardCathInd = this.getYesNo(this.fields.getField("Medical History", "PreviousCardiacCatheterizationInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.PreoprBakatriBSInd = this.getYesNo(this.fields.getField("Medical History", "BalloonAtrioseptostomyBeforeSurgeryInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.PreoprResuscitBSInd = this.getYesNo(this.fields.getField("Medical History", "ResuscitationBeforeSurgeryInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.PreoprInotheraBSInd = this.getYesNo(this.fields.getField("Medical History", "InotropeTherapyBeforeSurgeryInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.PreoprVentpreopInd = this.getYesNo(this.fields.getField("Medical History", "VentilationPreoperativelyInd").value);
        this.surCaseService.surcase.SurCaseMedicalHistInfo.Updatedby = this.user.user.UserId.toString();
      
    

      //case type
      //if (localStorage.getItem('surgerycasetypedirty') === 'true' && this.fields.getField("Surgical Procedures", "Recorded Events").value !== null) {
       // if (localStorage.getItem('surgerycasetypedirty') === 'true' ) {
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.SurCaseProcAsgnId = this.fields.getField("Surgical Procedures", "SurCaseProcAsgnId").value;
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.ProcedureCode = this.getprocedures(this.fields.getField("Surgical Procedures", "Recorded Events").value);
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.ProceduresDescription = this.fields.getField("Surgical Procedures", "Description").value;;
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.ProceduresRachsValue = this.fields.getField("Surgical Procedures", "RACHS-1 Category").value;
        this.surCaseService.surcase.SurCaseProcAsgnmtInfo.Updatedby = this.user.user.UserId.toString();
     // }
     // else {
     //   this.surCaseService.surcase.SurCaseProcAsgnmtInfo = null;
    //  }

      //Surgery Events
      //if (localStorage.getItem('surgeryeventsdirty') === 'true') {
        this.surCaseService.surcase.SurCaseEventsInfo.SurCaseEventId = this.fields.getField("Surgery Events", "SurCaseEventId").value;
        this.surCaseService.surcase.SurCaseEventsInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseEventsInfo.PatChestOpenSurgeryInd = this.getTrueFalse(this.fields.getField("Surgery Events", "PatChestOpenSurgeryInd").value);
        this.surCaseService.surcase.SurCaseEventsInfo.PatCarioBypassRelEventInd = this.getTrueFalse(this.fields.getField("Surgery Events", "PatCarioBypassRelEventInd").value);
        this.surCaseService.surcase.SurCaseEventsInfo.PatCarioBypassRelEventNotes = this.fields.getField("Surgery Events", "CPBEventsList").value;
        this.surCaseService.surcase.SurCaseEventsInfo.Updatedby = this.user.user.UserId.toString();
      //}
      //else {
      //  this.surCaseService.surcase.SurCaseEventsInfo = null;
      //}

      //clinical outcomes
      if (localStorage.getItem('clinicaloutcomedirty') === 'true') {
        if (this.fields.getField("Clinical Outcomes", "InHospDeath").value === 'No') {
          this.fields.getField("Clinical Outcomes", "dOD").hide = true;
          this.fields.getField("Clinical Outcomes", "dODDescription").hide = true;
        }
        if (this.fields.getField("Clinical Outcomes", "InHospDeath").value === 'Yes') {
          this.fields.getField("Clinical Outcomes", "dOD").hide = false;
          this.fields.getField("Clinical Outcomes", "dODDescription").hide = false;
        }
        if (this.fields.getField("Clinical Outcomes", "SurgicalSiteInfection").value === 'No') {
          this.fields.getField("Clinical Outcomes", "SurgicalSiteInfectionType").hide = true;
        }
        if (this.fields.getField("Clinical Outcomes", "SurgicalSiteInfection").value === 'Yes') {
          this.fields.getField("Clinical Outcomes", "SurgicalSiteInfectionType").hide = false;
        }
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurCaseClinicalOutComesId = this.fields.getField("Clinical Outcomes", "SurCaseClinicalOutComesId").value;
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseOutcomesInfo.HospitalDeathInd = this.getYesNo(this.fields.getField("Clinical Outcomes", "InHospDeath").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.HospitalDeathDt = this.formatDate(this.fields.getField("Clinical Outcomes", "dOD").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.HospitalDeathReason = this.fields.getField("Clinical Outcomes", "dODDescription").value;

        this.surCaseService.surcase.SurCaseOutcomesInfo.BactSepsisInfectionInd = this.getYesNo(this.fields.getField("Clinical Outcomes", "BactSepsis").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.BactSepsisInfectionDesc = this.fields.getField("Clinical Outcomes", "BactSepsisDescription").value;

        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionInd = this.getYesNo(this.fields.getField("Clinical Outcomes", "SurgicalSiteInfection").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionVal = this.fields.getField("Clinical Outcomes", "SurgicalSiteInfectionType").value;
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionDesc = this.fields.getField("Clinical Outcomes", "SurSiteInfectionDescription").value;

        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteAddlInfectionInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "HospitalInfections").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionVaeInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "VentPneumonia").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionVaeDesc = this.fields.getField("Clinical Outcomes", "VentPneumoniaDescription").value;
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionCabiInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "CathInfection").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionCabiDesc = this.fields.getField("Clinical Outcomes", "CathInfectionDescription").value;
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionCautiInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "cathUTI").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteInfectionCautiDesc = this.fields.getField("Clinical Outcomes", "CathUTIDescription").value;

        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "AnyComplications").value);

        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationASEBInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "ReqAddSurgeryForBleeding").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationICR24DInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "ICUReAdmissionAfter24Hrs").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationRIWI24Ind = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "ReIntubationIn24hrs").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationDIAPInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "DiaphragmaticParalysis").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationTDSAAGInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "Tracheostomy").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationPOSInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "PostOperativeSeizures").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationAKIRDInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "AcuteKidneyInjury").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationHBNPMInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "PostSurgicalHeartBlock").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationOtherInd = this.getYesNoNotCollected(this.fields.getField("Clinical Outcomes", "OtherComplications").value);
        this.surCaseService.surcase.SurCaseOutcomesInfo.Updatedby = this.user.user.UserId.toString();
        //this.surCaseService.surcase.SurCaseOutcomesInfo.SurgSiteComplicationAddlInfoval = this.getTrueFalse(this.fields.getField("Clinical Outcomes", "IsPatientAlive"));
      }
      else {
        this.surCaseService.surcase.SurCaseOutcomesInfo = null;
      }
      //SurCase30DayStatusInfo
      if (this.fields.getField("Day 30 Status", "IsPatientAlive").value === null) {
        this.surCaseService.surcase.SurCase30DayStatusInfo = null;
      }
      else {
       // if (localStorage.getItem('day30statusdirty') === 'true') {
          if (this.fields.getValue("Day 30 Status", "IsPatientAlive") === 'false') {
            this.fields.getField("Day 30 Status", "IsPatientDischarged").hide = true;
            this.fields.getField("Day 30 Status", "IsPatientDoingWell").hide = true;
          }
          if (this.fields.getValue("Day 30 Status", "IsPatientAlive") === 'true') {
            this.fields.getField("Day 30 Status", "IsPatientDischarged").hide = false;
          }
          if (this.fields.getValue("Day 30 Status", "IsPatientDischarged") === 'Yes') {
            this.fields.getField("Day 30 Status", "IsPatientDoingWell").hide = false;
          }
          if (this.fields.getValue("Day 30 Status", "IsPatientDischarged") === 'No') {
            this.fields.getField("Day 30 Status", "IsPatientDoingWell").hide = true;
          }
          this.surCaseService.surcase.SurCase30DayStatusInfo.SurCase30DayStatusId = this.fields.getField("Day 30 Status", "SurCase30DayStatusId").value;
          this.surCaseService.surcase.SurCase30DayStatusInfo.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCase30DayStatusInfo.IsPatientAlive = this.getYesNoUnknown(this.fields.getField("Day 30 Status", "IsPatientAlive").value);
          this.surCaseService.surcase.SurCase30DayStatusInfo.IsPatientDischarged = this.getYesNo(this.fields.getField("Day 30 Status", "IsPatientDischarged").value);
          this.surCaseService.surcase.SurCase30DayStatusInfo.IsPatientDoingWell = this.getYesNo(this.fields.getField("Day 30 Status", "IsPatientDoingWell").value);
          this.surCaseService.surcase.SurCase30DayStatusInfo.Updatedby = this.user.user.UserId.toString();
       // }
       // else {
       //   this.surCaseService.surcase.SurCase30DayStatusInfo = null;
       // }
      }
      
      //this.surCaseService.surcase.SurCaseOutcomesInfo = null;
      //efficacy
      this.setupProcedureEfficacyData();
     // this.surCaseService.surcase.SurCaseProcEfficacyInfo = null;


      if ((this.surCaseService.surcase.SurCaseUniqueIdentifier === null || this.surCaseService.surcase.SurCaseUniqueIdentifier === undefined
              || this.surCaseService.surcase.SurgDate === '') && isNotPostSave) {
        this.fields.validate = false;
        this.fields.validateDate = true;
        if (this.surCaseService.surcase.SurCaseId == 0) {
          this.fields.type = "add"
        }
        else {
          this.fields.type = "edit"
        }
        let nav = this.fields.getParent("Case Identification")["link"];
        this.router.navigate([nav]);
      } else {
        this.isNewCase = this.surCaseService.surcase.SurCaseId === 0;   
        this.save()
      }
    }
  }
 
  private setupProcedureEfficacyData() {
    //if (localStorage.getItem('proceduralefficacydirty') === 'true') {
      if (this.fields.getField("Procedural Efficacy", "ASDRepairStatus").value !== null) {
        this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "ASDRepairStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "ASDRepairStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "ASDRepairStatus").value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
        this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
      }

      if (this.fields.getField("Procedural Efficacy", "ASDRepairStatus").value) {
        if (this.fields.getField("Procedural Efficacy", "ASDEchoPerformed").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "ASDEchoPerformed").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "ASDEchoPerformed").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "ASDEchoPerformed").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "ASDPostOperativeReIntervention").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "ASDPostOperativeReIntervention").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "ASDPostOperativeReIntervention").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "ASDPostOperativeReIntervention").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "ASDEchoPerformed").value) {
          if (this.fields.getField("Procedural Efficacy", "ASDRepair").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "ASDRepair").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "ASDRepair").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "ASDRepair").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "ASDConduction").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "ASDConduction").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "ASDConduction").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "ASDConduction").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
        }
      }

      if (this.fields.getField("Procedural Efficacy", "VASSurgeryStatus").value !== null) {
        this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASSurgeryStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASSurgeryStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASSurgeryStatus").value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
        this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
      }
      if (this.fields.getField("Procedural Efficacy", "VASSurgeryStatus").value) {
        if (this.fields.getField("Procedural Efficacy", "VASEchoPerformed").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASEchoPerformed").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASEchoPerformed").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASEchoPerformed").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "VASPostOperativeReIntervention").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASPostOperativeReIntervention").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASPostOperativeReIntervention").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASPostOperativeReIntervention").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "VASEchoPerformed").value) {
          if (this.fields.getField("Procedural Efficacy", "VASAroticValueStenosis").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASAroticValueStenosis").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASAroticValueStenosis").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASAroticValueStenosis").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "VASAroticValueRegurgitation").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASAroticValueRegurgitation").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASAroticValueRegurgitation").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASAroticValueRegurgitation").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "VASCoronaryFlow").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASCoronaryFlow").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASCoronaryFlow").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASCoronaryFlow").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "VASSupraAorticAnastomosis").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASSupraAorticAnastomosis").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASSupraAorticAnastomosis").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASSupraAorticAnastomosis").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "VASCondution").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "VASCondution").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "VASCondution").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "VASCondution").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
        }
      }
     
      if (this.fields.getField("Procedural Efficacy", "CRLThoracotomyStatus").value !== null) {
        this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLThoracotomyStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLThoracotomyStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLThoracotomyStatus").value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
        this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
      }
      if (this.fields.getField("Procedural Efficacy", "CRLThoracotomyStatus").value) {
        if (this.fields.getField("Procedural Efficacy", "CRLEchoPerformed").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLEchoPerformed").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLEchoPerformed").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLEchoPerformed").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "CRLPostOperativeReIntervention").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLPostOperativeReIntervention").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLPostOperativeReIntervention").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLPostOperativeReIntervention").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "CRLEchoPerformed").value) {
          if (this.fields.getField("Procedural Efficacy", "CRLAorticArch").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLAorticArch").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLAorticArch").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLAorticArch").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "CRLDescendingAorta").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLDescendingAorta").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLDescendingAorta").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLDescendingAorta").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "CRLBranchPulmonaryArtery").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "CRLBranchPulmonaryArtery").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "CRLBranchPulmonaryArtery").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "CRLBranchPulmonaryArtery").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
        }
      }

      if (this.fields.getField("Procedural Efficacy", "PDALigationStatus").value !== null) {
        this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDALigationStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDALigationStatus").proccode;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDALigationStatus").value;
        this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
        this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
      }
      if (this.fields.getField("Procedural Efficacy", "PDALigationStatus").value) {
        if (this.fields.getField("Procedural Efficacy", "PDAEchoPerformed").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDAEchoPerformed").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDAEchoPerformed").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDAEchoPerformed").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "PDAPostOperativeReIntervention").value !== null) {
          this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDAPostOperativeReIntervention").parentcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDAPostOperativeReIntervention").elementcode;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDAPostOperativeReIntervention").value;
          this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
          this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
        }
        if (this.fields.getField("Procedural Efficacy", "PDAEchoPerformed").value) {
          if (this.fields.getField("Procedural Efficacy", "PDALigationDivision").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDALigationDivision").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDALigationDivision").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDALigationDivision").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "PDABranchPulmonaryArtery").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDABranchPulmonaryArtery").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDABranchPulmonaryArtery").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDABranchPulmonaryArtery").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
          if (this.fields.getField("Procedural Efficacy", "PDAIsthmusDescendingAorta").value !== null) {
            this.surCaseService.surcase.SurCaseProcEfficacyItem = new SurCaseProcEfficacyInfoModel;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.SurCaseId = this.fields.getField("Case Identification", 'Case Number').value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyProcedureCode = this.fields.getField("Procedural Efficacy", "PDAIsthmusDescendingAorta").parentcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataName = this.fields.getField("Procedural Efficacy", "PDAIsthmusDescendingAorta").elementcode;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.EfficacyDataValue = this.fields.getField("Procedural Efficacy", "PDAIsthmusDescendingAorta").value;
            this.surCaseService.surcase.SurCaseProcEfficacyItem.Updatedby = this.user.user.UserId.toString();
            this.surCaseService.surcase.SurCaseProcEfficacy.push(this.surCaseService.surcase.SurCaseProcEfficacyItem);
          }
        }
      }
    //}
    //else {
    //  this.surCaseService.surcase.SurCaseProcEfficacy = null
    //}
  }
  private save() {
    if (this.surCaseService.surcase.SurCaseUniqueIdentifier != '' && this.surCaseService.surcase.SurgDate != '' && this.surCaseService.surcase.SurCaseUpdatedby != null && this.surCaseService.surcase.SurCaseUpdatedby != '') {
      //once case is marked complete it is complete we dont change the status back
      if (this.fields.completedCase) {
        //  //todo: switch from completed case back to need to mark completed
        this.fields.completedCase = false;
      }
      this.surCaseService.save().subscribe(data => this.saveResponse(data));
    }
  }

  private saveResponse(data: SurCaseSaveResponse) {
    let caseSaveStatusModel = data.DataObject;
    if (caseSaveStatusModel.SurCasestatus === "Saved Succesfully") {
      let newCaseId = caseSaveStatusModel.SurCaseId;
      this.saveCaseText = 'Case has been updated and saved (Case ID: ' + newCaseId + ')';
      if (newCaseId > 0) {
        if (this.isNewCase) {
        this.router.navigate(['/user-portal/edit-surgery-case/post-save/' + newCaseId]);
        }
        else {
          let reload = true;
          if ((this.surCaseService.surcase.SurCaseProcAsgnmtInfo != null) && (this.fields.getValue("Surgical Procedures", "SurCaseProcAsgnId") != 0)) {
            reload = true;
          }
          if ((this.surCaseService.surcase.SurCaseProcEfficacy != null) &&
            ((this.fields.getValue("Procedural Efficacy", "ASDRepairStatus") == 1) ||
            (this.fields.getValue("Procedural Efficacy", "VASSurgeryStatus") == 1) ||
            (this.fields.getValue("Procedural Efficacy", "CRLThoracotomyStatus") == 1) ||
            (this.fields.getValue("Procedural Efficacy", "PDALigationStatus") == 1)
            )
          ) {
            reload = true;
          }
          if (reload) {
            this.surCaseService.getCaseDetails(newCaseId).subscribe(data => this.loadCaseResponse(data));
          }
        }

        }
      } else {
        alert(caseSaveStatusModel.SurCasestatus);
      }
    }
  
    markCaseComplete() {
    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (!isNotPostSave) {
      //load case values
      this.surCaseService.clearCase();
      this.surCaseService.surcase.SurCaseUpdatedby = this.user.user.UserId.toString();
      this.surCaseService.surcase.SurCaseId = +this.fields.getField("Case Identification", 'Case Number').value;
    }
      this.surCaseService.markCaseComplete().subscribe(data => this.CaseCompleteResponse(data));
  }

  private CaseCompleteResponse(data: MarkSurCaseCompleteResponse) {
    let caseCompleteStatusModel = data.DataObject;
    if (caseCompleteStatusModel.Status === "Completed") {
      this.fields.completedCase = true;
      this.completedCaseText = 'Case has been marked as Completed';
    } else {
      this.completedCaseText = caseCompleteStatusModel.Status;
    }
  }

  private loadCaseResponse(data: SurCaseResponse) {
    let caseModel = data.DataObject;
    if (caseModel.SurCaseProcAsgnmtInfo != null && (caseModel.SurCaseProcAsgnmtInfo.SurCaseProcAsgnId > 0))
    {
      this.fields.setValue("Surgical Procedures", "RACHS-1 Category", caseModel.SurCaseProcAsgnmtInfo.ProceduresRachsValue);
     
    }

 
    if (caseModel.SurCaseProcEfficacy && caseModel.SurCaseProcEfficacy.length > 0) {
       // need to work 
        let peFields: any[] = this.fields.fields.find(x => x.name == "Procedural Efficacy")["fields"]
        let setValue: any = null;
        for (let fobj of peFields) {
          if (fobj.proccode !== undefined)
            setValue = caseModel.SurCaseProcEfficacy.sort((a, b) => (a.EfficacyDataName > b.EfficacyDataName) ? 1 : (b.EfficacyDataName > a.EfficacyDataName) ? -1 : 0).find(x => (x.EfficacyProcedureCode.trim() == fobj.proccode.trim())).EfficacyDataValue;

          else
            setValue = caseModel.SurCaseProcEfficacy.find(x => x.EfficacyDataName.trim() == fobj.elementcode.trim()) === undefined ? null : caseModel.SurCaseProcEfficacy.find(x => x.EfficacyDataName.trim() == fobj.elementcode.trim()).EfficacyDataValue;

          if (fobj.fieldtype == 'boolean')
            this.fields.setValue("Procedural Efficacy", fobj.name, JSON.parse(setValue));
          else if (fobj.fieldtype == 'radio')
            this.fields.setValue("Procedural Efficacy", fobj.name, setValue);
          else
            this.fields.setValue("Procedural Efficacy", fobj.name, setValue);
        }
      }
  }

  private formatDate(date: string): string {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (isNaN(year)) {
      return '';
    }

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let response = [month, day, year].join('-');

    if (year < 2000) {
      response = '';
    }

    return response;
  }

  private formatTime(date: string): string {
    if (date == null) {
      return null;
    }

    let d = new Date(date),
      hoursNumber = d.getHours(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes();

    if (isNaN(hoursNumber)) {
      return null;
    }

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return hours + ":" + minutes;
  }

  private getAgeType(type: string): number {
    if (type === 'Days') {
      return 0;
    }
    if (type === 'Months') {
      return 1;
    }
    if (type === 'Years') {
      return 2;
    }
  }

  private getYesNoNotCollected(type: string): string {
    if (type === null) {
      return null;
    }

    if (type === 'Yes') {
      return "1";
    }
    if (type === 'No') {
      return "0";
    }
    if (type === 'Not Collected') {
      return "3";
    }
  }
  private getYesNoUnknown(type: string): string {
    if (type === 'true') {
      return "1";
    }
    if (type === 'false') {
      return "0";
    }
    if (type === 'Unknown') {
      return "-1";
    }
  }

  private getprematurevalue(type: string): number {
    if (type === 'Yes') {
      return 1;
    }
    if (type === 'No') {
      return 0;
    }
    if (type === 'Unknown') {
      return -1;
    }
  }

  private getYesNo(type: string): string {
    if (type === null) {
      return null;
    }

    if (type === 'Yes') {
      return "1";
    }
    return "0";
  }

  private getTrueFalse(type: boolean): string {
    if (type == null) {
      return null;
    }

    if (type) {
      return "1";
    }
    return "0";
  }

  disablemarkcomplete() {
    return !this.fields.isComplete() && !this.fields.completedCase;
  }
  private getprocedures(procList: any): string {
    var proc_ids = ""
    var procsArr = [];
    if (procList) {
       procsArr = Object.getOwnPropertyNames(procList);
    }
    for (let item of procsArr) {
      let proc = procList[item];
      //let proc = item;
      if (proc_ids.length == 0)
        proc_ids = proc['id'].replace("procedure-", "");
      else
        proc_ids = proc_ids + "|" + proc['id'].replace("procedure-", "");
      
    }
    return proc_ids;
  }
}
