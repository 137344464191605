import { SurCaseClinicalInfoModel } from './surcase-clinical-info-model';
import { SurCaseMedicalHistInfoModel } from './surcase-surmedical-hist-model';
import { SurCase30DayStatusModel } from './surcase-30daystatus-model';
import { SurCaseClinicalOutComesInfoModel} from './surcase-clinical-outcomesinfo-model';
import { SurCaseSurgeryEventsInfoModel } from './surcase-events-info-model';
import { SurCaseProcAssignmentModel } from './surcase-proc-assignment-model';
import { SurCaseProcEfficacyInfoModel } from './surcase-proc-efficacy-info-model';


export class SurCaseModel {
  SurCaseId: number;
  SurCaseUniqueIdentifier: string;
  SurgDate: string;
  SurLocationId: number;
  PrimarySurgeonId: number;
  SurPerfPVCSGInd: string;
  SurInfoStatus: string;
  SurCaseUpdatedby: string;
  ActiveStatus: string;
  SurCaseClinicalInfo: SurCaseClinicalInfoModel;
  SurCaseMedicalHistInfo: SurCaseMedicalHistInfoModel;
  SurCaseProcAsgnmtInfo: SurCaseProcAssignmentModel;
  SurCaseEventsInfo: SurCaseSurgeryEventsInfoModel;
  SurCaseOutcomesInfo: SurCaseClinicalOutComesInfoModel;
  SurCase30DayStatusInfo: SurCase30DayStatusModel;
  SurCaseProcEfficacyItem: SurCaseProcEfficacyInfoModel;
  SurCaseProcEfficacy: SurCaseProcEfficacyInfoModel[];

  constructor() {
    this.SurCaseClinicalInfo = new SurCaseClinicalInfoModel;
    this.SurCaseOutcomesInfo = new SurCaseClinicalOutComesInfoModel;
    this.SurCaseEventsInfo = new SurCaseSurgeryEventsInfoModel;
    this.SurCaseMedicalHistInfo = new SurCaseMedicalHistInfoModel;
    this.SurCase30DayStatusInfo = new SurCase30DayStatusModel;
    this.SurCaseProcAsgnmtInfo = new SurCaseProcAssignmentModel;
    this.SurCaseProcEfficacy = new Array();
  }
}
