import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";

@Injectable({
  providedIn: "root"
})
export class HemosService {
  public sv: boolean = false;
  public svVals = {
    SVEDP: 18,
    SysSat: 78,
    MVSat: 50,
    PAMean: 17,
    QpQs: 1.5,
    PVR: 3
  };
  public biVals = {
    SVEDP: 18,
    SysSat: 95,
    MVSat: 60,
    PAMean: 45,
    QpQs: 1.5,
    PVR: 3
  };
  public vals = {
    SVEDP: 18,
    SysSat: 78,
    MVSat: 50,
    PAMean: 17,
    QpQs: 1.5,
    PVR: 3
  };
  clicked = false;
  //use this service to store/get data from the hemodynamics form
  paTxt = "sys";
  svChange: Subject<boolean> = new Subject<boolean>();

  name = "Hemodynamics";
  public fields: CaseFieldsService;
  constructor(fields: CaseFieldsService) {
    this.fields = fields;

    this.vals = this.biVals;
    this.svChange.subscribe(value => {
      this.sv = value;
    });    
  }

  public setSVP() {
    let svp = this.fields.getValue(this.name, 'Single Ventricle Physiology');
    if (svp != null) {
      this.svChange.next(svp);
      this.toggleTxt(svp);
      this.clicked = true;
    } else {
      this.clicked = false;
    }   
  }

  toggleTxt(val) {
    if (val) {
      this.vals = this.svVals;
      this.paTxt = "mean";
    } else {
      this.vals = this.biVals;
      this.paTxt = "sys";
    }
  }

  toggleSV(val) {
    this.clicked = true;

    this.svChange.next(val);
    this.toggleTxt(val);

    this.fields.setValue(this.name, 'Single Ventricle Physiology', val);
    this.fields.getField(this.name, "Single Ventricle Physiology").hide = true;

  }
}
