
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { CalculatorService } from './calculator.service';
import { CaseFieldsService } from "../user/add-case/case-fields.service";
import { fadeAnimation } from '../../animations/fade.animation';
import { enterAnimation } from '../../animations/enter.animation';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';

import { PicklistItemService } from "../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../shared/picklist-item-response';
import { PicklistItemModel } from '../../shared/picklist-item-model';
import { LoginService } from "../user/login.service";
import { NgForm } from "@angular/forms";


@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  animations: [enterAnimation]
})
export class CalculatorComponent  implements OnInit, OnDestroy {
  @ViewChild('calculatorform')
  form: NgForm;
  public picklistItemService: PicklistItemService;
  public user: LoginService;


  showResults:boolean = false;
  btnText: string = "CALCULATE";
  caseSummaryT: string = "";
  showSummary: boolean = false;
  dropDownCrisp: PicklistItemResponse;


  public calc: CalculatorService;
  public fields: CaseFieldsService;
  constructor(calc: CalculatorService, _fields: CaseFieldsService, private router: Router, _picklistItemService: PicklistItemService, _user: LoginService) { 
    
    this.calc = calc;
    this.fields = _fields;
    this.user = _user;
    this.resetFieldsCheck();

    this.picklistItemService = _picklistItemService;
    this.picklistItemService
      .getPicklistItem("1", "CRISP Physiologic category")
      .subscribe(data => this.populatePicklistItems(data, "CRISP"));
  
 

  }



  

  populatePicklistItems(picklistItemResponse: PicklistItemResponse, type) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();    

 
      this.dropDownCrisp = picklistItemResponse;

  }

  ngOnInit() {
  }

  //gets the recorded procedures as an array
  getProceduresArray() {
  
    var procedure = [];
    var getproc = [];
    var proc = this.getProcedures();
    if (proc != null) {
      getproc = Object.getOwnPropertyNames(this.getProcedures());


      for (let pr in Object.values(proc)) {
        procedure[pr] = getproc[pr] + "," + Object.values(proc)[pr]["primary"];
      }
    }
    return procedure;

    
  }

  //retrieves the recorded procedure list from the case list service
  getProcedures() {
    return this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value;
  }


  PreCaseSummary()
  {
    
    let arr = this.getProceduresArray();
    this.caseSummaryT = "";
    var SingleVentricle = "No"; if (this.fields.getField('Pre-Case Calculation', 'Single Ventricle Physiology').value == "1") { SingleVentricle = "Yes" }
    var IsMvSat = "No"; if (this.fields.getField('Pre-Case Calculation', 'MV Sat').value == "1") { IsMvSat = "Yes" }
    var IsPAMean = "No"; if (this.fields.getField('Pre-Case Calculation', 'PA').value == "1") { IsPAMean = "Yes" }
    var IsPVR = "No"; if (this.fields.getField('Pre-Case Calculation', 'PVR').value == "1") { IsPVR = "Yes" }
    var IsQPQS = "No"; if (this.fields.getField('Pre-Case Calculation', 'Qp:Qs').value == "1") { IsQPQS = "Yes" }
    var IsSVEDP = "No"; if (this.fields.getField('Pre-Case Calculation', 'SV EDP').value== "1") { IsSVEDP = "Yes" }
    var IsSysSat = "No"; if (this.fields.getField('Pre-Case Calculation', 'Sys Sat').value == "1") { IsSysSat = "Yes" }
    var GenSyndromeInd = "No"; if (this.fields.getField('Clinical Characteristics', 'Genetic Syndrome').value == "1") { GenSyndromeInd = "Yes" } 
    var crisp = "";
    var age = "";
    if (this.fields.getField('Clinical Characteristics', 'Age').value != null)
      age = this.fields.getField('Clinical Characteristics', 'Age').value + "&nbsp;" + this.fields.getField('Clinical Characteristics', 'Age Unit').value
    var stsDiag = "";
    if (this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value != null)
      stsDiag = this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value;
    
    var weight = "";
    if (this.fields.getField('Clinical Characteristics', 'Weight').value != null)
      weight = this.fields.getField('Clinical Characteristics', 'Weight').value + "&nbsp;kg"
    var caseNumber = "";
    if (this.fields.getField('Case Identification','Case Number').value != null)
      caseNumber = this.fields.getField('Case Identification', 'Case Number').value;
    var caseNumbertxt = "";
    if (caseNumber != "")
      caseNumbertxt = "Case ID &nbsp;" + caseNumber;
    var hospital = "";
    if (this.user.user != null && this.user.user.Hospital.indexOf("-") > 0)
      hospital = this.user.user.Hospital.split('-')[1];
    else if (this.user.user != null)
      hospital = this.user.user.Hospital;
   
    if (this.fields.getField('Pre-Case Calculation', 'Physiologic Category').value != "" && this.fields.getField('Pre-Case Calculation', 'Physiologic Category').value != undefined) {
      if (this.dropDownCrisp.DataObject.find(x => x.ChoiceCode == this.fields.getField('Pre-Case Calculation', 'Physiologic Category').value) != undefined)
        crisp = this.dropDownCrisp.DataObject.find(x => x.ChoiceCode == this.fields.getField('Pre-Case Calculation', 'Physiologic Category').value)["ChoiceText"];
    }
    this.showSummary = true;
    
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-5  \" style=\" font-size: medium !important;font-weight : bold !important;text-transform: uppercase !important;\">Pre-Case Catheterization Summary</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"></div>"
    this.caseSummaryT += "</div>"
    if (this.user.IsLoggedIn) {
      this.caseSummaryT += "<div class=\"form-row\">"

      this.caseSummaryT += "<div  class=\"d-flex  col-lg-2  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: uppercase !important;\">Hospital:</span></div><div  class=\"d-flex  col-lg-8  \"><span style=\"font-weight: 500;font-size:medium;\">" + hospital + "</span></div>"

      this.caseSummaryT += "</div>"
    }
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: medium !important;font-weight : bold !important;text-transform: uppercase !important;\">Clinical Characteristics</div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Age:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:mediumr;\">" +  age + "</span></div>"

    

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Diagnosis Category:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:mediumr;\">" + stsDiag + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Weight:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + weight + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Physiologic Category:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + crisp + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Case Id:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + caseNumber + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Genetic Syndrome:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + GenSyndromeInd + "</span></div>"


    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 \" style=\" font-size: medium !important;font-weight : bold !important;text-transform: uppercase !important;\">Predicted Hemodynamics </div>"
    this.caseSummaryT += "</div>"



   


    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Single Ventricle Physiology:</span></div><div  class=\"d-flex  col-lg-8  \"><span style=\"font-weight: 500;font-size:medium;\">" + SingleVentricle + "</span></div>"

    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 \"></div>"
    this.caseSummaryT += "</div>"
   

    

   



    if (SingleVentricle == "Yes") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">MV Sat < 50%:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsMvSat + "</span></div>"
    }
    if (SingleVentricle == "No") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">MV Sat < 60%:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsMvSat + "</span></div>"
    }
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">SV EDP ≥ 18mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsSVEDP + "</span></div>"

   

    if (SingleVentricle == "Yes") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Sys Sat < 78%:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsSysSat + "</span></div>"
    }
    if (SingleVentricle == "No") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Sys Sat < 95%:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsSysSat + "</span></div>"
    }
    if (SingleVentricle == "Yes") {


      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">PA mean ≥ 17mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsPAMean + "</span></div>"
    }
    if (SingleVentricle == "No") {


      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">PA mean ≥ 45mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsPAMean + "</span></div>"
    }

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">Qp:Qs > 1.5:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsQPQS + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: medium !important;font-weight : bold !important;text-transform: capitalize !important;\">PVR > 3WU:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:medium;\">" + IsPVR + "</span></div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "</div>"

        

    var primaryProc = "";
    var secondaryProc = "";
    var caseType = "";
    if (this.fields.getField('Pre-Case Calculation', 'Case Type').value != null)
      caseType = this.fields.getField('Pre-Case Calculation', 'Case Type').value;
   
    for (let proc in arr)
    {
      var procsplit = arr[proc].split(',');
      if (procsplit[1] == "true")
        primaryProc = primaryProc + procsplit[0].split('|')[1];
      else
        secondaryProc = secondaryProc + procsplit[0].split('|')[1];
    }
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"


    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \" style=\" font-size: medium !important;font-weight : bold !important;text-transform: uppercase !important;\">Anticipated Case Type</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-8   \"> <span style=\"font-weight: 500;font-size:medium;\">" + caseType + "</span></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\" >"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \" style=\" font-size: medium !important;font-weight : bold !important;\"> Primary Procedure:</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-8   \"> <span style=\"font-weight: 500;font-size:medium;\">" + primaryProc + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4  \" style=\" font-size: medium !important;font-weight : bold !important;\">Secondary Procedure(s):</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-8   \"> <span style=\"font-weight: 500;font-size:medium;text-align:start !important;\">" + secondaryProc + "</span></div>"

    this.caseSummaryT += "</div>"  
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 \"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 \"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 \"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"><span>Report generated on " + new Date().toLocaleDateString()  + "</span> </div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"><span>" + caseNumbertxt + "</span> </div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-4 \"></div>"
    this.caseSummaryT += "</div>"  
  }


  onPrint() {

    let pdf = new jspdf('p', 'mm', 'a4');
  
    const filename = 'PreCaseSummary.pdf';


    html2canvas(document.querySelector('#precaseSummaryReport')).then(canvas1 => {

      pdf.addImage(canvas1.toDataURL('image/jpeg'), 'JPEG', 5, 5, 211, 178);
     
        pdf.save(filename);
       
    });
      
  }

  onPrintCancel() {
    this.showSummary = false;
  }

  changeView($event) {
    this.showResults = !this.showResults;
    if (this.showResults) {
      this.btnText = "BACK";
    }
    else {
      this.btnText = "CALCULATE";
    }
  }

  ngOnDestroy() {
    this.resetFieldsCheck();
  }

  resetFieldsCheck() {
    if (this.router.url.indexOf("user-portal") == -1) {
      this.fields.resetFields();
    }
  }
}
