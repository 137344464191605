
import { Component, OnInit, HostListener } from "@angular/core";
import { CaseService } from '../../user/add-case/case.service';
import { Router } from '@angular/router';
import { SurCaseService } from "../../user/add-surgery-case/surcase.service";


@Component({
  selector: 'app-confirmsave-modal',
  templateUrl: './confirmsave-modal.component.html',
  styleUrls: ['./confirmsave-modal.component.scss']
})
export class ConfirmsaveModalComponent implements OnInit {
 
  public caseService: CaseService;
  public surCaseService: SurCaseService;
  constructor(_caseService: CaseService, _surCaseService: SurCaseService, private router: Router) {
    this.caseService = _caseService;
    this.surCaseService = _surCaseService;
  }

  ngOnInit() {

  }

  saveCase() {
    if (this.caseService.saveCaseId) {
      if (this.caseService.route == 'editcase') {
        this.caseService.route = ''
        this.router.navigate(['/user-portal/edit-case/save/' + this.caseService.saveCaseId]);
        
      }
      
    
    }

    if (this.surCaseService.saveCaseId) {
      if (this.surCaseService.route == 'editsurgerycase') {
        this.surCaseService.route = ''
        this.router.navigate(['/user-portal/edit-surgery-case/save/' + this.surCaseService.saveCaseId]);
      }


    }

    if (this.caseService.route === 'addcase') {
      this.caseService.route = ''
      this.router.navigate(['/user-portal/add-case/save']);
    }
    if (this.surCaseService.route === 'addsurgerycase') {
      this.surCaseService.route = ''
      this.router.navigate(['/user-portal/add-surgery-case/save']);
    }
  }


  close() {
    this.caseService.saveCaseId = 0;
    this.surCaseService.saveCaseId = 0;
  }

 
}
