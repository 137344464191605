export class SurCaseClinicalInfoModel {
  SurCaseClinicalInfoId: number;
  SurCaseId: number;
  PatSex: string;
  PatAge: number;
  PatAgeType: number;
  PatPreMaturityVal: number;
  PatHeight: number;
  PatWeight: number;
  PatBSA: number;
  SurHemotocritVal: string;
  SurOxygenSatVal: string;
  SurNutrnalApearVal: string;
  PatWhozScoreVal: string;
  Updatedby: string;
}
