export class ReportRequest {
  UserName: string;
  Email: string;
  FromDate: string;
  ToDate: string;
  CaseType: string;
  AgeRange: string;
  FromWeight: number;
  ToWeight: number;
  TimeInterval: string;
  ReportID: string;
  CathLocationID: number;
  OperatorID: string; 
  AdverseEventSeverity: string;
  ReportName: string;
  AppRegistry: string;
  sitesSelected: string;
  Reviewed: boolean;
  NotReviewed: boolean;

}
